// userReducer.js
import {
  GENERATE_OTP_REQUEST,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  SET_TOKEN,
  GET_ADMIN_ROLES,
  GET_ADMIN_ROLES_SUCCESS,
  GET_ADMIN_ROLE_FAILURE,
  CREATE_USER,
  CREATE_USER_FAILURE,
  SET_NEW_USER_CREATED,
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  GET_REFERRALS_LIST,
  GET_REFERRALS_LIST_SUCCESS,
  GET_REFERRALS_LIST_FAILURE,
  GET_REFERRALS_DETAIL,
  GET_REFERRALS_DETAIL_SUCCESS,
  GET_REFERRALS_DETAIL_FAILURE,
  SET_USER_DETAILS,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from '../actions/userActions';

const initialState = {
  generatingOTP: false,
  verifyingOTP: false,
  errorMessage: null,
  createNewUser: null,
  token: null,
  adminRoles: null,
  usersList: [],
  referralCodesList: [],
  referralDetailById: null,
  userDetails: null,
  isDocumentDeleted: null,
  isUserUpdated: null,
  isUserDeleted: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_OTP_REQUEST:
      return { ...state, generatingOTP: true, errorMessage: null };

    case GENERATE_OTP_SUCCESS:
      return { ...state, generatingOTP: false, errorMessage: null };

    case GENERATE_OTP_FAILURE:
      return { ...state, generatingOTP: false, errorMessage: action.payload };

    case VERIFY_OTP_REQUEST:
      return { ...state, verifyingOTP: true, errorMessage: null };

    case VERIFY_OTP_SUCCESS:
      return { ...state, verifyingOTP: false, errorMessage: null };

    case VERIFY_OTP_FAILURE:
      return { ...state, verifyingOTP: false, errorMessage: action.payload };

      case GET_ADMIN_ROLES:
      return { ...state, errorMessage: null };

    case GET_ADMIN_ROLES_SUCCESS:
      return { ...state, adminRoles: action.payload, errorMessage: null };

      case GET_ADMIN_ROLE_FAILURE:
      return { ...state, adminRoles: null, errorMessage: action.payload };

      case CREATE_USER:
      return { ...state, createNewUser: null, errorMessage: null };
    
     case SET_NEW_USER_CREATED:
        return { ...state, createNewUser: action.payload,  errorMessage: null };

    case CREATE_USER_FAILURE:
      return { ...state, createNewUser: false, errorMessage: action.payload };

      case GET_USERS_LIST:
        return { ...state, usersList: [], errorMessage: null };
      
       case GET_USERS_LIST_SUCCESS:
          return { ...state, usersList: action.payload,  errorMessage: null };
  
      case GET_USERS_LIST_FAILURE:
        return { ...state, usersList: [], errorMessage: action.payload };

    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

      case GET_REFERRALS_LIST:
        return { ...state, errorMessage: null };
  
      case GET_REFERRALS_LIST_SUCCESS:
        return { ...state, referralCodesList: action.payload, errorMessage: null };
  
      case GET_REFERRALS_LIST_FAILURE:
        return { ...state, errorMessage: action.payload };

      case GET_REFERRALS_DETAIL:
        return { ...state,referralDetailById: null, errorMessage: null };
  
      case GET_REFERRALS_DETAIL_SUCCESS:
        return { ...state, referralDetailById: action.payload, errorMessage: null };
  
      case GET_REFERRALS_DETAIL_FAILURE:
        return { ...state, errorMessage: action.payload };

      case SET_USER_DETAILS:
        return {...state, userDetails: action.payload}

        case UPDATE_USER_REQUEST:
          return { ...state, isUserUpdated: null, errorMessage: null };
    
        case UPDATE_USER_SUCCESS:
          return { ...state, isUserUpdated: true, errorMessage: null };
    
        case UPDATE_USER_FAILURE:
          return { ...state,isUserUpdated: null, errorMessage: action.payload };

          case DELETE_USER_REQUEST:
            return { ...state, isUserDeleted: null, errorMessage: null };
      
          case DELETE_USER_SUCCESS:
            return { ...state, isUserDeleted: true, errorMessage: null };
      
          case DELETE_USER_FAILURE:
            return { ...state,isUserDeleted: null, errorMessage: action.payload };

            case DELETE_DOCUMENT_REQUEST:
            return { ...state, isDocumentDeleted: null, errorMessage: null };
      
          case DELETE_DOCUMENT_SUCCESS:
            return { ...state, isDocumentDeleted: true, errorMessage: null };
      
          case DELETE_DOCUMENT_FAILURE:
            return { ...state,isDocumentDeleted: null, errorMessage: action.payload };

    default:
      return state;
  }
};

export default userReducer;
