// apiService.js
import axios from 'axios';
import { store } from '../store/store';
import { setToken } from '../actions/userActions';
import showToast from '../../utils/toast';

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const apiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

apiService.interceptors.request.use(
  (config) => {
    const { user } = store.getState();
    const token = user.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Dispatch action directly
      store.dispatch(setToken(null));
      showToast('Session expired. Please login again.', 'error');
    }
    return Promise.reject(error);
  }
);

export default apiService;
