// clipboardService.js
import copy from 'copy-to-clipboard';
import showToast from './toast';

class ClipboardService {
  copyToClipboard(text) {
    try {
      copy(text);
      showToast('Text copied successfully', 'success');
      return true; // Copy succeeded
    } catch (error) {
      console.error('Unable to copy text', error);
      return false; // Copy failed
    }
  }
}

const clipboardService = new ClipboardService();
export default clipboardService;