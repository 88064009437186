// ViewGigs.js
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldComponent from '../components/TextFieldComponent';
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGigPost, exportGigPosts, getGigApplicantsList, getGigDetail, getGigsList } from '../redux/actions/gigsActions';
import moment from 'moment';
import GigDetailsModal from '../models/GigDetails';
import GigApplicantsModal from '../models/GigApplicantsModal';
import { useLocation } from 'react-router-dom';
import DropdownComponent from '../components/DropDownComponent';
import { formatIndianCurrency } from '../helpers/formatIndianCurrency';
import GigPostForm from './GigPostForm';
import ConfirmationDialog from '../components/ConfirmationDialog';
import clipboardService from '../utils/clipboardService';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { isSuperUser } from '../redux/selectors/userSelector';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { formatYYYYMMDDDate } from '../helpers/formattedDateTime';
import constants from '../helpers/constants.json';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ArrowBackIosNew } from '@mui/icons-material';

const ViewGigs = () => {
    const MENU_ID = 'GIG_ACTION'
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const employerId = searchParams.get('employerId');

    const [queryParams, setQueryParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        searchText: '',
        sort: '',
        active: '',
        employerId: employerId ? employerId : ''
    });

    const validationSchema = Yup.object().shape({
        startDate: Yup.date().required('Date is required'),
        endDate: Yup.string().required('Date is required'),
    });

    const [selectedRow, setSelectedRow] = useState(null);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [activeFilter, setActiveFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [gig, selectedGig] = useState(null);
    const [gigId, setGigId] = useState([]);
    const [isEditGigVisible, setEditGigVisible] = useState(false);
    const [isMoreDetailsVisible, setIsMoreDetailsVisible] = useState(false);
    const [isGigApplicantsModalOpen, setIsGigApplicantsModalOpen] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(null);

    const gigApplicantsResponse = useSelector((state) => state.gig.gigApplicants);
    const gigApplicants = gigApplicantsResponse;

    const dispatch = useDispatch();
    const gigsResponse = useSelector((state) => state.gig.gigs);
    const gigs = gigsResponse?.data;

    const gigMoreDetailsResponse = useSelector((state) => state.gig.gigDetailsById);
    const gigMoreDetails = gigMoreDetailsResponse?.data;

    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const handleContextMenu = (event, gig, index) => {
        setSelectedRow(index);
        show({
            event,
            props: gig,
        });
    };

    const superUser = useSelector(isSuperUser);

    useEffect(() => {
        dispatch(getGigsList(queryParams));
    }, [dispatch, queryParams]);

    const handleEditClick = (gig) => {
        selectedGig(null);
        selectedGig(gig);
        setEditGigVisible(true)
    };

    const viewMoreDetails = (id) => {
        dispatch(getGigDetail(id));
        setIsMoreDetailsVisible(true);
    }

    const handleGigApplicantsClick = (id) => {
        dispatch(getGigApplicantsList(id, 0));
        setIsGigApplicantsModalOpen(true);
    }

    const handleCloseGigApplicantsModal = () => {
        setIsGigApplicantsModalOpen(false);
    };

    const handleDeleteClick = (gig) => {
        setEditGigVisible(false)
        setShowDeleteDialog(true);
        selectedGig(gig)
    };

    const handlePageChange = (event, page) => {
        handleCloseGigModal()
        setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
    };

    const handleActiveFilterChange = (event) => {
        handleCloseGigModal()
        setActiveFilter(event.id);
        setQueryParams((prevParams) => ({ ...prevParams, active: event.id }));
    };

    const handleSearch = (text) => {
        handleCloseGigModal()
        setSearchTerm(text);
        clearTimeout(typingTimeout);
        const timeout = setTimeout(() => {
            setQueryParams((prevParams) => ({ ...prevParams, searchText: text, pageNumber: 0 }));
        }, 2000);
        setTypingTimeout(timeout);
    };

    const handleCloseGigModal = () => {
        setEditGigVisible(false);
        selectedGig(null)
    }

    const handleCloseGigMoreDetailsModal = () => {
        setIsMoreDetailsVisible(false);
    };

    const handleConfirmationDialogClose = () => {
        setShowDeleteDialog(false);
        selectedGig(null)
    };

    const handleConfirmationDialog = () => {
        dispatch(deleteGigPost(gig.gigPostId))
        setShowDeleteDialog(false);
        selectedGig(null)
    };

    const handleCopyClick = (shareLink) => {
        clipboardService.copyToClipboard(shareLink);
    };

    const handleItemClick = ({ event, id }) => {
        const gig = event.props
        const { gigPostId } = event.props;
        setGigId(gigPostId);
        switch (id) {
            case "view_more":
                viewMoreDetails(gigPostId)
                break;
            case "gig_applicants":
                handleGigApplicantsClick(gigPostId)
                break;
            case "edit":
                handleEditClick(gig);
                break;
            case "delete":
                handleDeleteClick(gig)
                break;
            default:
                break
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            startDate: '',
            endDate: '',
        },
        onSubmit: async (values) => {
            const payload = {
                startDate: formatYYYYMMDDDate(values.startDate),
                endDate: formatYYYYMMDDDate(values.endDate),
            };
            dispatch(exportGigPosts(payload.startDate, payload.endDate))
        },
    });

    return (

        <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: isEditGigVisible ? 8 : 12 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <TextFieldComponent
                            label="Search"
                            type="text"
                            value={searchTerm}
                            style={{ width: '350px' }}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <div style={{ marginLeft: '10px' }}>
                            <DropdownComponent
                                label="Active"
                                value={activeFilter}
                                onChange={handleActiveFilterChange}
                                options={constants.boolValueTypes}
                                fullWidth
                                style={{ width: "110px" }}
                            />
                        </div>

                        {superUser && <div style={{ marginLeft: '10px' }}>
                            <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '110px' }}>
                                    <TextFieldComponent
                                        label="Start Date"
                                        type="date"
                                        field={{
                                            ...formik.getFieldProps("startDate"),
                                            onChange: formik.handleChange("startDate"),
                                        }}
                                        form={formik}
                                        fullWidth
                                    />
                                </div>

                                <div style={{ width: '110px', marginLeft: "10px" }}>
                                    <TextFieldComponent
                                        label="End Date"
                                        type="date"
                                        field={{
                                            ...formik.getFieldProps("endDate"),
                                            onChange: formik.handleChange("endDate"),
                                        }}
                                        form={formik}
                                        fullWidth
                                    />
                                </div>

                                <div style={{ marginLeft: "10px", whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                    <Button variant="contained" disabled={!formik.isValid || formik.isSubmitting} className="blackButton" type="submit">
                                        Download Excel
                                    </Button>
                                </div>

                            </form>
                        </div>}
                    </div>
                </div>

                <div style={{ margin: '16px 0' }} />

                {(!gigs?.length ? (
                    <h1 style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>No Gigs</h1>
                ) : (
                    <>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={styles.textBold}>Gig Title</TableCell>
                                        <TableCell style={styles.textBold}>Employer Brand Name</TableCell>
                                        <TableCell style={styles.textBold}>Job Location</TableCell>
                                        <TableCell style={styles.textBold}>Job Date</TableCell>
                                        <TableCell style={styles.textBold}>English Proficiency</TableCell>
                                        <TableCell style={styles.textBold}>Compensation</TableCell>
                                        <TableCell style={styles.textBold}>Time Range</TableCell>
                                        <TableCell style={styles.textBold}>No of Positions</TableCell>
                                        <TableCell style={styles.textBold}>Mode of Payment</TableCell>
                                        <TableCell style={styles.textBold}>Share Link</TableCell>
                                        <TableCell style={styles.textBold}>Created At</TableCell>
                                        <TableCell style={styles.textBold}>Updated At</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {gigs?.map((gig, index) => (
                                        <TableRow onContextMenu={(event) => handleContextMenu(event, gig, index)} key={gig.gigPostId}
                                            onClick={() => setSelectedRow(index)}
                                            onDoubleClick={() => viewMoreDetails(gig.gigPostId)}
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                                            }}>
                                            <TableCell>{gig.jobTitle}</TableCell>
                                            <TableCell>{gig.employerBrandName}</TableCell>
                                            <TableCell>{gig.jobLocation}</TableCell>
                                            <TableCell>{moment(gig.jobDate).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell>{gig.englishProficiency}</TableCell>
                                            <TableCell>{formatIndianCurrency(gig.compensation)}</TableCell>
                                            <TableCell>{`${gig.timeStart} - ${gig.timeEnd}`}</TableCell>
                                            <TableCell>{gig.noOfPosition}</TableCell>
                                            <TableCell>{gig.modeOfPayment}</TableCell>
                                            <TableCell>
                                                <Button color="primary" onClick={(e) => { e.preventDefault(); handleCopyClick(gig.shareLink); }}>
                                                    <ContentCopyIcon />
                                                </Button>
                                            </TableCell>
                                            <TableCell>{gig.createDate && moment(gig.createDate).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell>{gig.updateDate && moment(gig.updateDate).format("DD/MM/YYYY")}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Menu id={MENU_ID}>
                            <Item id="view_more" onClick={(event, props) => handleItemClick({ event, props, id: 'view_more' })}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <VisibilityIcon />
                                    <span style={{ marginLeft: '5px' }}>View More</span>
                                </div>
                            </Item>


                            <Item id="gig_applicants" onClick={(event) => handleItemClick({ event, id: 'gig_applicants' })}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <PeopleIcon />
                                    <span style={{ marginLeft: '5px' }}>Gig Applicants</span>
                                </div>
                            </Item>
                            <Separator />
                            <Item id="edit" onClick={(event) => handleItemClick({ event, id: 'edit' })}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <EditIcon />
                                    <span style={{ marginLeft: '5px' }}>Edit</span>
                                </div>
                            </Item>
                            {superUser && <Item id="delete" onClick={(event) => handleItemClick({ event, id: 'delete' })}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
                                    <DeleteIcon />
                                    <span style={{ marginLeft: '5px' }}>Delete</span>
                                </div>
                            </Item>}

                        </Menu>
                    </>
                ))}
            </Grid>

            {isEditGigVisible && (
                <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
                    <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleCloseGigModal} style={{ marginBottom: '16px' }}>
                        Back
                    </Button>
                    {/* Adjust the component and props based on your Gig editing form */}
                    <GigPostForm
                        gigQueryParams={queryParams}
                        employerId={gig.employerId}
                        gigDetail={gig}
                        onClose={handleCloseGigModal}
                        isOpen={isEditGigVisible}
                    />
                </Grid>
            )}

            <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
                <Pagination
                    page={queryParams.pageNumber + 1}
                    onChange={handlePageChange}
                    color="primary"
                    count={gigsResponse?.metadata?.totalPages}
                    size="small"
                />
            </Grid>



            {isMoreDetailsVisible && gigMoreDetails !== null && (
                <GigDetailsModal
                    isOpen={isMoreDetailsVisible}
                    onClose={handleCloseGigMoreDetailsModal}
                    gigDetails={gigMoreDetails}
                />
            )}

            {isGigApplicantsModalOpen && gigApplicants !== null && (
                <GigApplicantsModal
                    gigPostId={gigId}
                    isOpen={isGigApplicantsModalOpen}
                    onClose={handleCloseGigApplicantsModal}
                    gigApplicants={gigApplicants}
                />
            )}

            {showDeleteDialog === true && (
                <ConfirmationDialog
                    open={true}
                    onClose={handleConfirmationDialogClose}
                    onConfirm={handleConfirmationDialog}
                    message="Do you want to Delete?"
                />
            )}
        </Grid>
    );
};

export default ViewGigs;

const styles = {
    textBold: {
        fontWeight: 'bold'
    }
}