// userActions.js
import { generateOTPService, verifyOTPService, getAdminRolesService, createNewUserService, getUsersListService, getReferralsListService, getAppUserFromReferralCode, uploadFileService, deleteDocumentService, updateUserService, deleteUserService } from '../service/userService';
import showToast from '../../utils/toast';
import { startLoader, stopLoader } from './loaderActions';
import { GET_SINGLE_CANDIDATE_SUCCESS } from './candidateActions';
import { GET_SINGLE_EMPLOYER_SUCCESS } from './employerActions';

export const GENERATE_OTP_REQUEST = 'GENERATE_OTP_REQUEST';
export const GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS';
export const GENERATE_OTP_FAILURE = 'GENERATE_OTP_FAILURE';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const GET_ADMIN_ROLES = 'GET_ADMIN_ROLES';
export const GET_ADMIN_ROLES_SUCCESS = 'GET_ADMIN_ROLES_SUCCESS';
export const GET_ADMIN_ROLE_FAILURE = 'GET_ADMIN_ROLE_FAILURE';

export const CREATE_USER = 'CREATE_USER';
export const SET_NEW_USER_CREATED = 'SET_NEW_USER_CREATED';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';

export const GET_REFERRALS_LIST = 'GET_REFERRALS_LIST';
export const GET_REFERRALS_LIST_SUCCESS = 'GET_REFERRALS_LIST_SUCCESS';
export const GET_REFERRALS_LIST_FAILURE = 'GET_REFERRALS_LIST_FAILURE';

export const GET_REFERRALS_DETAIL = 'GET_REFERRALS_DETAIL';
export const GET_REFERRALS_DETAIL_SUCCESS = 'GET_REFERRALS_DETAIL_SUCCESS';
export const GET_REFERRALS_DETAIL_FAILURE = 'GET_REFERRALS_DETAIL_FAILURE';

export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const SET_USER_DETAILS = 'USER_DETAILS'

export const SET_TOKEN = 'SET_TOKEN';

// Action creators
export const generateOTPRequest = () => ({
  type: GENERATE_OTP_REQUEST,
});

export const generateOTPSuccess = (message) => ({
  type: GENERATE_OTP_SUCCESS,
  payload: message,
});

export const generateOTPFailure = (error) => ({
  type: GENERATE_OTP_FAILURE,
  payload: error,
});

export const verifyOTPRequest = () => ({
  type: VERIFY_OTP_REQUEST,
});

export const verifyOTPSuccess = (message) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: message,
});

export const verifyOTPFailure = (error) => ({
  type: VERIFY_OTP_FAILURE,
  payload: error,
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});

export const setUserDetails = (details) => ({
  type: SET_USER_DETAILS,
  payload: details
})

export const getAdminRole = () => ({
  type: GET_ADMIN_ROLES,
});

export const getAdminRoleSuccess = (roles) => ({
  type: GET_ADMIN_ROLES_SUCCESS,
  payload: roles,
});

export const getAdminRoleFailure = (error) => ({
  type: GET_ADMIN_ROLE_FAILURE,
  payload: error,
});

export const getUserList = () => ({
  type: GET_USERS_LIST,
});

export const getUserListSuccess = (users) => ({
  type: GET_USERS_LIST_SUCCESS,
  payload: users,
});

export const getUserListFailure = (error) => ({
  type: GET_USERS_LIST_FAILURE,
  payload: error,
});

export const createNewUser = () => ({
  type: CREATE_USER,
});

export const setNewUserCreated = (isNewUserCreated) => ({
  type: SET_NEW_USER_CREATED,
  payload: isNewUserCreated
});

export const createUserFailure = (error) => ({
  type: CREATE_USER_FAILURE,
  payload: error,
});

export const getReferralsList = () => ({
  type: GET_REFERRALS_LIST,
});

export const getReferralsListSuccess = (referrals) => ({
  type: GET_REFERRALS_LIST_SUCCESS,
  payload: referrals
});

export const getReferralsListFailure = (error) => ({
  type: GET_REFERRALS_LIST_FAILURE,
  payload: error,
});

export const getReferralDetail = () => ({
  type: GET_REFERRALS_DETAIL,
});

export const getReferralDetailSuccess = (detail) => ({
  type: GET_REFERRALS_DETAIL_SUCCESS,
  payload: detail
});

export const getReferralDetailFailure = (error) => ({
  type: GET_REFERRALS_DETAIL_FAILURE,
  payload: error,
});

export const fileUploadRequest = () => ({
  type: FILE_UPLOAD_REQUEST,
});

export const fileUploadSuccess = (isFileUploaded) => ({
  type: FILE_UPLOAD_SUCCESS,
  payload: isFileUploaded
});

export const fileUploadFailure = (errorMessage) => ({
  type: FILE_UPLOAD_FAILURE,
  payload: errorMessage,
});

export const deleteDocumentRequest = () => ({
  type: DELETE_DOCUMENT_REQUEST,
});

export const deleteDocumentSuccess = (message) => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: message,
});

export const deleteDocumentFailure = (error) => ({
  type: DELETE_DOCUMENT_FAILURE,
  payload: error,
});

export const updateUserRequest = () => ({
  type: UPDATE_USER_REQUEST,
});

export const updateUserSuccess = (message) => ({
  type: UPDATE_USER_SUCCESS,
  payload: message,
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
});

export const deleteUserRequest = () => ({
  type: DELETE_USER_REQUEST,
});

export const deleteUserSuccess = (message) => ({
  type: DELETE_USER_SUCCESS,
  payload: message,
});

export const deleteUserFailure = (error) => ({
  type: DELETE_USER_FAILURE,
  payload: error,
});

export const generateOTP = (payload) => async (dispatch) => {
  dispatch(generateOTPRequest());
  dispatch(startLoader());
  try {
    const response = await generateOTPService(payload);
    if (response.success) {
      dispatch(generateOTPSuccess(response.message));
      showToast(response.message, 'success');
    } else {
      dispatch(generateOTPFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(generateOTPFailure('Error generating OTP'));
    showToast('Error generating OTP', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const verifyOTP = (payload, navigate) => async (dispatch) => {
  dispatch(verifyOTPRequest());
  dispatch(startLoader());

  try {
    const response = await verifyOTPService(payload);

    if (response.success) {
      const { token } = response.data;
      dispatch(setToken(token));
      dispatch(setUserDetails(response.data))

      dispatch(verifyOTPSuccess(response.message));
      showToast(response.message, 'success');
      navigate('/app');
    } else {
      dispatch(verifyOTPFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(verifyOTPFailure('Error verifying OTP'));
    showToast('Error verifying OTP', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getAdminRoles = () => async (dispatch) => {
  dispatch(getAdminRole());
  dispatch(startLoader());

  try {
    const response = await getAdminRolesService();

    if (response.success) {
      dispatch(getAdminRoleSuccess(response.data));
    } else {
      dispatch(getAdminRoleFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getAdminRoleFailure('Error getting admin role'));
    showToast('Error getting admin roles', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getUsersList = () => async (dispatch) => {
  dispatch(getUserList());
  dispatch(startLoader());

  try {
    const response = await getUsersListService();
    if (response.success) {
      dispatch(getUserListSuccess(response.data));
    } else {
      dispatch(getUserListFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getUserListFailure('Error getting users'));
    showToast('Error getting users list', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const createUser = (payload) => async (dispatch, getState) => {
  dispatch(createNewUser());
  dispatch(startLoader());

  try {
    const response = await createNewUserService(payload);

    if (response.success) {
      dispatch(setNewUserCreated(true));

      const userState = getState().user || {};
      const updatedUsersList = [...userState.usersList, response.data];

      dispatch({
        type: GET_USERS_LIST_SUCCESS,
        payload: updatedUsersList,
      });

      showToast(response.message, 'success');
    } else {
      dispatch(createUserFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(createUserFailure('Error creating new user'));
    showToast('Error creating new user', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getReferralCodeList = () => async (dispatch) => {
  dispatch(getReferralsList());
  dispatch(startLoader());

  try {
    const response = await getReferralsListService();
    if (response.success) {
      dispatch(getReferralsListSuccess(response.data));
    } else {
      dispatch(getReferralsListFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getReferralsListFailure('Error getting list'));
    showToast('Error getting list', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getReferralUserDetail = (referralCode) => async (dispatch) => {
  dispatch(getReferralDetail());
  dispatch(startLoader());

  try {
    const response = await getAppUserFromReferralCode(referralCode);
    if (response.success && response.data) {
      dispatch(getReferralDetailSuccess(response.data));
    } else if (!response.data) {
      showToast('Details not found', "error");
    } else {
      dispatch(getReferralDetailFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getReferralDetailFailure('Error getting detail'));
    showToast('Error getting detail', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

// Action creator for file upload
export const uploadFile = (files, userId, docType) => async (dispatch) => {
  dispatch(fileUploadRequest());
  dispatch(startLoader());
  try {
    const responseData = await uploadFileService(files, userId, docType);
    if (responseData.success) {
      dispatch(fileUploadSuccess(responseData.success));
    }
    showToast(responseData.message, 'success');

  } catch (error) {
    // Dispatch failure action and show error message
    dispatch(fileUploadFailure('Error uploading files'));
    showToast('Error uploading files', 'error');
  } finally {
    // Stop loader in any case
    dispatch(stopLoader());
  }
};

export const deleteDocument = (type, documentId) => async (dispatch, getState) => {
  dispatch(deleteDocumentRequest());
  dispatch(startLoader());
  try {
    const response = await deleteDocumentService(documentId);
    dispatch(deleteDocumentSuccess(response));
    if(type === 'candidate'){
      const candidateState = getState().candidate || {};
      const updatedCandidateById = { ...candidateState.candidateById };
  
      // Check if data exists and if documents array exists
      if (updatedCandidateById && updatedCandidateById.data && updatedCandidateById.data.documents) {
        // Filter out the document with the given documentId
        const updatedCandidateById = {
          ...candidateState.candidateById,
          data: {
            ...candidateState.candidateById.data,
            documents: candidateState.candidateById.data.documents.filter(document => document.id !== documentId)
          }
        };
        
        // Update the state with the modified candidateById data 
        dispatch({
          type: GET_SINGLE_CANDIDATE_SUCCESS,
          payload: updatedCandidateById,
        });
    }
    }
    if(type === 'employer'){
      const employerState = getState().employer || {};
      const updatedEmployerById = { ...employerState.employerById };

       // Check if data exists and if documents array exists
       if (updatedEmployerById && updatedEmployerById.data && updatedEmployerById.data.documents) {
        // Filter out the document with the given documentId
        const updatedEmployerById = {
          ...employerState.employerById,
          data: {
            ...employerState.employerById.data,
            documents: employerState.employerById.data.documents.filter(document => document.id !== documentId)
          }
        };
        
        // Update the state with the modified candidateById data 
        dispatch({
          type: GET_SINGLE_EMPLOYER_SUCCESS,
          payload: updatedEmployerById,
        });
      }
    }

    showToast("document deleted successfully", 'success');
  } catch (error) {
    dispatch(deleteDocumentFailure('Error deleting document'));
    showToast('Error deleting files', 'error');
  } finally {
    // Stop loader in any case
    dispatch(stopLoader());
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  dispatch(updateUserRequest());
  dispatch(startLoader());
  try {
    const response = await updateUserService(user);
    const userState = getState().user || {};
    const updatedUser = response.data;
    const updatedUserList = userState.usersList?.map((existingUser) => {
      if (existingUser.id === updatedUser.id) {
        return {
          ...existingUser,
          ...updatedUser,
        };
      }
      // Return the unmodified employer object if it doesn't match the ID
      return existingUser;
    });
    dispatch({
      type: GET_USERS_LIST_SUCCESS,
      payload: updatedUserList,
    });

    dispatch(updateUserSuccess(response));
    showToast("user updated successfully", 'success');
  } catch (error) {
    dispatch(updateUserFailure('Error updating user'));
    showToast('Error updating user', 'error');
  } finally {
    // Stop loader in any case
    dispatch(stopLoader());
  }
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  dispatch(deleteUserRequest());
  dispatch(startLoader());
  try {
    const response = await deleteUserService(userId);

    const userState = getState().user || {};

    // Remove the user from the list if its ID matches the updated user's ID
    const updatedUserList = userState.usersList?.filter(existingUser => existingUser.id !== userId);

    dispatch({
      type: GET_USERS_LIST_SUCCESS,
      payload: updatedUserList,
    });


    dispatch(deleteUserSuccess(response));
    showToast('User deleted successfully', 'success');
  } catch (error) {
    dispatch(deleteUserFailure('Error deleting user'));
    showToast('Error deleting user', 'error');
  } finally {
    // Stop loader in any case
    dispatch(stopLoader());
  }
};