import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import WorkIcon from '@mui/icons-material/Work';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import TextFieldComponent from '../components/TextFieldComponent';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmployerData, getEmployeesList, getSingleEmployerData } from '../redux/actions/employerActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EmployerProfileModal from '../models/EmployerProfile';
import moment from 'moment';
import EmployerForm from './EmployerForm'
import { useNavigate } from 'react-router-dom';
import JobPostForm from './JobPostForm';
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GigPostForm from '../pages/GigPostForm';
import PaidIcon from '@mui/icons-material/Paid';
import './css/Button.css';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { isSuperUser } from '../redux/selectors/userSelector';
import DocumentUploadModal from '../models/DocumentUploadModal';
import constants from '../helpers/constants.json';
import { Add, ArrowBackIosNew, FilterAlt } from '@mui/icons-material';
import ExportComponent from '../components/ExportComponent';
import { IconButton } from '@mui/material';
import EmployerFilters from './EmployerFilters';


const ViewEmployers = () => {

  const MENU_ID = 'EMPLOYER_ACTION'
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedEmployerDetail, setSelectedEmployerDetail] = useState(null);

  const isMountingRef = useRef(false);

  const [employer, setEmployer] = useState(null);

  const [queryParams, setQueryParams] = useState({
    pageNumber: 0, // default to the first page
    searchText: ''
  });

  const messageStyle = {
    color: 'green',
    margin: "16px 0",
    fontWeight: 'bold',
  };

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const handleContextMenu = (event, employer, index) => {
    setSelectedRow(index);
    show({
      event,
      props: employer,
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const superUser = useSelector(isSuperUser);

  const employersResponse = useSelector((state) => state.employer.employer);
  const employers = employersResponse?.data;

  const employerDetails = useSelector((state) => state.employer.employerById);
  const employerDataById = employerDetails?.data;

  const employerFilters = useSelector((state) => state.employer.employerFilters);

  const [searchTerm, setSearchTerm] = useState('');
  const [isAddEmployerVisible, setAddEmployerVisible] = useState(false);
  const [isEmployerDetailVisible, setIsEmployerDetailVisible] = useState(false);
  const [isAddJobVisible, setAddJobVisible] = useState(false);
  const [isAddGigVisible, setAddGigVisible] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [employerId, setEmployerId] = useState(null);
  const [isDocumentUploadModal, setDocumentUploadModal] = useState(false);
  const [isEmployerFiltersOpen, setIsEmployerFiltersOpen] = useState(false);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    let aParams = { ...queryParams, ...employerFilters };
    dispatch(getEmployeesList(aParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (!isMountingRef.current) {
      setQueryParams((prevParams) => ({ ...prevParams, pageNumber: 0 }));
    } else {
      isMountingRef.current = false;
    }
  }, [employerFilters]);


  const handleAddEmployerClick = () => {
    setSelectedEmployerDetail(null);
    setAddEmployerVisible(!isAddEmployerVisible);
    setAddGigVisible(false);
    setAddJobVisible(false);
  };

  const handleEditClick = (employer) => {
    setSelectedEmployerDetail(employer)

    setAddEmployerVisible(true);
  };

  const handleViewMore = (id) => {
    dispatch(getSingleEmployerData(id));
    setIsEmployerDetailVisible(true);
  };

  const handleDeleteClick = (id) => {
    setEmployerId(id);
    setShowDeleteDialog(true)
  };

  const handlePageChange = (event, page) => {
    handleCloseEmployerOrJobModal();
    setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
  };

  const handleCloseEmployerOrJobModal = () => {
    setEmployer(null);
    setAddJobVisible(false);
    setAddEmployerVisible(false);
    setIsEmployerDetailVisible(false);
    setAddGigVisible(false);
  };

  const handleCloseAllForm = () => {
    setEmployer(null);
    setAddJobVisible(false);
    setAddEmployerVisible(false);
    setAddGigVisible(false);
  }

  const handleSearch = (text) => {
    handleCloseEmployerOrJobModal();
    setSearchTerm(text);
    clearTimeout(typingTimeout); // Clear the previous timeout
    const timeout = setTimeout(() => {
      setQueryParams((prevParams) => ({ ...prevParams, searchText: text, pageNumber: 0 }));
    }, 2000);
    setTypingTimeout(timeout);
  };

  const handleAddJobClick = (employer) => {
    setAddJobVisible(true);
    setAddGigVisible(false)
    setAddEmployerVisible(false);
    setEmployer(employer);
  };

  const handleAddGigsClick = (employer) => {
    setAddGigVisible(true);
    setAddJobVisible(false);
    setAddEmployerVisible(false);
    setEmployer(employer);
  }

  const handleNavigateJobs = (employerId) => {
    // Navigate to the 'jobs' route with the 'employerId' as a query parameter
    navigate(`/app/jobs?employerId=${employerId}`);
  };

  const handleNavigateGigs = (employerId) => {
    // Navigate to the 'jobs' route with the 'employerId' as a query parameter
    navigate(`/app/gigs?employerId=${employerId}`);
  };

  const handleConfirmationDialogClose = () => {
    setEmployerId(null);
    setShowDeleteDialog(false)
  };

  const handleConfirmationDialog = () => {
    dispatch(deleteEmployerData(employerId))
    setShowDeleteDialog(false);
    setEmployerId(null);
    setSelectedRow(null);
  };

  const handleUploadDocumentModal = (employer) => {
    setDocumentUploadModal(true)
    setEmployerId(employer.employerId);
  };

  const handleCloseUploadDocumentModal = () => {
    setDocumentUploadModal(false);
    setEmployerId(null);
  }

  const handleFilterClick = () => {
    setIsEmployerFiltersOpen(true);
  }

  const handleCloseEmployerFilters = () => {
    setIsEmployerFiltersOpen(false);
  }

  const handleItemClick = ({ event, id }) => {
    const employer = event.props
    const { employerId } = event.props;
    switch (id) {
      case 'upload_document':
        handleUploadDocumentModal(employer)
        break;
      case "view_more":
        handleViewMore(employerId)
        break;
      case "view_jobs":
        handleNavigateJobs(employerId)
        break;
      case "view_gigs":
        handleNavigateGigs(employerId);
        break;
      case "add_job":
        handleAddJobClick(employer)
        break;
      case "add_gig":
        handleAddGigsClick(employer)
        break;
      case "edit":
        handleEditClick(employer)
        break;
      case "delete":
        handleDeleteClick(employerId)
        break;
      default:
        break
    }
  }

  return (
    <Grid container spacing={3}>
      {/* Left side: Employers Table */}
      <Grid size={{ xs: 12, md: isAddEmployerVisible || isAddJobVisible || isAddGigVisible ? 8 : (isEmployerFiltersOpen ? 9 : 12) }}>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {/* Search Text Field */}
            <div>
              <TextFieldComponent
                label="Search"
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: '350px' }}
              />
            </div>


            <div style={{ display: 'flex' }}>
              {superUser && (
                <ExportComponent
                  screenName={constants.screenName.EMPLOYER}
                  style={{ marginLeft: '10px' }}
                />
              )}
            </div>
          </div>

          <div>
            {/* Filter Button */}
            <Tooltip title="Filter">
              <IconButton
                style={{ marginLeft: '8px' }}
                sx={{
                  backgroundColor: "#1976d2",
                  color: 'white',
                  ':hover': {
                    backgroundColor: 'primary.light'
                  },
                }}
                onClick={handleFilterClick}>
                <FilterAlt />
              </IconButton>
            </Tooltip>

            {/* Add Employer Button */}
            <Tooltip title="Add Employer">
              <IconButton
                style={{ marginLeft: '8px' }}
                sx={{
                  backgroundColor: "#1976d2",
                  color: 'white',
                  ':hover': {
                    backgroundColor: 'primary.light'
                  },
                }}
                onClick={handleAddEmployerClick}>
                <Add />
              </IconButton>
            </Tooltip>
          </div>
        </div>


        <div style={{ margin: '16px 0' }} />

        <TableContainer component={Paper}>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold}>Name</TableCell>
                <TableCell style={styles.textBold}>Mobile Number</TableCell>
                <TableCell style={styles.textBold}>Brand Name</TableCell>
                <TableCell style={styles.textBold}>Company Name</TableCell>
                <TableCell style={styles.textBold}>Outlet Type</TableCell>
                <TableCell style={styles.textBold}>Job Posts</TableCell>
                <TableCell style={styles.textBold}>Verified</TableCell>
                <TableCell style={styles.textBold}>Assisted Hire</TableCell>
                <TableCell style={styles.textBold}>Created At</TableCell>
                <TableCell style={styles.textBold}>Updated At</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {employers?.length > 0 ? (
                employers.map((employer, index) => (
                  <TableRow onContextMenu={(event) => handleContextMenu(event, employer, index)} key={employer.employerId}
                    onClick={() => setSelectedRow(index)}
                    onDoubleClick={() => handleViewMore(employer.employerId)}
                    style={{
                      backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                    }}
                  >
                    <TableCell >{employer.name}</TableCell>
                    <TableCell>{employer.mobileNumber}</TableCell>
                    <TableCell>{employer.brandName}</TableCell>
                    <TableCell>{employer.companyName}</TableCell>
                    <TableCell>{employer.outletType}</TableCell>
                    <TableCell>{employer.noOfJobPosts}</TableCell>
                    <TableCell>{employer.verified === false ? 'No' : 'Yes'}</TableCell>
                    <TableCell>{employer.assistedHire === false ? 'No' : 'Yes'}</TableCell>
                    <TableCell>{employer.createDate && moment(employer.createDate).format("DD/MM/YYYY")}</TableCell>
                    <TableCell>{employer.updateDate && moment(employer.updateDate).format("DD/MM/YYYY")}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={16}>
                    <h1 style={{ textAlign: 'center', marginTop: '20px' }}>No Employers</h1>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Menu id={MENU_ID}>
          <Item id="upload_documents" onClick={(event, props) => handleItemClick({ event, props, id: 'upload_document' })}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <DocumentScannerIcon />
              <span style={{ marginLeft: '5px' }}>Upload Documents / Images</span>
            </div>
          </Item>
          <Separator />
          <Item id="view_more" onClick={(event, props) => handleItemClick({ event, props, id: 'view_more' })}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <VisibilityIcon />
              <span style={{ marginLeft: '5px' }}>View More</span>
            </div>
          </Item>
          <Item id="view_jobs" onClick={(event) => handleItemClick({ event, id: 'view_jobs' })}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <WorkIcon />
              <span style={{ marginLeft: '5px' }}>View Jobs</span>
            </div>
          </Item>
          <Item id="view_gigs" onClick={(event) => handleItemClick({ event, id: 'view_gigs' })}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <PaidIcon />
              <span style={{ marginLeft: '5px' }}>View Gigs</span>
            </div>
          </Item>
          <Separator />
          <Item id="add_job" onClick={(event) => handleItemClick({ event, id: 'add_job' })}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
              <AddCircleRounded />
              <span style={{ marginLeft: '5px' }}>Add Job</span>
            </div>
          </Item>
          <Item id="add_gig" onClick={(event) => handleItemClick({ event, id: 'add_gig' })}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <AttachMoneyIcon />
              <span style={{ marginLeft: '5px' }}>Add Gigs</span>
            </div>
          </Item>
          <Separator />
          <Item id="edit" onClick={(event) => handleItemClick({ event, id: 'edit' })}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <EditIcon />
              <span style={{ marginLeft: '5px' }}>Edit</span>
            </div>
          </Item>
          {superUser && <Item id="delete" onClick={(event) => handleItemClick({ event, id: 'delete' })}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <DeleteIcon />
              <span style={{ marginLeft: '5px' }}>Delete</span>
            </div>
          </Item>}
        </Menu>


        <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
          <Pagination
            page={queryParams.pageNumber + 1}
            onChange={handlePageChange}
            color="primary"
            count={employersResponse?.metadata?.totalPages}
            size="small"
          />
        </Grid>
      </Grid>

      {/* Right side: Add User Button */}
      {
        isAddEmployerVisible && (
          <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
            <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleCloseAllForm} style={{ marginBottom: '16px' }}>
              Back
            </Button>
            <EmployerForm onClose={() => setAddEmployerVisible(false)} employerQueryParams={queryParams} employerDetail={selectedEmployerDetail} />
          </Grid>
        )
      }

      {
        isAddJobVisible && !isAddEmployerVisible && !isAddGigVisible && (
          <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
            <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleCloseAllForm}>
              Back
            </Button>
            <div style={messageStyle}>
              You are adding a job for {employer.name} at {employer.brandName}.
              The job is associated with the company {employer.companyName} and falls under the outlet type {employer.outletType}.
            </div>
            <JobPostForm
              employerId={employer.employerId}
              onClose={handleCloseEmployerOrJobModal}
              isOpen={isAddJobVisible}
            />
          </Grid>
        )
      }

      {
        isAddGigVisible && !isAddEmployerVisible && !isAddJobVisible && (
          <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
            <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleCloseAllForm}>
              Back
            </Button>
            <div style={messageStyle}>
              You are adding a gig for {employer.name} at {employer.brandName}.
              The job is associated with the company {employer.companyName} and falls under the outlet type {employer.outletType}.
            </div>
            <GigPostForm
              employerId={employer.employerId}
              onClose={handleCloseEmployerOrJobModal}
              isOpen={isAddGigVisible}
            />
          </Grid>
        )
      }

      {isEmployerFiltersOpen &&
        <Grid size={{ xs: 12, md: 3 }} style={{ paddingTop: '8px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
          <EmployerFilters
            onClose={handleCloseEmployerFilters}
            filtersData={employerFilters}
          />
        </Grid>
      }

      {
        isEmployerDetailVisible && employerDataById !== null && (
          <EmployerProfileModal
            isOpen={isEmployerDetailVisible}
            onClose={handleCloseEmployerOrJobModal}
            employerData={employerDataById}
          />
        )
      }

      {showDeleteDialog && (
        <ConfirmationDialog
          open={true}
          onClose={handleConfirmationDialogClose}
          onConfirm={handleConfirmationDialog}
          message="Do you want to Delete?"
        />
      )}

      {
        isDocumentUploadModal === true && (
          <DocumentUploadModal documentTypes={constants.employerDocumentTypes} userId={employerId} open={isDocumentUploadModal} onClose={handleCloseUploadDocumentModal} />
        )
      }

    </Grid >
  );
};

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}
export default ViewEmployers;
