import React from 'react';
import Button from '@mui/material/Button';

const ButtonComponent = ({ type, label, disabled, ...props }) => {
  return (
    <Button
      type={type}
      variant="contained"
      style={{ marginTop: '20px', backgroundColor: 'black', color: 'white' }}
      disabled={disabled}
      {...props}
    >
      {label}
    </Button>
  );
};

export default ButtonComponent;
