import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { formatIndianCurrency } from '../helpers/formatIndianCurrency';
import { createProfileSuggestion, getSingleCandidateData, removeSelectCandidatesForJobSuggestion } from '../redux/actions/candidateActions';
import { getJobByIdDetails, removeAllJobsFromJobSuggestion, removeSelectJobsForJobSuggestion } from '../redux/actions/jobActions';
import showToast from '../utils/toast';
import JobPostRecordModal from './JobPostRecord';
import CandidateProfileModal from './CandidateProfile';


const SuggestedCandidatesAndJobsModal = ({ isOpen, onClose }) => {

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(4px)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      left: '5%',
      top: '10%',
      zIndex: 1001,
      width: '94%',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      overflowY: 'auto',
    },
  }

  const dummyImageURL = 'https://via.placeholder.com/150';
  const dispatch = useDispatch();

  const [isMoreDetailsVisibleForJobs, setIsMoreDetailsVisibleForJobs] = useState(false);
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);

  const suggestedCandidates = useSelector((state) => state.candidate.selectedCandidatesForJobSuggestion);
  const suggestedJobs = useSelector((state) => state.job.selectedJobsForJobSuggestion);

  const jobMoreDetailsResponse = useSelector((state) => state.job.jobDetailsById);
  const jobMoreDetails = jobMoreDetailsResponse?.data;

  const candidateById = useSelector((state) => state.candidate.candidateById);
  const candidateDataById = candidateById?.data;


  const onConfirm = () => {
    const payload = {
      candidateIds: suggestedCandidates.map(candidate => candidate.candidateId),
      jobIds: suggestedJobs.map(job => job.jobPostId)
    }
    dispatch(createProfileSuggestion(payload))
    dispatch(removeAllJobsFromJobSuggestion())
    onClose();
    setTimeout(() => {
      window.location.reload();
    }, 1000)
  
  }

  const removeSelectedJob = (jobId) => {
    if (suggestedJobs.length > 1) {
      dispatch(removeSelectJobsForJobSuggestion(jobId))
    } else {
      showToast("At least 1 job should be selected ", "error")
    }
  }

  const removeSelectedCandidate = (candidateId) => {
    if (suggestedCandidates.length > 1) {
      dispatch(removeSelectCandidatesForJobSuggestion(candidateId))
    } else {
      showToast("At least 1 candidate should be selected ", "error")
    }
  }

  const viewMoreDetailsForJob = (id) => {
    setIsMoreDetailsVisibleForJobs(true);
    dispatch(getJobByIdDetails(id));
  };

  const viewMoreDetailsForCandidate = (id) => {
    handleCloseCandidateModal();
    dispatch(getSingleCandidateData(id));
    setIsCandidateModalOpen(true)
}

  const handleCloseJobMoreDetailsForJobModal = () => {
    setIsMoreDetailsVisibleForJobs(false);
  };

  const handleCloseCandidateModal = () => {
    setIsCandidateModalOpen(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Suggested Candidates and Jobs Modal"
      style={customStyles}
    >
      <div style={{ width: '100%', position: 'relative' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Suggested Candidates and Jobs</h2>
          <IconButton onClick={onClose} style={{ marginTop: '1rem' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold}>Candidate Name</TableCell>
                <TableCell style={styles.textBold}>Location</TableCell>
                <TableCell style={styles.textBold}>Job Location</TableCell>
                <TableCell style={styles.textBold}>Last Job Company</TableCell>
                <TableCell style={styles.textBold}>Preferred Role 1</TableCell>
                <TableCell style={styles.textBold}>Preferred Role 2</TableCell>
                <TableCell style={styles.textBold}>Preferred Role 3</TableCell>
                <TableCell style={styles.textBold}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suggestedCandidates.map(candidate => (
                <TableRow key={candidate.candidateId}>
                  <TableCell  style={{ cursor: 'pointer' }}  onClick={() => viewMoreDetailsForCandidate(candidate.candidateId)}>{candidate.candidateName}</TableCell>
                  <TableCell>{candidate.location}</TableCell>
                  <TableCell>{candidate.jobLocation}</TableCell>
                  <TableCell>{candidate.lastJobTitle}</TableCell>
                  <TableCell>{candidate.preferredRole1}</TableCell>
                  <TableCell>{candidate.preferredRole2}</TableCell>
                  <TableCell>{candidate.preferredRole3}</TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => removeSelectedCandidate(candidate.candidateId)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <h2>Suggested Jobs</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold} >Logo</TableCell>
                <TableCell style={styles.textBold}>Employer</TableCell>
                <TableCell style={styles.textBold}>Job Title</TableCell>
                <TableCell style={styles.textBold}>Location</TableCell>
                <TableCell style={styles.textBold}>Salary Range</TableCell>
                <TableCell style={styles.textBold}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suggestedJobs.map(job => (
                <TableRow key={job.jobPostId}>
                  <TableCell style={{ cursor: 'pointer' }} onClick={() => viewMoreDetailsForJob(job.jobPostId)} >
                    {job.employerCompanyLogo ? (
                      <div style={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <img
                          src={job.employerCompanyLogo}
                          alt={`Profile of ${job.employerBrandName}`}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%'
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <img
                          src={dummyImageURL}
                          alt="Dummy Profile"
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%'
                          }}
                        />
                      </div>
                    )}

                  </TableCell>
                  <TableCell style={{ cursor: 'pointer' }} onClick={() => viewMoreDetailsForJob(job.jobPostId)}>{job.employerBrandName}</TableCell>
                  <TableCell>{job.jobTitle}</TableCell>
                  <TableCell>{job.jobLocation}</TableCell>
                  <TableCell>{`${formatIndianCurrency(job.salaryLowerRange)} - ${formatIndianCurrency(
                    job.salaryUpperRange
                  )}`}</TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => removeSelectedJob(job.jobPostId)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ position: 'sticky', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <Button style={{ width: '100px', marginRight: '10px' }} variant="contained" onClick={onConfirm} color="primary">
            Confirm
          </Button>
          <Button style={{ width: '100px' }} variant="contained" onClick={onClose} color="error">
            Cancel
          </Button>
        </div>
      </div>

      {isMoreDetailsVisibleForJobs && jobMoreDetails !== null && (
        <JobPostRecordModal
          isOpen={isMoreDetailsVisibleForJobs}
          onClose={handleCloseJobMoreDetailsForJobModal}
          jobPostData={jobMoreDetails}
        />
      )}

      {isCandidateModalOpen && candidateDataById !== null && (
        <CandidateProfileModal
          isOpen={isCandidateModalOpen}
          onClose={handleCloseCandidateModal}
          candidateData={candidateDataById}
        />
      )}

    </ReactModal>
  );
};

export default SuggestedCandidatesAndJobsModal;

const styles = {
  textBold: {
      fontWeight: 'bold'
  }
}