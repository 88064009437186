import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@mui/material/TextField';

const TimePickerComponent = ({ label, field, form, ...props }) => {
  return (
    <DatePicker
  
      selected={field.value}
      onChange={(date) => form.setFieldValue(field.name, date)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={60}
      timeCaption="Time"
      dateFormat="h:mm aa"
      placeholderText={`Select ${label}`}
      popperPlacement="bottom-end" // Adjust the placement as needed
      {...props}
      customInput={
        <TextField
          inputProps={{ autoComplete: 'off' }}
          label={label}
          fullWidth
          sx={{ width: '100%' }} 
          error={field && form.touched[field.name] && Boolean(form.errors[field.name])}
          helperText={field && form.touched[field.name] && form.errors[field.name]}
        />
      }
    />
  );
};

export default TimePickerComponent;
