import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../components/TextFieldComponent';
import ButtonComponent from '../components/ButtonComponent';
import TimePickerComponent from '../components/TimePickerComponent';
import DropdownComponent from '../components/DropDownComponent';
import constants from '../helpers/constants.json';
import { createGigPost, editGigPost, clearAddedGigFlag, clearEditedGigFlag } from '../redux/actions/gigsActions';
import { formatted12hTime, convertTimeStringToDate, formatYYYYMMDDDate } from '../helpers/formattedDateTime';
import { getJobRolesList } from '../redux/actions/jobActions';
import CreatableDropdownComponent from '../components/CreatableDropDown';

const validationSchema = Yup.object().shape({
    jobTitle: Yup.object().required('Job Title is required'),
    jobDescription: Yup.string().required('Job Description is required'),
    jobLocation: Yup.string().required('Job Location is required'),
    jobDate: Yup.date().required('Job Date is required'),
    englishProficiency: Yup.object().required('English Proficiency is required'),
    compensation: Yup.number().required('Compensation is required'),
    timeStart: Yup.string().required('Time Start is required'),
    timeEnd: Yup.string().required('Time End is required'),
    noOfPosition: Yup.number().required('Number of Positions is required'),
});

const GigPostForm = ({ employerId, gigDetail, onClose }) => {
    const dispatch = useDispatch();

    const [rolesArray, setRolesArray] = useState([]);
    const { englishProficiency } = constants;
    const jobRoles = useSelector((state) => state.job.jobRoles?.data);
    const isEditGigSuccess = useSelector((state) => state.gig.editGigSuccess);
    const isNewGigPostCreated = useSelector((state) => state.gig.newGigCreated);

    const englishProfObject = englishProficiency.find(englishProf => englishProf?.id === gigDetail?.englishProficiency)
    const jobTitleObject = gigDetail?.jobTitle ? {
        "name": gigDetail.jobTitle,
        "id": gigDetail.jobTitle,
        "description": gigDetail.jobDescription
    } : null;

    const updatedGigDetails = {
        ...gigDetail,
        englishProficiency: englishProfObject || null,
        jobTitle: jobTitleObject || null
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            jobTitle: updatedGigDetails ? updatedGigDetails.jobTitle : '',
            jobDescription: gigDetail ? gigDetail.jobDescription : '',
            jobLocation: gigDetail ? gigDetail.jobLocation : '',
            jobDate: gigDetail ? new Date(gigDetail.jobDate) : null,
            englishProficiency: updatedGigDetails ? updatedGigDetails.englishProficiency : '',
            compensation: gigDetail ? gigDetail.compensation : '',
            additionalFacilities: gigDetail ? gigDetail.additionalFacilities : '',
            additionalRequirements: gigDetail ? gigDetail.additionalRequirements : '',
            timeStart: gigDetail ? convertTimeStringToDate(gigDetail.timeStart) : null,
            timeEnd: gigDetail ? convertTimeStringToDate(gigDetail.timeEnd) : null,
            callingHoursStart: gigDetail ? convertTimeStringToDate(gigDetail.callingHoursStart) : null,
            callingHoursEnd: gigDetail ? convertTimeStringToDate(gigDetail.callingHoursEnd) : null,
            noOfPosition: gigDetail ? gigDetail.noOfPosition : '',
            modeOfPayment: gigDetail ? gigDetail.modeOfPayment : '',
            employerId: employerId,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                jobTitle: values.jobTitle.name,
                englishProficiency: values.englishProficiency?.id,
                jobDate: formatYYYYMMDDDate(values.jobDate),
                timeStart: formatted12hTime(values.timeStart),
                timeEnd: formatted12hTime(values.timeEnd),
                callingHoursStart: formatted12hTime(values.callingHoursStart),
                callingHoursEnd: formatted12hTime(values.callingHoursEnd),
                compensation: parseInt(values.compensation, 10),
                noOfPosition: parseInt(values.noOfPosition, 10),
            };
            if (gigDetail?.gigPostId) {
                await dispatch(editGigPost(gigDetail.gigPostId, formattedValues));
            } else {
                await dispatch(createGigPost(formattedValues));
            }
        },
    });

    useEffect(() => {
        if (isEditGigSuccess) {
            formik.resetForm();
            dispatch(clearEditedGigFlag());
            onClose();
        }
    }, [dispatch, formik, isEditGigSuccess, onClose]);

    // Effect to fetch job roles list once when the component mounts
    useEffect(() => {
        dispatch(getJobRolesList());
    }, [dispatch]);

    useEffect(() => {
        if (jobRoles) {
            let tempArray = jobRoles?.map((obj) => ({ "name": obj.name, "id": obj.name, "description": obj.description }));
            if (gigDetail?.jobTitle && !jobRoles?.find(e => e.name == gigDetail?.jobTitle)) {
                tempArray.unshift({ "name": gigDetail?.jobTitle, "id": gigDetail?.jobTitle, "description": gigDetail?.jobDescription });
            }
            setRolesArray(tempArray);
        }
    }, [jobRoles, gigDetail?.jobTitle]);

    useEffect(() => {
        if (isNewGigPostCreated) {
            formik.resetForm();
            dispatch(clearAddedGigFlag());
        }
    }, [formik, dispatch, isNewGigPostCreated]);

    useEffect(() => {
        const selectedJobTitle = formik.values.jobTitle;
        // Check if selectedJobTitle has changed
        if (selectedJobTitle && selectedJobTitle.description) {
            formik.setFieldValue("jobDescription", selectedJobTitle.description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.jobTitle]);


    return (
        <form onSubmit={formik.handleSubmit}>
            <CreatableDropdownComponent
                label="Job Title *"
                field={{
                    ...formik.getFieldProps("jobTitle"),
                    onChange: formik.handleChange("jobTitle"),
                }}
                form={formik}
                options={rolesArray}
                fullWidth
                style={{ marginBottom: "16px" }}
            />

            <TextInputComponent
                label="Job Description *"
                type="text"
                multiline
                rows={4}
                field={{
                    ...formik.getFieldProps('jobDescription'),
                    onChange: formik.handleChange('jobDescription'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <TextInputComponent
                label="Job Location *"
                type="text"
                field={{
                    ...formik.getFieldProps('jobLocation'),
                    onChange: formik.handleChange('jobLocation'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <div style={{ marginBottom: '16px' }}>

                <TextInputComponent
                    label="Job Date *"
                    type="date"
                    field={{
                        ...formik.getFieldProps("jobDate"),
                        onChange: formik.handleChange("jobDate"),
                    }}
                    form={formik}
                    minDate={new Date()}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                />
            </div>

            <DropdownComponent
                label="English Proficiency *"
                field={{
                    ...formik.getFieldProps("englishProficiency"),
                    onChange: formik.handleChange("englishProficiency"),
                }}
                form={formik}
                options={englishProficiency}
                fullWidth
                style={{ marginBottom: "16px" }}
            />

            <TextInputComponent
                label="Compensation *"
                type="tel"
                inputmode="numeric"
                pattern="[0-9\s]{13,19}"
                field={{
                    ...formik.getFieldProps('compensation'),
                    onChange: formik.handleChange('compensation'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <TextInputComponent
                label="Additional Facilities"
                type="text"
                multiline
                rows={4}
                field={{
                    ...formik.getFieldProps('additionalFacilities'),
                    onChange: formik.handleChange('additionalFacilities'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <TextInputComponent
                label="Additional Requirement"
                type="text"
                multiline
                rows={4}
                field={{
                    ...formik.getFieldProps('additionalRequirements'),
                    onChange: formik.handleChange('additionalRequirements'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <div style={{ marginBottom: '16px' }}>
                <TimePickerComponent
                    label="Time Start *"
                    field={{
                        ...formik.getFieldProps('timeStart'),
                        onChange: formik.handleChange('timeStart'),
                    }}
                    form={formik}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TimePickerComponent
                    label="Time End *"
                    field={{
                        ...formik.getFieldProps('timeEnd'),
                        onChange: formik.handleChange('timeEnd'),
                    }}
                    form={formik}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TimePickerComponent
                    label="Calling Hours Start"
                    field={{
                        ...formik.getFieldProps('callingHoursStart'),
                        onChange: formik.handleChange('callingHoursStart'),
                    }}
                    form={formik}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
            </div>

            <div style={{ marginBottom: '16px' }}>
                <TimePickerComponent
                    label="Calling Hours End"
                    field={{
                        ...formik.getFieldProps('callingHoursEnd'),
                        onChange: formik.handleChange('callingHoursEnd'),
                    }}
                    form={formik}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                />
            </div>

            <TextInputComponent
                label="No Of Position *"
                type="tel"
                inputmode="numeric"
                pattern="[0-9\s]{13,19}"
                field={{
                    ...formik.getFieldProps('noOfPosition'),
                    onChange: formik.handleChange('noOfPosition'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <TextInputComponent
                label="Mode Of Payment"
                type="text"
                field={{
                    ...formik.getFieldProps('modeOfPayment'),
                    onChange: formik.handleChange('modeOfPayment'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />



            <ButtonComponent
                type="submit"
                label={formik.isSubmitting ? 'Submitting...' : 'Submit'}
                fullWidth
                disabled={formik.isSubmitting}
            />
        </form>
    );
};

export default GigPostForm;
