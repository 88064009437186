import './App.css';
import LoaderComponent from './components/LoaderComponent';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './routes/AppRouter';
import Modal from 'react-modal';


function App() {
  const loading = useSelector((state) => state.loader.loading);
  // Set the root element for react-modal
  Modal.setAppElement('#root');
  return (
    <>
    {loading && <LoaderComponent />} 
    <ToastContainer/>
    <AppRouter/>
    </>
  );
}

export default App;
