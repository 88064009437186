export const selectUserRole = (state) => state.user.userDetails.role

// Selector to check if the userRole is a subUser
export const isSubUser = (state) => {
 const userRole = selectUserRole(state)
  return userRole === 'SUB_ADMIN'
};

export const isSuperUser = (state) => { 
   const userRole = selectUserRole(state)
    return userRole === 'SUPER_ADMIN'
  };

  export const referralCodeList = (state) => {
    return state.user.referralCodesList
  }
  
  export const referralCodeDetail = (state) => {
    return state.user.referralDetailById
  }

  export const userDetails = (state) => {
    return state.user.userDetails;
  }
  