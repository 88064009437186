const formatIndianCurrency = (currencyValue) => {
    if (typeof currencyValue !== 'number') {
      return '';
    }
    // Format the currency using toLocaleString with 'en-IN' locale
    return currencyValue.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    });
  };
  
  export { formatIndianCurrency };
  