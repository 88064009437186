import { Box } from '@mui/material'
import React from 'react'

function Dashboard() {
  return (
    <Box height={100}>
 <div>Dashboard</div>
    </Box>
   
  )
}

export default Dashboard