import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployersAuditList } from '../redux/actions/employerActions';
import moment from 'moment';
import 'react-contexify/ReactContexify.css';
import { Tab, Tabs } from '@mui/material';
import { getCandidatesAuditList } from '../redux/actions/candidateActions';
import { getJobsAuditList } from '../redux/actions/jobActions';
import AuditDetailModal from '../models/AuditDetailModal';
import constants from '../helpers/constants.json';

const ViewAllAudit = () => {
  const [value, setValue] = React.useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isAuditDetailModalOpen, setIsAuditDetailModalOpen] = useState(false);
  const [selectedAuditRecord, setSelectedAuditRecord] = useState(null);

  const { revisionType, entityType } = constants;

  const [employerQueryParams, setEmployerQueryParams] = useState({
    pageNumber: 0,
    pageSize: 10
  });

  const [candidateQueryParams, setCandidateQueryParams] = useState({
    pageNumber: 0,
    pageSize: 10
  });

  const [jobQueryParams, setJobQueryParams] = useState({
    pageNumber: 0,
    pageSize: 10
  });

  const dispatch = useDispatch();
  const employersAuditResponse = useSelector((state) => state.employer.employersAudit);
  const employersAudit = employersAuditResponse?.data;

  const candidatesAuditResponse = useSelector((state) => state.candidate.candidatesAudit);
  const candidatesAudit = candidatesAuditResponse?.data;

  const jobsAuditResponse = useSelector((state) => state.job.jobsAudit);
  const jobsAudit = jobsAuditResponse?.data;

  useEffect(() => {
    dispatch(getEmployersAuditList(employerQueryParams));
  }, [dispatch, employerQueryParams]);

  const candidateDidMount = useRef(true);
  useEffect(() => {
    if (candidateDidMount.current) {
      candidateDidMount.current = false;
      return;
    }
    dispatch(getCandidatesAuditList(candidateQueryParams));
  }, [candidateQueryParams]);

  const jobDidMount = useRef(true);
  useEffect(() => {
    if (jobDidMount.current) {
      jobDidMount.current = false;
      return;
    }
    dispatch(getJobsAuditList(jobQueryParams));
  }, [dispatch, jobQueryParams]);

  const handlePageChange = (event, page) => {
    if (value == 0) {
      setEmployerQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
    } else if (value == 1) {
      setCandidateQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
    } else {
      setJobQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      if (candidatesAudit == null || candidatesAudit?.length == 0) {
        dispatch(getCandidatesAuditList(candidateQueryParams));
      }
    } else if (newValue == 2) {
      if (jobsAudit == null || jobsAudit?.length == 0) {
        dispatch(getJobsAuditList(jobQueryParams))
      }
    }
  };

  const handleCloseAuditDetailModal = () => {
    setIsAuditDetailModalOpen(false);
  }

  const openAuditDetailModal = (auditRecord, aEntityType) => {
    setIsAuditDetailModalOpen(true);
    let specAuditRecord = {
      "revisionId": auditRecord.revisionId,
      "entityType": aEntityType
    }
    if (aEntityType == entityType.EMPLOYER) {
      specAuditRecord["entityId"] = auditRecord?.employerEntity?.id;
    } else if (aEntityType == entityType.CANDIDATE) {
      specAuditRecord["entityId"] = auditRecord?.candidateEntity?.id;
    } else if (aEntityType == entityType.JOB_POST) {
      specAuditRecord["entityId"] = auditRecord?.jobPostEntity?.id;
    }
    setSelectedAuditRecord(specAuditRecord);
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const EmployersView = () => {
    return (
      <>
        {employersAudit?.length ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={styles.textBold}>User</TableCell>
                    <TableCell style={styles.textBold}>Date</TableCell>
                    <TableCell style={styles.textBold}>Type of Change</TableCell>
                    <TableCell style={styles.textBold}>Brand Name</TableCell>
                    <TableCell style={styles.textBold}>Mobile Number</TableCell>
                    <TableCell style={styles.textBold}>Created On</TableCell>
                    <TableCell style={styles.textBold}>Updated On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employersAudit?.map((employer, index) => (
                    <TableRow key={employer.employerId}
                      onClick={() => setSelectedRow(index)}
                      onDoubleClick={() => openAuditDetailModal(employer, entityType.EMPLOYER)}
                      style={{
                        backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                      }}
                    >
                      <TableCell>{employer.revisionType == "ADD" ? employer.createdBy : employer.updatedBy}</TableCell>
                      <TableCell>{employer.revisionDate && moment(employer.revisionDate).local().format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                      <TableCell>{revisionType[employer.revisionType]}</TableCell>
                      <TableCell>{employer.employerEntity.brandName}</TableCell>
                      <TableCell>{employer.employerEntity.mobileNumber}</TableCell>
                      <TableCell>{employer.employerEntity.createdOn && moment(employer.employerEntity.createdOn).local().format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                      <TableCell>{employer.employerEntity.updatedOn && moment(employer.employerEntity.updatedOn).local().format("DD/MM/YYYY HH:mm:ss")}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
              <Pagination
                page={employerQueryParams.pageNumber + 1}
                onChange={handlePageChange}
                color="primary"
                count={employersAuditResponse?.metadata?.totalPages}
                size="small"
              />
            </Grid>
          </>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1>No Employers Activity</h1>
          </div>
        )}
      </>
    )
  }

  const CandidatesView = () => {
    return (
      <>
        {candidatesAudit?.length ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={styles.textBold}>User</TableCell>
                    <TableCell style={styles.textBold}>Date</TableCell>
                    <TableCell style={styles.textBold}>Type of Change</TableCell>
                    <TableCell style={styles.textBold}>Name</TableCell>
                    <TableCell style={styles.textBold}>Mobile Number</TableCell>
                    <TableCell style={styles.textBold}>Created On</TableCell>
                    <TableCell style={styles.textBold}>Updated On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {candidatesAudit?.map((candidate, index) => (
                    <TableRow key={candidate.candidateId}
                      onClick={() => setSelectedRow(index)}
                      onDoubleClick={() => openAuditDetailModal(candidate, entityType.CANDIDATE)}
                      style={{
                        backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                      }}
                    >
                      <TableCell>{candidate.revisionType == "ADD" ? candidate.createdBy : candidate.updatedBy}</TableCell>
                      <TableCell>{candidate.revisionDate && moment(candidate.revisionDate).local().format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                      <TableCell>{revisionType[candidate.revisionType]}</TableCell>
                      <TableCell>{candidate.candidateEntity.name}</TableCell>
                      <TableCell>{candidate.candidateEntity.mobileNumber}</TableCell>
                      <TableCell>{candidate.candidateEntity.createdOn && moment(candidate.candidateEntity.createdOn).local().format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                      <TableCell>{candidate.candidateEntity.updatedOn && moment(candidate.candidateEntity.updatedOn).local().format("DD/MM/YYYY HH:mm:ss")}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
              <Pagination
                page={candidateQueryParams.pageNumber + 1}
                onChange={handlePageChange}
                color="primary"
                count={candidatesAuditResponse?.metadata?.totalPages}
                size="small"
              />
            </Grid>
          </>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1>No Candidates Activity</h1>
          </div>
        )}
      </>
    )
  }

  const JobsView = () => {
    return (
      <>
        {jobsAudit?.length ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={styles.textBold}>User</TableCell>
                    <TableCell style={styles.textBold}>Date</TableCell>
                    <TableCell style={styles.textBold}>Type of Change</TableCell>
                    <TableCell style={styles.textBold}>Job Title</TableCell>
                    <TableCell style={styles.textBold}>Employer</TableCell>
                    <TableCell style={styles.textBold}>Created On</TableCell>
                    <TableCell style={styles.textBold}>Updated On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobsAudit?.map((job, index) => (
                    <TableRow key={job.jobPostId}
                      onClick={() => setSelectedRow(index)}
                      onDoubleClick={() => openAuditDetailModal(job, entityType.JOB_POST)}
                      style={{
                        backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                      }}
                    >
                      <TableCell>{job.revisionType == "ADD" ? job.createdBy : job.updatedBy}</TableCell>
                      <TableCell>{job.revisionDate && moment(job.revisionDate).local().format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                      <TableCell>{revisionType[job.revisionType]}</TableCell>
                      <TableCell>{job.jobPostEntity.jobTitle}</TableCell>
                      <TableCell>{job.jobPostEntity.jobLocation}</TableCell>
                      <TableCell>{job.jobPostEntity.createdOn && moment(job.jobPostEntity.createdOn).local().format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                      <TableCell>{job.jobPostEntity.updatedOn && moment(job.jobPostEntity.updatedOn).local().format("DD/MM/YYYY HH:mm:ss")}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
              <Pagination
                page={jobQueryParams.pageNumber + 1}
                onChange={handlePageChange}
                color="primary"
                count={jobsAuditResponse?.metadata?.totalPages}
                size="small"
              />
            </Grid>
          </>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1>No Jobs Activity</h1>
          </div>
        )}
      </>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 12 }} >

        <div style={{ margin: '16px 0' }} />
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Employers" {...a11yProps(0)} />
          <Tab label="Candidates" {...a11yProps(1)} />
          <Tab label="Job Posts" {...a11yProps(2)} />
        </Tabs>

        {value == 0 &&
          <div>
            {EmployersView()}
          </div>
        }

        {value == 1 &&
          <div>
            {CandidatesView()}
          </div>
        }

        {value == 2 &&
          <div>
            {JobsView()}
          </div>
        }

      </Grid>

      {isAuditDetailModalOpen &&
        <AuditDetailModal
          isOpen={isAuditDetailModalOpen}
          onClose={handleCloseAuditDetailModal}
          auditRecord={selectedAuditRecord}
        />
      }
    </Grid>
  );
};

export default ViewAllAudit;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}