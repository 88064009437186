// ViewCandidates.js
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import TextFieldComponent from '../components/TextFieldComponent';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector, } from 'react-redux';
import { getCandidatesList, getSingleCandidateData, getCandidateJobApplicationsData, deleteCandidateRecord, setSelectCandidatesForJobSuggestion } from '../redux/actions/candidateActions';
import CandidateProfile from '../models/CandidateProfile';
import CandidateJobApplicationModal from '../models/CandidateJobApplicationModal';
import CandidateForm from '../pages/CandidateForm'
import moment from 'moment';
import ConfirmationDialog from '../components/ConfirmationDialog';
import clipboardService from '../utils/clipboardService';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { isSuperUser } from '../redux/selectors/userSelector';
import DocumentUploadModal from '../models/DocumentUploadModal';
import constants from '../helpers/constants.json';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CheckboxComponent from '../components/CheckBoxComponent';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Add, ArrowBackIosNew, FilterAlt, SwapVert } from '@mui/icons-material';
import NonAppUsersModal from '../models/NonAppUsers';
import { useDropzone } from 'react-dropzone';
import DropdownComponent from '../components/DropDownComponent';
import ExportComponent from '../components/ExportComponent';
import CandidateFilters from './CandidateFilters';
import { IconButton } from '@mui/material';

const baseStyle = {
    padding: '0px 8px',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#aaaaaa',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: 'grey',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const ViewCandidates = () => {

    const MENU_ID = 'CANDIDATE_ACTION'
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedRow, setSelectedRow] = useState(null);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [isNonAppUsersModalOpen, setIsNonAppUsersModalOpen] = useState(false);
    const [nonAppUserFile, setNonAppUserFile] = useState([]);
    const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
    const [isJobApplicationModalOpen, setIsJobApplicationModalOpen] = useState(false);
    const [candidateDetail, setCandidateDetail] = useState(null);
    const [isEditCandidate, setIsEditCandidate] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(null);
    const [isDocumentUploadModal, setDocumentUploadModal] = useState(false);
    const [selectedSuggestCandidates, setSelectedSuggestCandidates] = useState([]);
    const [candidateId, setCandidateId] = useState(null);
    const [isCandidateFiltersOpen, setIsCandidateFiltersOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [isAddCandidateVisible, setAddCandidateVisible] = useState(false);

    const candidatesResponse = useSelector((state) => state.candidate.candidates);
    const candidates = candidatesResponse?.data;

    const candidateFilters = useSelector((state) => state.candidate.candidateFilters);

    const jobApplicationsResponse = useSelector((state) => state.candidate.candidateJobApplications);
    const jobApplications = jobApplicationsResponse;

    const candidateById = useSelector((state) => state.candidate.candidateById);
    const candidateDataById = candidateById?.data;

    const isMountingRef = useRef(false);

    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const handleContextMenu = (event, candidate, index) => {
        setSelectedRow(index);
        show({
            event,
            props: candidate,
        });
    };

    const superUser = useSelector(isSuperUser);

    const [queryParams, setQueryParams] = useState({
        pageNumber: 0,
        searchText: ''
    });

    const handleCopyClick = (shareLink) => {
        clipboardService.copyToClipboard(shareLink);
    };

    useEffect(() => {
        isMountingRef.current = true;
    }, []);

    useEffect(() => {
        let aParams = { ...queryParams, ...candidateFilters };
        dispatch(getCandidatesList(aParams));
    }, [dispatch, queryParams]);

    useEffect(() => {
        if (!isMountingRef.current) {
            setQueryParams((prevParams) => ({ ...prevParams, pageNumber: 0 }));
        } else {
            isMountingRef.current = false;
        }
    }, [candidateFilters]);

    useEffect(() => {
        if (candidateDataById && isEditCandidate) {
            setCandidateDetail(candidateDataById);
            setIsEditCandidate(false);
            setAddCandidateVisible(true);
        }
    }, [candidateDataById, isEditCandidate]);


    const handleEditClick = async (candidate) => {
        setIsEditCandidate(true);
        await dispatch(getSingleCandidateData(candidate.candidateId))
    };

    const handleViewMoreClick = (id) => {
        handleCloseCandidateModal();
        dispatch(getSingleCandidateData(id));
        setIsCandidateModalOpen(true)
    }

    const handleJobApplicationClick = (id) => {
        dispatch(getCandidateJobApplicationsData(id, 0));
        setIsJobApplicationModalOpen(true)
    }

    const handleCloseJobApplicationModal = () => {
        setIsJobApplicationModalOpen(false);
    }

    const handleCloseCandidateForm = () => {
        setIsCandidateModalOpen(false)
        setAddCandidateVisible(false);
        setCandidateDetail(null);
    }

    const handleCloseNonAppUsersModal = () => {
        setIsNonAppUsersModalOpen(false);
    }

    const handleCloseCandidateModal = () => {
        setIsCandidateModalOpen(false);
    };

    const handleDeleteClick = (candidate) => {
        setCandidateDetail(candidate)
        setShowDeleteDialog(true)
    };

    const handlePageChange = (event, page) => {
        handleCloseCandidateForm();
        setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
    };

    const handleAddCandidateClick = () => {
        setCandidateDetail(null)
        setAddCandidateVisible(!isAddCandidateVisible);
    };


    const handleCloseUploadDocumentModal = () => {
        setCandidateDetail(null)
        setDocumentUploadModal(false);
    }

    const handleSearch = (text) => {
        setSearchTerm(text);
        clearTimeout(typingTimeout); // Clear the previous timeout
        const timeout = setTimeout(() => {
            setQueryParams((prevParams) => ({ ...prevParams, searchText: text, pageNumber: 0 }));
        }, 2000);
        setTypingTimeout(timeout);
    };

    const handleConfirmationDialogClose = () => {
        setShowDeleteDialog(false);
        setCandidateDetail(null)
    };

    const handleConfirmationDialog = () => {
        dispatch(deleteCandidateRecord(candidateDetail.candidateId))
        setShowDeleteDialog(false);
        setCandidateDetail(null)
        setSelectedRow(null);
    };

    const handleUploadDocumentModal = (candidate) => {
        setCandidateDetail(candidate);
        setDocumentUploadModal(true)
    };

    const handleItemClick = ({ event, id }) => {
        const candidate = event.props
        const { candidateId } = event.props;
        setCandidateId(candidateId);
        switch (id) {
            case 'upload_document':
                handleUploadDocumentModal(candidate)
                break;
            case "view_more":
                handleViewMoreClick(candidateId)
                break;
            case "job_applications":
                handleJobApplicationClick(candidateId)
                break;
            case "edit":
                handleEditClick(candidate);
                break;
            case "delete":
                handleDeleteClick(candidate)
                break;
            default:
                break
        }
    }

    const handleSuggestedCandidateChange = (candidate) => {
        setSelectedSuggestCandidates(prevSelectedCandidates => {
            if (prevSelectedCandidates.some(c => c.candidateId === candidate.candidateId)) {
                // If the candidate is already selected, remove it from the selected candidates list
                return prevSelectedCandidates.filter(c => c.candidateId !== candidate.candidateId);
            } else {
                // If the candidate is not selected, add it to the selected candidates list
                return [...prevSelectedCandidates, candidate];
            }
        });
    };

    const suggestCandidatesForJobs = () => {
        dispatch(setSelectCandidatesForJobSuggestion(selectedSuggestCandidates))
        navigate(`/app/jobs`);
    }

    const handleFilterClick = () => {
        setIsCandidateFiltersOpen(true);
    }

    const handleCloseCandidateFilters = () => {
        setIsCandidateFiltersOpen(false);
    }

    const openNonAppUsersModal = (resume, fileUrl) => {
        setIsNonAppUsersModalOpen(true);
    }

    const onDropFileUpload = async (files) => {
        setNonAppUserFile(files[0]);
        openNonAppUsersModal();
    }

    const onDrop = useCallback(acceptedFiles => {
        onDropFileUpload(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone(
        {
            onDrop,
            multiple: false
        })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <Grid container spacing={2}>
            {/* Left side: Candidates Table */}
            <Grid size={{ xs: 12, md: isAddCandidateVisible ? 8 : (isCandidateFiltersOpen ? 9 : 12) }} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Search Input and Form */}
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        {/* Search Input */}
                        <div>
                            <TextFieldComponent
                                label="Search"
                                type="text"
                                value={searchTerm}
                                onChange={(e) => handleSearch(e.target.value)}
                                style={{ width: '350px' }}
                            />
                        </div>

                        {superUser && (
                            <ExportComponent
                                screenName={constants.screenName.CANDIDATE}
                                style={{ marginLeft: '10px' }}
                            />
                        )}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '8px', alignItems: 'center' }}>
                        {selectedSuggestCandidates.length > 0 &&
                            <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                                <Button variant="contained" color="primary" onClick={suggestCandidatesForJobs}>
                                    <AddIcon style={{ marginRight: '8px' }} />
                                    Sugg. Profile ({selectedSuggestCandidates.length})
                                </Button>
                            </div>
                        }

                        <div>
                            <div {...getRootProps({ style })}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop the file or click to select file</p>
                            </div>
                        </div>

                        {/* Filter Button */}
                        <Tooltip title="Filter">
                            <IconButton
                                style={{ marginLeft: '8px' }}
                                sx={{
                                    backgroundColor: "#1976d2",
                                    color: 'white',
                                    ':hover': {
                                        backgroundColor: 'primary.light'
                                    },
                                }}
                                onClick={handleFilterClick}>
                                <FilterAlt />
                            </IconButton>
                        </Tooltip>

                        {/* Add Candidate Button */}
                        <Tooltip title="Add Candidate">
                            <IconButton
                                style={{ marginLeft: '8px' }}
                                sx={{
                                    backgroundColor: "#1976d2",
                                    color: 'white',
                                    ':hover': {
                                        backgroundColor: 'primary.light'
                                    },
                                }}
                                onClick={handleAddCandidateClick}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </div>

                </div>


                <div style={{ margin: '16px 0' }} />



                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.textBold} >Select</TableCell>
                                <TableCell style={styles.textBold}>Name</TableCell>
                                <TableCell style={styles.textBold}>Mobile</TableCell>
                                <TableCell style={styles.textBold}>Last Job Company</TableCell>
                                <TableCell style={styles.textBold}>Last Job Title</TableCell>
                                <TableCell style={styles.textBold}>Location</TableCell>
                                <TableCell style={styles.textBold}>Job Location</TableCell>
                                <TableCell style={styles.textBold}>Last Course Name</TableCell>
                                <TableCell style={styles.textBold}>Pref Role</TableCell>
                                <TableCell style={styles.textBold}>Share Link</TableCell>
                                <TableCell style={styles.textBold}>Created At</TableCell>
                                <TableCell style={styles.textBold}>Updated At</TableCell>
                            </TableRow>
                        </TableHead>

                        {candidates?.length > 0 ? (
                            <TableBody>
                                {candidates.map((candidate, index) => (
                                    <TableRow onContextMenu={(event) => handleContextMenu(event, candidate, index)} key={candidate.candidateId}
                                        onClick={() => setSelectedRow(index)}
                                        onDoubleClick={() => handleViewMoreClick(candidate.candidateId)}
                                        style={{
                                            backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                                        }}
                                    >
                                        <TableCell>
                                            <CheckboxComponent
                                                label=""
                                                field={{
                                                    name: candidate.candidateId,
                                                    value: selectedSuggestCandidates.some((c) => c.candidateId === candidate.candidateId),
                                                }}
                                                form={{
                                                    setFieldValue: () => handleSuggestedCandidateChange(candidate),
                                                }}
                                                disabled={candidate.nonAppUser}
                                            />
                                        </TableCell>

                                        <TableCell>{candidate.candidateName}</TableCell>
                                        <TableCell>{candidate.mobileNumber}</TableCell>
                                        <TableCell>{candidate.lastJobCompany}</TableCell>
                                        <TableCell>{candidate.lastJobTitle}</TableCell>
                                        <TableCell>{candidate.location}</TableCell>
                                        <TableCell>{candidate.jobLocation}</TableCell>
                                        <TableCell>{candidate.lastCourseName}</TableCell>
                                        <TableCell>  {candidate.preferredRole1}
                                            {candidate.preferredRole2 && candidate.preferredRole3 ? ', ' : ' '}
                                            {candidate.preferredRole2}
                                            {candidate.preferredRole2 && candidate.preferredRole3 ? ', ' : ' '}
                                            {candidate.preferredRole3}
                                        </TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={(e) => { e.preventDefault(); handleCopyClick(candidate.shareLink); }}>
                                                <ContentCopyIcon />
                                            </Button>
                                        </TableCell>
                                        <TableCell>{candidate.createDate && moment(candidate.createDate).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell>{candidate.updateDate && moment(candidate.updateDate).format("DD/MM/YYYY")}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={10}>
                                        <h1 style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>No Candidates</h1>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}

                    </Table>
                </TableContainer>

                <Menu id={MENU_ID}>
                    <Item id="upload_documents" onClick={(event, props) => handleItemClick({ event, props, id: 'upload_document' })}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <DocumentScannerIcon />
                            <span style={{ marginLeft: '5px' }}>Upload Documents / Images</span>
                        </div>
                    </Item>
                    <Separator />
                    <Item id="view_more" onClick={(event, props) => handleItemClick({ event, props, id: 'view_more' })}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <VisibilityIcon />
                            <span style={{ marginLeft: '5px' }}>View More</span>
                        </div>
                    </Item>
                    <Item id="view_jobs" onClick={(event) => handleItemClick({ event, id: 'job_applications' })}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <PersonIcon />
                            <span style={{ marginLeft: '5px' }}>Job Applications</span>
                        </div>
                    </Item>
                    <Separator />
                    <Item id="view_gigs" onClick={(event) => handleItemClick({ event, id: 'edit' })}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <EditIcon />
                            <span style={{ marginLeft: '5px' }}>Edit</span>
                        </div>
                    </Item>
                    {superUser && <Item id="add_job" onClick={(event) => handleItemClick({ event, id: 'delete' })}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
                            <DeleteIcon />
                            <span style={{ marginLeft: '5px' }}>Delete</span>
                        </div>
                    </Item>}

                </Menu>

                <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
                    <Pagination
                        page={queryParams.pageNumber + 1}
                        onChange={handlePageChange}
                        color="primary"
                        count={candidatesResponse?.metadata?.totalPages}
                        size="small"
                    />

                </Grid>

                {isNonAppUsersModalOpen && (
                    <NonAppUsersModal
                        isOpen={isNonAppUsersModalOpen}
                        onClose={handleCloseNonAppUsersModal}
                        nonAppUserFile={nonAppUserFile}
                        candidateQueryParams={queryParams}
                    />
                )}

                {isCandidateModalOpen && candidateDataById !== null && (
                    <CandidateProfile
                        isOpen={isCandidateModalOpen}
                        onClose={handleCloseCandidateModal}
                        candidateData={candidateDataById}
                    />
                )}

                {isJobApplicationModalOpen && candidateDataById !== null && (
                    <CandidateJobApplicationModal
                        candidateId={candidateId}
                        isOpen={isJobApplicationModalOpen}
                        onClose={handleCloseJobApplicationModal}
                        jobApplications={jobApplications}
                    />
                )}
            </Grid>

            {/* Right side: Add Candidate Button */}
            {isAddCandidateVisible && candidateDetail ? (
                <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
                    <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleAddCandidateClick}>
                        Back
                    </Button>
                    <div style={{ margin: "16px 0px", fontWeight: 'bold', color: 'green' }}>Edit Form For {candidateDataById?.name} {candidateDataById?.nonAppUser && "[Non App User]"}</div>
                    <CandidateForm onClose={handleCloseCandidateForm} candidateDetail={candidateDataById ? candidateDataById : candidateDetail} resumeParsedData={candidateDataById?.nonAppUser} />
                </Grid>
            ) : (
                isAddCandidateVisible && (
                    <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
                        <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleAddCandidateClick} style={{ marginBottom: '16px' }}>
                            Back
                        </Button>
                        <CandidateForm onClose={handleCloseCandidateForm} candidateQueryParams={queryParams} />
                    </Grid>
                )
            )}

            {isCandidateFiltersOpen &&
                <Grid size={{ xs: 12, md: 3 }} style={{ paddingTop: '8px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
                    <CandidateFilters
                        onClose={handleCloseCandidateFilters}
                        filtersData={candidateFilters}
                    />
                </Grid>

            }


            {showDeleteDialog === true && (
                <ConfirmationDialog
                    open={true}
                    onClose={handleConfirmationDialogClose}
                    onConfirm={handleConfirmationDialog}
                    message="Do you want to Delete?"
                />
            )}

            {
                isDocumentUploadModal === true && (
                    <DocumentUploadModal documentTypes={constants.candidateDocumentTypes} userId={candidateDetail.candidateId} open={isDocumentUploadModal} onClose={handleCloseUploadDocumentModal} />
                )
            }
        </Grid>
    );
};

const styles = {
    textBold: {
        fontWeight: 'bold'
    }
}

export default ViewCandidates;
