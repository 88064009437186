import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextFieldComponent from '../components/TextFieldComponent';
import ButtonComponent from '../components/ButtonComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { generateOTP, verifyOTP } from '../redux/actions/userActions';
import './css/Login.css';
import { Button } from '@mui/material';

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits')
    .required('Mobile Number is required'),
  otp: Yup.string()
    .matches(/^\d{4}$/, 'OTP must be exactly 4 numbers')
    .required('OTP is required'),
});

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);

  const formik = useFormik({
    initialValues: {
      mobileNumber: '',
      otp: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (isOtpSent) {
        // Call your backend API to validate OTP number
        try {
          const payload = {
            mobileNumber: values.mobileNumber,
            otp: values.otp,
          }
          await dispatch(verifyOTP(payload, navigate));
        } catch (error) {
          setIsMobileNumberValid(true);
          setIsOtpSent(false); // Reset OTP status on API call error
        }
      }
    }
  });

  const handleMobileNumberChange = async (e) => {
    if (e.target.value.length === 10) {
      // Call your backend API to validate mobile number
      try {
        const payload = {
          mobileNumber: e.target.value
        };
        await dispatch(generateOTP(payload));
        setIsOtpSent(true); // Set the flag indicating that OTP has been sent
      } catch (error) {
        setIsMobileNumberValid(true);
        setIsOtpSent(false); // Reset OTP status on API call error
      }
    } else {
      setIsMobileNumberValid(true);
      setIsOtpSent(false);
    }
  };


  return (
    <Container component="main" maxWidth="xs" fixed>
      <CssBaseline />
      <div className='login-container'>
        <Paper elevation={3} className="login-paper">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={process.env.PUBLIC_URL + '/rozgaar_logo.png'}
              style={{ maxHeight: '50px', width: 'auto', backgroundColor: '#EB1934' }}
              alt=''
            />
          </div>
          <div className="login-center-text">
            <Typography variant="h5" style={{ fontWeight: 'bold'}}>Sign In</Typography>
          </div>
          <form onSubmit={formik.handleSubmit} className="login-form">
            <TextFieldComponent
              label="Mobile Number"
              type="tel"
              inputmode="numeric"
              pattern="[0-9\s]{13,19}"
              field={{
                ...formik.getFieldProps('mobileNumber'),
                onBlur: (e) => {
                  if (e.target.value.length === 10) {
                    handleMobileNumberChange(e);
                  }
                },
              }}
              form={formik}
              fullWidth
              disabled={isOtpSent || !isMobileNumberValid}
              minLength={10}
              maxLength={10}
            />
            <Box mt={2} />

            {formik.values.mobileNumber.length === 10 && (
              <div>
                <TextFieldComponent
                  label="OTP"
                  type="text"
                  field={formik.getFieldProps('otp')}
                  form={formik}
                  fullWidth
                  minLength={4}
                  maxLength={4}
                />
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!formik.isValid || formik.isSubmitting}
                  fullWidth
                  className="login-button"
                >
                  {formik.isSubmitting ? 'Signing In...' : 'Sign In'}
                </Button>
              </div>
            )}
          </form>
        </Paper>
      </div>
    </Container>
  );
};

export default LoginPage;
