
import showToast from '../../utils/toast';
import { addEmployerService, getEmployerService, getSingleEmployerService, editEmployerService, deleteEmployerService, exportEmployerDataService, getEmployersAuditService, getSpecificEmployersAuditService, getSpecificEmployersAuditRevisionService } from '../service/employerService';
import { startLoader, stopLoader } from './loaderActions';

export const ADD_EMPLOYER = 'ADD_EMPLOYER';
export const ADD_EMPLOYER_SUCCESS = 'ADD_EMPLOYER_SUCCESS';
export const ADD_EMPLOYER_FAILURE = 'ADD_EMPLOYER_FAILURE';

export const GET_EMPLOYERS = 'GET_EMPLOYERS';
export const GET_EMPLOYERS_SUCCESS = 'GET_EMPLOYERS_SUCCESS';
export const GET_EMPLOYERS_FAILURE = 'GET_EMPLOYERS_FAILURE';

export const SET_EMPLOYER_FILTERS = 'SET_EMPLOYER_FILTERS';

export const GET_EMPLOYERS_AUDIT = 'GET_EMPLOYERS_AUDIT';
export const GET_EMPLOYERS_AUDIT_SUCCESS = 'GET_EMPLOYERS_AUDIT_SUCCESS';
export const GET_EMPLOYERS_AUDIT_FAILURE = 'GET_EMPLOYERS_AUDIT_FAILURE';

export const GET_SPECIFIC_EMPLOYERS_AUDIT = 'GET_SPECIFIC_EMPLOYERS_AUDIT';
export const GET_SPECIFIC_EMPLOYERS_AUDIT_SUCCESS = 'GET_SPECIFIC_EMPLOYERS_AUDIT_SUCCESS';
export const GET_SPECIFIC_EMPLOYERS_AUDIT_FAILURE = 'GET_SPECIFIC_EMPLOYERS_AUDIT_FAILURE';
export const RESET_SPECIFIC_EMPLOYERS_AUDIT = 'RESET_SPECIFIC_EMPLOYERS_AUDIT';

export const GET_SINGLE_EMPLOYER = 'GET_SINGLE_EMPLOYER';
export const GET_SINGLE_EMPLOYER_SUCCESS = 'GET_SINGLE_EMPLOYER_SUCCESS';
export const GET_SINGLE_EMPLOYER_FAILURE = 'GET_SINGLE_EMPLOYER_FAILURE';

export const EDIT_EMPLOYER = 'EDIT_EMPLOYER';
export const EDIT_EMPLOYER_SUCCESS = 'EDIT_EMPLOYER_SUCCESS';
export const EDIT_EMPLOYER_FAILURE = 'EDIT_EMPLOYER_FAILURE';

export const RESET_ADDED_EMPLOYER_FLAG = 'RESET_ADDED_EMPLOYER_FLAG'
export const RESET_EDITED_EMPLOYER_FLAG = 'RESET_EDITED_EMPLOYER_FLAG'

export const DELETE_EMPLOYER = 'DELETE_EMPLOYER';
export const DELETE_EMPLOYER_SUCCESS = 'DELETE_EMPLOYER_SUCCESS';
export const DELETE_EMPLOYER_FAILURE = 'DELETE_EMPLOYER_FAILURE';

export const EXPORT_EMPLOYER_DATA = 'EXPORT_EMPLOYER_DATA';
export const EXPORT_EMPLOYER_DATA_SUCCESS = 'EXPORT_EMPLOYER_DATA_SUCCESS';
export const EXPORT_EMPLOYER_DATA_FAILURE = 'EXPORT_EMPLOYER_DATA_FAILURE';


export const addEmployer = () => ({
  type: ADD_EMPLOYER,
});

export const addEmployerSuccess = (response) => ({
  type: ADD_EMPLOYER_SUCCESS,
  payload: response,
});

export const addEmployerFailure = (error) => ({
  type: ADD_EMPLOYER_FAILURE,
  payload: error,
});

export const editEmployer = () => ({
  type: EDIT_EMPLOYER,
});

export const editEmployerSuccess = (response) => ({
  type: EDIT_EMPLOYER_SUCCESS,
  payload: response,
});

export const editEmployerFailure = (error) => ({
  type: EDIT_EMPLOYER_FAILURE,
  payload: error,
});

export const getEmployees = () => ({
  type: GET_EMPLOYERS,
});

export const getEmployeesSuccess = (response) => ({
  type: GET_EMPLOYERS_SUCCESS,
  payload: response,
});

export const getEmployeesFailure = (error) => ({
  type: GET_EMPLOYERS_FAILURE,
  payload: error,
});

export const setEmployerFilters = (response) => ({
  type: SET_EMPLOYER_FILTERS,
  payload: response,
});

export const getSpecificEmployersAudit = () => ({
  type: GET_SPECIFIC_EMPLOYERS_AUDIT,
});

export const getSpecificEmployersAuditSuccess = (response) => ({
  type: GET_SPECIFIC_EMPLOYERS_AUDIT_SUCCESS,
  payload: response,
});

export const getSpecificEmployersAuditFailure = (error) => ({
  type: GET_SPECIFIC_EMPLOYERS_AUDIT_FAILURE,
  payload: error,
});

export const resetSpecificEmployersAudit = () => ({
  type: RESET_SPECIFIC_EMPLOYERS_AUDIT,
});

export const getEmployersAudit = () => ({
  type: GET_EMPLOYERS_AUDIT,
});

export const getEmployersAuditSuccess = (response) => ({
  type: GET_EMPLOYERS_AUDIT_SUCCESS,
  payload: response,
});

export const getEmployersAuditFailure = (error) => ({
  type: GET_EMPLOYERS_AUDIT_FAILURE,
  payload: error,
});

export const addEmployers = () => ({
  type: ADD_EMPLOYER,
});

export const addEmployersSuccess = (response) => ({
  type: ADD_EMPLOYER_SUCCESS,
  payload: response,
});

export const addEmployersFailure = (error) => ({
  type: ADD_EMPLOYER_FAILURE,
  payload: error,
});


export const getSingleEmployer = () => ({
  type: GET_SINGLE_EMPLOYER,
});

export const getSingleEmployerSuccess = (response) => ({
  type: GET_SINGLE_EMPLOYER_SUCCESS,
  payload: response,
});

export const getSingleEmployerFailure = (error) => ({
  type: GET_SINGLE_EMPLOYER_FAILURE,
  payload: error,
});

export const deleteEmployer = () => ({
  type: DELETE_EMPLOYER,
});

export const deleteEmployerSuccess = (response) => ({
  type: DELETE_EMPLOYER_SUCCESS,
  payload: response,
});

export const deleteEmployerFailure = (error) => ({
  type: DELETE_EMPLOYER_FAILURE,
  payload: error,
});


export const resetEmployerAdded = () => ({
  type: RESET_ADDED_EMPLOYER_FLAG,
})

export const resetEmployerEdited = () => ({
  type: RESET_EDITED_EMPLOYER_FLAG,
})

export const exportEmployerData = () => ({
  type: EXPORT_EMPLOYER_DATA,
});

export const exportEmployerDataSuccess = () => ({
  type: EXPORT_EMPLOYER_DATA_SUCCESS,
});

export const exportEmployerDataFailure = (error) => ({
  type: EXPORT_EMPLOYER_DATA_FAILURE,
  payload: error,
});

export const getEmployeesList = (payload) => async (dispatch) => {
  dispatch(getEmployees());
  dispatch(startLoader());

  try {
    const response = await getEmployerService(payload);

    if (response.success) {
      dispatch(getEmployeesSuccess(response));
    } else {
      dispatch(getEmployeesFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getEmployeesFailure('Error getting employee list'));
    showToast('Error getting employee list', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getEmployersAuditList = (payload) => async (dispatch) => {
  dispatch(getEmployersAudit());
  dispatch(startLoader());

  try {
    const response = await getEmployersAuditService(payload);

    if (response.success) {
      dispatch(getEmployersAuditSuccess(response));
    } else {
      dispatch(getEmployersAuditFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getEmployersAuditFailure('Error getting employer audit list'));
    showToast('Error getting employer activity logs', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getSpecificEmployersAuditList = (payload) => async (dispatch) => {
  dispatch(getSpecificEmployersAudit());
  dispatch(startLoader());

  try {
    const response = await getSpecificEmployersAuditService(payload);

    if (response.success) {
      dispatch(getSpecificEmployersAuditSuccess(response));
    } else {
      dispatch(getSpecificEmployersAuditFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getSpecificEmployersAuditFailure('Error getting specific employer audit list'));
    showToast('Error getting employer activity logs', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

// TODO: as a temporary workaround used employer specific state for all types of audit details
export const getSpecificEmployersAuditRevisionList = (payload) => async (dispatch) => {
  dispatch(getSpecificEmployersAudit());
  dispatch(startLoader());

  try {
    const response = await getSpecificEmployersAuditRevisionService(payload);

    if (response.success) {
      dispatch(getSpecificEmployersAuditSuccess(response));
    } else {
      dispatch(getSpecificEmployersAuditFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getSpecificEmployersAuditFailure('Error getting specific employer audit list'));
    showToast('Error getting activity log details', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getSingleEmployerData = (employeeId) => async (dispatch) => {
  dispatch(getSingleEmployer());
  dispatch(startLoader());

  try {
    const response = await getSingleEmployerService(employeeId);

    if (response.success) {
      dispatch(getSingleEmployerSuccess(response));
    } else {
      dispatch(getSingleEmployerFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getSingleEmployerFailure('Error getting single employee'));
    showToast('Error getting single employee', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const addEmployerData = (employeeData) => async (dispatch) => {
  dispatch(addEmployer());
  dispatch(startLoader());

  try {
    const response = await addEmployerService(employeeData);

    if (response.success) {
      dispatch(addEmployerSuccess(true));
      showToast(response.message, 'success');

    } else {
      dispatch(addEmployerFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(addEmployerFailure('Error adding employee'));
    showToast('Error adding employee', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const editEmployerData = (employerId, employeeData) => async (dispatch, getState) => {
  dispatch(editEmployer());
  dispatch(startLoader());
  try {
    const response = await editEmployerService(employerId, employeeData);
    if (response.success) {
      dispatch(editEmployerSuccess(true));

      const updatedEmployerData = response.data;

      const employerState = getState().employer || {};
      const updatedEmployerList = employerState.employer.data.map((existingEmployer) => {
        if (existingEmployer.employerId === updatedEmployerData.employerId) {
          // Update the existing employer object with properties from updatedEmployerData
          return {
            ...existingEmployer,
            ...updatedEmployerData,
          };
        }
        // Return the unmodified employer object if it doesn't match the ID
        return existingEmployer;
      });


      const updatedPayload = {
        success: true,
        message: "Employer list fetched",
        msgId: "SUCCESS",
        data: updatedEmployerList,
        metadata: employerState.metadata,
      };

      dispatch({
        type: GET_EMPLOYERS_SUCCESS,
        payload: updatedPayload,
      });

      showToast(response.message, 'success');
    } else {
      dispatch(editEmployerFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(addEmployerFailure('Error editing employee'));
    showToast('Error editing employee', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const deleteEmployerData = (employerId) => async (dispatch, getState) => {
  dispatch(deleteEmployer());
  dispatch(startLoader());
  try {
    const response = await deleteEmployerService(employerId);
    if (response.success) {
      dispatch(deleteEmployerSuccess(true));

      const employerState = getState().employer || {};

      const updatedEmployerList = employerState?.employer?.data?.filter((existingEmployer) => {
        return existingEmployer.employerId !== employerId;
      });

      const updatedPayload = {
        success: true,
        message: "Employer list fetched",
        msgId: "SUCCESS",
        data: updatedEmployerList,
        metadata: employerState.metadata,
      };

      dispatch({
        type: GET_EMPLOYERS_SUCCESS,
        payload: updatedPayload,
      });

      showToast(response.message, 'success');
    } else {
      dispatch(editEmployerFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(addEmployerFailure('Error deleting employee'));
    showToast('Error deleting employee', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const exportEmployerDataInExcel = (startDate, endDate) => async (dispatch) => {
  dispatch(exportEmployerData());
  dispatch(startLoader());
  try {
    const response = await exportEmployerDataService(startDate, endDate);
    if (response.success) {
      dispatch(exportEmployerDataSuccess());
      showToast(response.message, 'success');
    } else {
      dispatch(exportEmployerDataFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(exportEmployerDataFailure('Error exporting employer data'));
    showToast('Error exporting employer data', 'error');
  } finally {
    dispatch(stopLoader());
  }
};


export const clearAddedEmployerFlag = () => async (dispatch) => {
  dispatch(resetEmployerAdded(null));
}

export const clearEditedEmployerFlag = () => async (dispatch) => {
  dispatch(resetEmployerEdited(null));
}