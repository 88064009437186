import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import CreatableSelect from "react-select/creatable";

const CreatableDropdownComponent = ({
  label,
  options,
  field,
  form,
  placeholder,
  isDisabled,
  fullWidth,
  required,
  style,
  selectStyle,
  onChange,
}) => {
  const [newOptions, setNewOptions] = useState(options);

  useEffect(() => {
    setNewOptions(options);
  }, [options]);

  const handleChange = (selectedOption) => {
    if (form) {
      form.setFieldValue(field.name, selectedOption);
    } else if (onChange) {
      onChange(selectedOption);
    }
  };

  const handleCreate = (inputValue) => {
    const newOption = {
      name: inputValue,
      id: inputValue,
    };
    const updatedOptions = [...newOptions, newOption];
    setNewOptions(updatedOptions);
    handleChange(newOption);
  };

  const getOptionLabel = (option) => option.label || option.name || '';
  const value = field?.value && newOptions?.find(option => option.name === field?.value?.name);

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      disabled={isDisabled}
      error={form?.touched[field.name] && Boolean(form?.errors[field.name])}
      style={style}
    >
      <label>{label}</label>
      <CreatableSelect
        options={newOptions}
        value={value}
        onChange={handleChange}
        onCreateOption={handleCreate}
        placeholder={placeholder}
        isDisabled={isDisabled}
        menuPosition={'fixed'}
        menuPortalTarget={document.body}
        minMenuHeight={24}
        getOptionLabel={getOptionLabel}
        getOptionValue={(option) => option?.id?.toString() || ""}
        menuPlacement={'bottom'}
        menuShouldScrollIntoView={false}
        styles={selectStyle}
      />
      {form?.touched[field.name] && form?.errors[field.name] && (
        <FormHelperText>{form?.errors[field.name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CreatableDropdownComponent;
