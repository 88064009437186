// AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidenav from './Sidenav';
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../pages/Dashboard';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* If user tries to access /login or /app when already logged in, redirect to /app */}
        <Route
          path="/login"
          element={<Login />}
        />
        {/* Main route */}
        <Route
          path="/app/*"
          element={
            <PrivateRoute>
              <Sidenav />
            </PrivateRoute>
          }
        />
        {/* Dashboard route with private access */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
       
        <Route
          path="/"
          element={<Navigate to="/app" />}
        />

      </Routes>
    </Router>
  );
};

export default AppRouter;
