import { useFormik } from "formik";
import * as Yup from 'yup';
import { formatYYYYMMDDDate } from '../helpers/formattedDateTime';
import TextFieldComponent from "./TextFieldComponent";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { downloadCandidateData } from "../redux/actions/candidateActions";
import constants from '../helpers/constants.json';
import { exportEmployerDataInExcel } from "../redux/actions/employerActions";
import { downloadJobPosts } from "../redux/actions/jobActions";
import { useState } from "react";

const ExportComponent = ({ screenName, style }) => {
    const dispatch = useDispatch();
    const [isExportClicked, setIsExportClicked] = useState(false);

    const validationSchema = Yup.object().shape({
        startDate: Yup.date().required('Start Date is required'),
        endDate: Yup.date().required('End Date is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            startDate: '',
            endDate: '',
        },
        onSubmit: async (values) => {
            const payload = {
                startDate: formatYYYYMMDDDate(values.startDate),
                endDate: formatYYYYMMDDDate(values.endDate),
            };
            if (screenName == constants.screenName.CANDIDATE) {
                dispatch(downloadCandidateData(payload.startDate, payload.endDate))
            } else if (screenName == constants.screenName.EMPLOYER) {
                dispatch(exportEmployerDataInExcel(payload.startDate, payload.endDate));
            } else if (screenName == constants.screenName.JOB_POST) {
                dispatch(downloadJobPosts(payload.startDate, payload.endDate));
            }
        },
    });

    const handleExportClick = () => {
        setIsExportClicked(true);
    }

    return (
        <div style={style}>
            {isExportClicked ?
                <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: '110px' }}>
                        <TextFieldComponent
                            label="Start Date"
                            type="date"
                            field={{
                                ...formik.getFieldProps("startDate"),
                                onChange: formik.handleChange("startDate"),
                            }}
                            maxDate={new Date()}
                            form={formik}
                            fullWidth
                        />
                    </div>

                    <div style={{ width: '110px', marginLeft: '10px' }}>
                        <TextFieldComponent
                            label="End Date"
                            type="date"
                            field={{
                                ...formik.getFieldProps("endDate"),
                                onChange: formik.handleChange("endDate"),
                            }}
                            maxDate={new Date()}
                            form={formik}
                            fullWidth
                        />
                    </div>

                    <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', alignSelf: 'center', whiteSpace: 'nowrap' }}>
                        <Button variant="contained" disabled={!formik.isValid || formik.isSubmitting} className="blackButton" type="submit">
                            Download Excel
                        </Button>
                    </div>
                </form>
                :
                <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', whiteSpace: 'nowrap' }}>
                    <Button variant="contained" className="blackButton" onClick={handleExportClick}>
                        Export Excel
                    </Button>
                </div>
            }
        </div>
    )

}

export default ExportComponent;