// ReferralDetailModal.js
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Close as CloseIcon, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ReferralDetailModal = ({ isOpen, onClose, referralDetails }) => {

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(4px)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      left: '5%',
      top: '10%',
      zIndex: 1001,
      width: '94%',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      overflowY: 'auto',
    },
  }
  const [expanded, setExpanded] = useState(false);

  const handleCloseReferralModal = () => {
    onClose();
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Referral Details Modal"
      style={customStyles}
    >
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Referral Details</h2>
          <IconButton onClick={handleCloseReferralModal} style={{ marginRight: '-12px', marginTop: '-12px' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold}>Name</TableCell>
                <TableCell style={styles.textBold}>Referral Code</TableCell>
                <TableCell style={styles.textBold}>Referral Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{referralDetails.name}</TableCell>
                <TableCell>{referralDetails.referralCode}</TableCell>
                <TableCell>{referralDetails.referralCount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {referralDetails.users && referralDetails.users.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={3}>
                  <IconButton size="small" onClick={toggleExpand}>
                    {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                  <strong>Referred Users</strong>
                </TableCell>
              </TableRow>
              {expanded && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Mobile Number</TableCell>
                      <TableCell>User Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {referralDetails.users.map(user => (
                      <TableRow key={user.userId}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.mobileNumber}</TableCell>
                        <TableCell>{user.candidate ? 'Candidate' : 'Employer'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </>
          )}
        </TableContainer>
      </div>
    </ReactModal>
  );
};

export default ReferralDetailModal;

const styles = {
  textBold: {
      fontWeight: 'bold'
  }
}