// gigsReducer.js
import {
    GET_GIGS,
    GET_GIGS_SUCCESS,
    GET_GIGS_FAILURE,
    GET_GIG_APPLICANTS,
    GET_GIG_APPLICANTS_SUCCESS,
    GET_GIG_APPLICANTS_FAILURE,
    CREATE_GIG,
    CREATE_GIG_SUCCESS,
    CREATE_GIG_FAILURE,
    EDIT_GIG,
    EDIT_GIG_SUCCESS,
    EDIT_GIG_FAILURE,
    GET_PENDING_GIGS,
    GET_PENDING_GIGS_SUCCESS,
    GET_PENDING_GIGS_FAILURE,
    UPDATE_GIG_APPLICATION,
    UPDATE_GIG_APPLICATION_SUCCESS,
    UPDATE_GIG_APPLICATION_FAILURE,
    GET_GIG_DETAILS,
    GET_GIG_DETAILS_SUCCESS,
    GET_GIG_DETAILS_FAILURE,
    RESET_ADDED_GIG_FLAG,
    RESET_EDITED_GIG_FLAG,
    DELETE_GIG,
    DELETE_GIG_SUCCESS,
    DELETE_GIG_FAILURE,
    EXPORT_GIG_POSTS_REQUEST,
    EXPORT_GIG_POSTS_FAILURE
  } from '../actions/gigsActions';
  
  const initialState = {
    gigs: null,
    gigDetailsById: null,
    gigApplicants: null,
    newGigCreated: null,
    editGigSuccess: null,
    pendingGigs: null,
    updateGigApplicationSuccess: null,
    deleteGigSuccess: null
  };
  
  const gigsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_GIGS:
        return {
          ...state,
        };
  
      case GET_GIGS_SUCCESS:
        return {
          ...state,
          gigs: action.payload,
        };
  
      case GET_GIGS_FAILURE:
        return {
          ...state,
        };

        case GET_GIG_DETAILS:
          return {
            ...state,
            gigDetailsById: null
         };
    
        case GET_GIG_DETAILS_SUCCESS:
          return {
            ...state,
            gigDetailsById: action.payload,
        };
    
        case GET_GIG_DETAILS_FAILURE:
          return {
            ...state,
            gigDetailsById: null
          };
  
      case GET_GIG_APPLICANTS:
        return {
          ...state,
          gigApplicants: null,

        };
  
      case GET_GIG_APPLICANTS_SUCCESS:
        return {
          ...state,
          gigApplicants: action.payload,
        };
  
      case GET_GIG_APPLICANTS_FAILURE:
        return {
          ...state,
          gigApplicants: null,
        };
  
      case CREATE_GIG:
        return {
          ...state,
          newGigCreated: null,
        };
  
      case CREATE_GIG_SUCCESS:
        return {
          ...state,
          newGigCreated: true,
        };
  
      case CREATE_GIG_FAILURE:
        return {
          ...state,
          newGigCreated: null,
        };
  
      case EDIT_GIG:
        return {
          ...state,
          editGigSuccess: null,
        };
  
      case EDIT_GIG_SUCCESS:
        return {
          ...state,
          editGigSuccess: true,
        };
  
      case EDIT_GIG_FAILURE:
        return {
          ...state,
          editGigSuccess: null,
        };

        case DELETE_GIG:
        return {
          ...state,
        };
  
      case DELETE_GIG_SUCCESS:
        return {
          ...state,
          deleteGigSuccess: true,
        };
  
      case DELETE_GIG_FAILURE:
        return {
          ...state,
        };
  
      case GET_PENDING_GIGS:
        return {
          ...state,
          pendingGigs: null,
        };
  
      case GET_PENDING_GIGS_SUCCESS:
        return {
          ...state,
          pendingGigs: action.payload,
        };
  
      case GET_PENDING_GIGS_FAILURE:
        return {
          ...state,
          pendingGigs: null,
        };
  
      case UPDATE_GIG_APPLICATION:
        return {
          ...state,
          updateGigApplicationSuccess: null,
        };
  
      case UPDATE_GIG_APPLICATION_SUCCESS:
        return {
          ...state,
          updateGigApplicationSuccess: true,
        };
  
      case UPDATE_GIG_APPLICATION_FAILURE:
        return {
          ...state,
          updateGigApplicationSuccess: null
        };

        case RESET_ADDED_GIG_FLAG:
      return {
        ...state,
        newGigCreated: null
      };
      
      case RESET_EDITED_GIG_FLAG:
      return {
        ...state,
        editGigSuccess: null
      };

      case EXPORT_GIG_POSTS_REQUEST:
        return {
          ...state,
        };
  
        case EXPORT_GIG_POSTS_FAILURE:
        return {
          ...state,
        };

      default:
        return state;
    }
  };
  
  export default gigsReducer;
  