import showToast from '../../utils/toast';
import { getCandidatesService, getSingleCandidateService, getCandidateJobApplicationsService, createCandidateService, editCandidateService, deleteCandidateService, deleteSuggestedCandidateProfileService, createProfileSuggestionService, exportCandidateDataService, getCandidatesAuditService, getSpecificCandidatesAuditService } from '../service/candidateService';
import { EDIT_EMPLOYER } from './employerActions';
import { GET_SUGGESTED_CANDIDATES_SUCCESS } from './jobActions';
import { startLoader, stopLoader } from './loaderActions';

export const GET_CANDIDATES = 'GET_CANDIDATES';
export const GET_CANDIDATES_SUCCESS = 'GET_CANDIDATES_SUCCESS';
export const GET_CANDIDATES_FAILURE = 'GET_CANDIDATES_FAILURE';

export const SET_CANDIDATE_FILTERS = 'SET_CANDIDATE_FILTERS';

export const GET_CANDIDATES_AUDIT = 'GET_CANDIDATES_AUDIT';
export const GET_CANDIDATES_AUDIT_SUCCESS = 'GET_CANDIDATES_AUDIT_SUCCESS';
export const GET_CANDIDATES_AUDIT_FAILURE = 'GET_CANDIDATES_AUDIT_FAILURE';

export const GET_SPECIFIC_CANDIDATES_AUDIT = 'GET_SPECIFIC_CANDIDATES_AUDIT';
export const GET_SPECIFIC_CANDIDATES_AUDIT_SUCCESS = 'GET_SPECIFIC_CANDIDATES_AUDIT_SUCCESS';
export const GET_SPECIFIC_CANDIDATES_AUDIT_FAILURE = 'GET_SPECIFIC_CANDIDATES_AUDIT_FAILURE';
export const RESET_SPECIFIC_CANDIDATES_AUDIT = 'RESET_SPECIFIC_CANDIDATES_AUDIT';

export const GET_SINGLE_CANDIDATE = 'GET_SINGLE_CANDIDATE';
export const GET_SINGLE_CANDIDATE_SUCCESS = 'GET_SINGLE_CANDIDATE_SUCCESS';
export const GET_SINGLE_CANDIDATE_FAILURE = 'GET_SINGLE_CANDIDATE_FAILURE';

export const GET_CANDIDATE_JOB_APPLICATIONS = 'GET_CANDIDATE_JOB_APPLICATIONS';
export const GET_CANDIDATE_JOB_APPLICATIONS_SUCCESS = 'GET_CANDIDATE_JOB_APPLICATIONS_SUCCESS';
export const GET_CANDIDATE_JOB_APPLICATIONS_FAILURE = 'GET_CANDIDATE_JOB_APPLICATIONS_FAILURE';

export const ADD_CANDIDATE = 'ADD_CANDIDATE';
export const ADD_CANDIDATE_SUCCESS = 'ADD_CANDIDATE_SUCCESS';
export const ADD_CANDIDATE_FAILURE = 'ADD_CANDIDATE_FAILURE';

export const EDIT_CANDIDATE = 'EDIT_CANDIDATE';
export const EDIT_CANDIDATE_SUCCESS = 'EDIT_CANDIDATE_SUCCESS';
export const EDIT_CANDIDATE_FAILURE = 'EDIT_CANDIDATE_FAILURE';

export const DELETE_CANDIDATE = 'DELETE_CANDIDATE';
export const DELETE_CANDIDATE_SUCCESS = 'DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_FAILURE = 'DELETE_CANDIDATE_FAILURE';

export const RESET_ADDED_CANDIDATE_FLAG = 'RESET_ADDED_CANDIDATE_FLAG'

export const RESET_EDITED_CANDIDATE_FLAG = 'RESET_EDITED_CANDIDATE_FLAG'

export const CREATE_PROFILE_SUGGESTION_REQUEST = 'CREATE_PROFILE_SUGGESTION_REQUEST';
export const CREATE_PROFILE_SUGGESTION_SUCCESS = 'CREATE_PROFILE_SUGGESTION_SUCCESS';
export const CREATE_PROFILE_SUGGESTION_FAILURE = 'CREATE_PROFILE_SUGGESTION_FAILURE';

export const DELETE_SUGGESTED_CANDIDATE_PROFILE_REQUEST = 'DELETE_SUGGESTED_CANDIDATE_PROFILE_REQUEST';
export const DELETE_SUGGESTED_CANDIDATE_PROFILE_SUCCESS = 'DELETE_SUGGESTED_CANDIDATE_PROFILE_SUCCESS';
export const DELETE_SUGGESTED_CANDIDATE_PROFILE_FAILURE = 'DELETE_SUGGESTED_CANDIDATE_PROFILE_FAILURE';

export const SELECT_CANDIDATES_FOR_JOB_SUGGESTION = 'SELECT_CANDIDATES_FOR_JOB_SUGGESTION';

export const REMOVE_CANDIDATES_FOR_JOB_SUGGESTION = 'REMOVE_CANDIDATES_FOR_JOB_SUGGESTION';

export const DOWNLOAD_CANDIDATE_DATA_REQUEST = 'DOWNLOAD_CANDIDATE_DATA_REQUEST';
export const DOWNLOAD_CANDIDATE_DATA_SUCCESS = 'DOWNLOAD_CANDIDATE_DATA_SUCCESS';
export const DOWNLOAD_CANDIDATE_DATA_FAILURE = 'DOWNLOAD_CANDIDATE_DATA_FAILURE';


export const addCandidate = () => ({
  type: ADD_CANDIDATE,
});

export const addCandidateSuccess = (response) => ({
  type: ADD_CANDIDATE_SUCCESS,
  payload: response,
});

export const addCandidateFailure = (error) => ({
  type: ADD_CANDIDATE_FAILURE,
  payload: error,
});

export const editCandidate = () => ({
  type: EDIT_EMPLOYER,
});

export const editCandidateSuccess = (response) => ({
  type: EDIT_CANDIDATE_SUCCESS,
  payload: response,
});

export const editCandidateFailure = (error) => ({
  type: EDIT_CANDIDATE_FAILURE,
  payload: error,
});

export const deleteCandidate = () => ({
  type: DELETE_CANDIDATE,
});

export const deleteCandidateSuccess = (response) => ({
  type: DELETE_CANDIDATE_SUCCESS,
  payload: response,
});

export const deleteCandidateFailure = (error) => ({
  type: DELETE_CANDIDATE_FAILURE,
  payload: error,
});

export const getCandidatesAudit = () => ({
  type: GET_CANDIDATES_AUDIT,
});

export const getCandidatesAuditSuccess = (response) => ({
  type: GET_CANDIDATES_AUDIT_SUCCESS,
  payload: response,
});

export const getCandidatesAuditFailure = (error) => ({
  type: GET_CANDIDATES_AUDIT_FAILURE,
  payload: error,
});

export const getSpecificCandidatesAudit = () => ({
  type: GET_SPECIFIC_CANDIDATES_AUDIT,
});

export const getSpecificCandidatesAuditSuccess = (response) => ({
  type: GET_SPECIFIC_CANDIDATES_AUDIT_SUCCESS,
  payload: response,
});

export const getSpecificCandidatesAuditFailure = (error) => ({
  type: GET_SPECIFIC_CANDIDATES_AUDIT_FAILURE,
  payload: error,
});

export const resetSpecificCandidatesAudit = () => ({
  type: RESET_SPECIFIC_CANDIDATES_AUDIT,
});

export const getCandidates = () => ({
  type: GET_CANDIDATES,
});

export const getCandidatesSuccess = (response) => ({
  type: GET_CANDIDATES_SUCCESS,
  payload: response,
});

export const getCandidatesFailure = (error) => ({
  type: GET_CANDIDATES_FAILURE,
  payload: error,
});

export const setCandidateFilters = (response) => ({
  type: SET_CANDIDATE_FILTERS,
  payload: response,
});

export const getSingleCandidate = () => ({
  type: GET_SINGLE_CANDIDATE,
});

export const getSingleCandidateSuccess = (response) => ({
  type: GET_SINGLE_CANDIDATE_SUCCESS,
  payload: response,
});

export const getSingleCandidateFailure = (error) => ({
  type: GET_SINGLE_CANDIDATE_FAILURE,
  payload: error,
});

export const getCandidateJobApplications = () => ({
  type: GET_CANDIDATE_JOB_APPLICATIONS,
});

export const getCandidateJobApplicationsSuccess = (response) => ({
  type: GET_CANDIDATE_JOB_APPLICATIONS_SUCCESS,
  payload: response,
});

export const getCandidateJobApplicationsFailure = (error) => ({
  type: GET_CANDIDATE_JOB_APPLICATIONS_FAILURE,
  payload: error,
});

export const resetCandidateAdded = () => ({
  type: RESET_ADDED_CANDIDATE_FLAG,
})

export const resetCandidateEdited = () => ({
  type: RESET_EDITED_CANDIDATE_FLAG,
})

export const createProfileSuggestionRequest = () => ({
  type: CREATE_PROFILE_SUGGESTION_REQUEST
});

export const createProfileSuggestionSuccess = () => ({
  type: CREATE_PROFILE_SUGGESTION_SUCCESS
});

export const createProfileSuggestionFailure = (error) => ({
  type: CREATE_PROFILE_SUGGESTION_FAILURE,
  payload: error
});

export const deleteSuggestedCandidateProfileRequest = () => ({
  type: DELETE_SUGGESTED_CANDIDATE_PROFILE_REQUEST
});

export const deleteSuggestedCandidateProfileSuccess = () => ({
  type: DELETE_SUGGESTED_CANDIDATE_PROFILE_SUCCESS
});

export const deleteSuggestedCandidateProfileFailure = (error) => ({
  type: DELETE_SUGGESTED_CANDIDATE_PROFILE_FAILURE,
  payload: error
});

export const selectCandidatesForJobSuggestion = (candidates) => ({
  type: SELECT_CANDIDATES_FOR_JOB_SUGGESTION,
  payload: candidates
});

export const removeCandidatesForJobSuggestion = (candidateId) => ({
  type: REMOVE_CANDIDATES_FOR_JOB_SUGGESTION,
  payload: candidateId
});

export const downloadCandidateDataRequest = () => ({
  type: DOWNLOAD_CANDIDATE_DATA_REQUEST,
});

export const downloadCandidateDataSuccess = () => ({
  type: DOWNLOAD_CANDIDATE_DATA_SUCCESS,
});

export const downloadCandidateDataFailure = (error) => ({
  type: DOWNLOAD_CANDIDATE_DATA_FAILURE,
  payload: error,
});

export const getCandidatesList = (payload) => async (dispatch) => {
  dispatch(getCandidates());
  dispatch(startLoader());

  try {
    // Assuming you have a getCandidatesService function
    const response = await getCandidatesService(payload);

    if (response.success) {
      dispatch(getCandidatesSuccess(response));
    } else {
      dispatch(getCandidatesFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getCandidatesFailure('Error getting candidate list'));
    showToast('Error getting candidate list', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getCandidatesAuditList = (payload) => async (dispatch) => {
  dispatch(getCandidatesAudit());
  dispatch(startLoader());

  try {
    const response = await getCandidatesAuditService(payload);

    if (response.success) {
      dispatch(getCandidatesAuditSuccess(response));
    } else {
      dispatch(getCandidatesAuditFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getCandidatesAuditFailure('Error getting candidate list'));
    showToast('Error getting candidate activity logs', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getSpecificCandidatesAuditList = (payload) => async (dispatch) => {
  dispatch(getSpecificCandidatesAudit());
  dispatch(startLoader());

  try {
    const response = await getSpecificCandidatesAuditService(payload);

    if (response.success) {
      dispatch(getSpecificCandidatesAuditSuccess(response));
    } else {
      dispatch(getSpecificCandidatesAuditFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getSpecificCandidatesAuditFailure('Error getting candidate list'));
    showToast('Error getting candidate activity logs', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getSingleCandidateData = (candidateId) => async (dispatch) => {
  dispatch(getSingleCandidate());
  dispatch(startLoader());

  try {
    const response = await getSingleCandidateService(candidateId);

    if (response.success) {
      dispatch(getSingleCandidateSuccess(response));
    } else {
      dispatch(getSingleCandidateFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getSingleCandidateFailure('Error getting single candidate'));
    showToast('Error getting single candidate', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getCandidateJobApplicationsData = (candidateId, pageNumber = 0) => async (dispatch) => {
  dispatch(getCandidateJobApplications());
  dispatch(startLoader());

  try {
    const response = await getCandidateJobApplicationsService(candidateId, pageNumber);

    if (response.success && response.data.length) {
      dispatch(getCandidateJobApplicationsSuccess(response));
    } else {
      dispatch(getCandidateJobApplicationsFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getCandidateJobApplicationsFailure('Error getting candidate job applications'));
    showToast('Error getting candidate job applications', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const createNewCandidate = (candidate) => async (dispatch) => {
  dispatch(addCandidate());
  dispatch(startLoader());

  try {
    const response = await createCandidateService(candidate);
    if (response.success) {
      dispatch(addCandidateSuccess(response.data));
      showToast(response.message, 'success')
    } else {
      dispatch(addCandidateFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(addCandidateFailure('Error adding candidate'));
    showToast('Error adding candidate', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const editCandidateRecord = (candidateId, candidate) => async (dispatch, getState) => {
  dispatch(editCandidate());
  dispatch(startLoader());

  try {
    const response = await editCandidateService(candidateId, candidate);

    if (response.success) {

      const updatedCandidateData = response.data;
      const candidateState = getState().candidate || {};
      const updatedCandidateList = candidateState.candidates.data.map((existingCandidate) => {
        if (existingCandidate.candidateId === updatedCandidateData.candidateId) {
          // Update the existing existingCandidate object with properties from updatedExistingCandidate
          return {
            ...existingCandidate,
            ...updatedCandidateData,
          };
        }
        // Return the unmodified candidate object if it doesn't match the ID
        return existingCandidate;
      });

      const updatedPayload = {
        success: true,
        message: candidateState.message,
        msgId: candidateState.msgId,
        data: updatedCandidateList,
        metadata: candidateState.metadata,
      };


      dispatch({
        type: GET_CANDIDATES_SUCCESS,
        payload: updatedPayload,
      });

      dispatch(editCandidateSuccess(true));
      showToast(response.message, 'success')
    } else {
      dispatch(editCandidateFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(editCandidateFailure('Error editing candidate'));
    showToast('Error editing candidate', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const deleteCandidateRecord = (candidateId) => async (dispatch, getState) => {
  dispatch(deleteCandidate());
  dispatch(startLoader());

  try {
    const response = await deleteCandidateService(candidateId);

    if (response.success) {
      const candidateState = getState().candidate || {};
      const updatedCandidateList = candidateState?.candidates?.data?.filter((existingCandidate) => {
        return existingCandidate.candidateId !== candidateId;
      });

      const updatedPayload = {
        success: true,
        message: candidateState.message,
        msgId: candidateState.msgId,
        data: updatedCandidateList,
        metadata: candidateState.metadata,
      };


      dispatch({
        type: GET_CANDIDATES_SUCCESS,
        payload: updatedPayload,
      });

      dispatch(deleteCandidateSuccess(true));
      showToast(response.message, 'success')
    } else {
      dispatch(deleteCandidateFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(deleteCandidateFailure('Error deleting candidate'));
    showToast('Error deleting candidate', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const clearAddedCandidateFlag = () => async (dispatch) => {
  dispatch(resetCandidateAdded(null));
}

export const clearEditedCandidateFlag = () => async (dispatch) => {
  dispatch(resetCandidateEdited(null));
}

export const createProfileSuggestion = (payload) => async (dispatch) => {
  dispatch(createProfileSuggestionRequest());
  dispatch(startLoader())

  try {
    const response = await createProfileSuggestionService(payload);

    if (response.success) {
      dispatch(createProfileSuggestionSuccess());
      showToast(response.message, "success")
    } else {
      dispatch(createProfileSuggestionFailure(response.message));
      showToast(response.message, "error")
    }
    dispatch(stopLoader())
  } catch (error) {
    dispatch(createProfileSuggestionFailure("error in creating profile suggestion"));
    showToast("error in creating profile suggestion", "error")
    throw error;
  } finally {
    dispatch(stopLoader());
  }
};

export const deleteSuggestedCandidateProfile = (suggestionId) => async (dispatch, getState) => {
  dispatch(deleteSuggestedCandidateProfileRequest());
  dispatch(startLoader());

  try {
    const response = await deleteSuggestedCandidateProfileService(suggestionId);

    if (response.success) {
      const suggestedCandidateState = getState().job.suggestedCandidates || {};
      const updatedSuggestedCandidateList = suggestedCandidateState?.data?.filter((existingSuggestedCandidate) => {
        return existingSuggestedCandidate.profileSuggestionId !== suggestionId;
      });

      const updatedPayload = {
        success: true,
        message: suggestedCandidateState.message,
        msgId: suggestedCandidateState.msgId,
        data: updatedSuggestedCandidateList,
        metadata: suggestedCandidateState.metadata,
      };

      dispatch({
        type: GET_SUGGESTED_CANDIDATES_SUCCESS,
        payload: updatedPayload,
      });

      dispatch(deleteSuggestedCandidateProfileSuccess());
      showToast(response.message, 'success');
    } else {

      dispatch(deleteSuggestedCandidateProfileFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(deleteSuggestedCandidateProfileFailure('Error deleting suggested candidate profile'));
    showToast('Error deleting suggested candidate profile', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const setSelectCandidatesForJobSuggestion = (payload) => async (dispatch) => {
  dispatch(selectCandidatesForJobSuggestion(payload));
}

export const removeSelectCandidatesForJobSuggestion = (candidateId) => async (dispatch) => {
  dispatch(removeCandidatesForJobSuggestion(candidateId));
}

export const downloadCandidateData = (startDate, endDate) => async (dispatch) => {
  dispatch(downloadCandidateDataRequest());
  dispatch(startLoader());
  try {
    const response = await exportCandidateDataService(startDate, endDate);

    if (response.success) {
      dispatch(downloadCandidateDataSuccess());
      showToast(response.message, 'success');
    } else {
      dispatch(downloadCandidateDataFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(downloadCandidateDataFailure('Error exporting candidate data'));
    showToast('Error exporting candidate data', 'error');
  } finally {
    dispatch(stopLoader());
  }
}