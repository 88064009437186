import moment from "moment";

export function isDateValid(dateStr) {
    return moment(dateStr, "YYYY-MM-DD").isValid();
}

export function parseMobileNumber(mobileNumber) {
    if (mobileNumber) {
        return mobileNumber.replace(/\D/g, '').slice(-10);
    } else {
        return '';
    }
}

export function convertToYearAndMonth(months) {
    let years = Math.floor(months / 12);
    let remainingMonths = months % 12;
    return years + " Year " + remainingMonths + " Month";
}

export function convertToShortYearAndMonth(months) {
    let years = Math.floor(months / 12);
    let remainingMonths = months % 12;
    return years + "y " + remainingMonths + "m";
}