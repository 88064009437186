// EmployerFilters.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import constants from '../helpers/constants.json';
import { Button, Divider, TextField, Tooltip } from '@mui/material';
import DatePicker from 'react-datepicker';
import Select from "react-select";
import { formatYYYYMMDDDate } from '../helpers/formattedDateTime';
import { ArrowDownward, ArrowUpward, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { setEmployerFilters } from '../redux/actions/employerActions';

const EmployerFilters = ({ onClose, filtersData }) => {
    const dispatch = useDispatch();

    const employerFilters = useSelector((state) => state.employer.employerFilters);

    const [sortDirection, setSortDirection] = useState('desc');

    const initSortOrder = filtersData.sort !== '' ? constants.employerSortOrders?.find(option => option.id === (filtersData.sort?.split(":")[0])) : '';
    const [sortOrderFilter, setSortOrderFilter] = useState(initSortOrder);

    const initActive = filtersData.active !== '' ? constants.boolValueTypes?.find(option => option.id === filtersData.active) : '';
    const [activeFilter, setActiveFilter] = useState(initActive);

    const initVerified = filtersData.verified !== '' ? constants.boolValueTypes?.find(option => option.id === filtersData.verified) : '';
    const [verifiedFilter, setVerifiedFilter] = useState(initVerified);

    const initAssistedHire = filtersData.assistedHire !== '' ? constants.boolValueTypes?.find(option => option.id === filtersData.assistedHire) : '';
    const [assistedHireFilter, setAssistedHireFilter] = useState(initAssistedHire);

    const initProfileIncomplete = filtersData.profileIncomplete !== '' ? constants.boolValueTypes?.find(option => option.id === filtersData.profileIncomplete) : '';
    const [profileIncompleteFilter, setProfileIncompleteFilter] = useState(initProfileIncomplete);

    const initCreateDate = filtersData.hasOwnProperty("createDate") && filtersData.createDate !== '' ? new Date(filtersData.createDate) : '';
    const [createDateFilter, setCreateDateFilter] = useState(initCreateDate);

    const initUpdateDate = filtersData.hasOwnProperty("updateDate") && filtersData.updateDate !== '' ? new Date(filtersData.updateDate) : '';
    const [updateDateFilter, setUpdateDateFilter] = useState(initUpdateDate);

    const handleResetAll = () => {
        let empFilters = {
            sort: '',
            active: '',
            verified: '',
            assitedHire: '',
            profileIncomplete: '',
            createDate: '',
            updateDate: ''
        };

        dispatch(setEmployerFilters(empFilters));

        setSortOrderFilter('');
        setActiveFilter('')
        setVerifiedFilter('');
        setAssistedHireFilter('');
        setProfileIncompleteFilter('');
        setCreateDateFilter('');
        setUpdateDateFilter('');

    }

    const handleFilterChange = (filterType, e) => {
        let empFilters = { ...employerFilters };
        let filterValue = e?.id;
        empFilters[filterType] = filterValue;

        switch (filterType) {
            case 'sort':
                let asortOrder = filterValue + ":" + sortDirection;
                empFilters[filterType] = asortOrder;
                setSortOrderFilter(e);
                break;
            case 'active':
                setActiveFilter(e);
                break;
            case 'verified':
                setVerifiedFilter(e);
                break;
            case 'assistedHire':
                setAssistedHireFilter(e);
                break;
            case 'profileIncomplete':
                setProfileIncompleteFilter(e);
                break;
            case 'createDate':
                filterValue = e;
                empFilters[filterType] = formatYYYYMMDDDate(filterValue);
                setCreateDateFilter(filterValue);
                break;
            case 'updateDate':
                filterValue = e;
                empFilters[filterType] = formatYYYYMMDDDate(filterValue);
                setUpdateDateFilter(filterValue);
                break;
            default:
                break;
        }

        dispatch(setEmployerFilters(empFilters));
    }

    const handleResetSpecific = (filterType) => {
        let empFilters = { ...employerFilters };

        empFilters[filterType] = '';
        switch (filterType) {
            case 'sort':
                setSortOrderFilter('');
                break;
            case 'active':
                setActiveFilter('');
                break;
            case 'verified':
                setVerifiedFilter('');
                break;
            case 'assistedHire':
                setAssistedHireFilter('');
                break;
            case 'profileIncomplete':
                setProfileIncompleteFilter('');
                break;
            case 'createDate':
                setCreateDateFilter('');
                break;
            case 'updateDate':
                setUpdateDateFilter('');
                break;
            default:
                break;
        }

        dispatch(setEmployerFilters(empFilters));
    }

    const changeSortDirection = () => {
        let aDirection = 'asc';
        if (sortDirection == 'asc') {
            aDirection = 'desc';
        }

        let asortOrder = sortOrderFilter?.id + ":" + aDirection;
        let empFilters = { ...employerFilters };
        empFilters['sort'] = asortOrder;

        setSortDirection(aDirection);
        dispatch(setEmployerFilters(empFilters));
    }

    const shouldDisableResetButton = (filterType, filterState) => {
        let shouldDisable = true;
        switch (filterType) {
            case 'sort':
            case 'active':
            case 'verified':
            case 'asssitedHire':
            case 'profileIncomplete':
                shouldDisable = !filterState?.hasOwnProperty('id');
                break;
            case 'createDate':
            case 'updateDate':
                shouldDisable = !Boolean(filterState);
                break;
            default:
                break;
        }
        return shouldDisable;
    }

    const getReadableSortDirection = ( ) => {
        let readableDirection = (sortDirection == 'asc') ? '(Ascending)' : '(Descending)';
        return readableDirection;
    }

    const FilterHeader = (label, filterType, filterState) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className='empFilterLabel'>{label}</div>
                <Button sx={{ textTransform: 'none' }} size='small' variant="text"
                    disabled={shouldDisableResetButton(filterType, filterState)}
                    onClick={() => handleResetSpecific(filterType)}>
                    Reset
                </Button>
            </div>
        )
    }

    const customSelectStyle = (filterState) => ({
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: filterState?.hasOwnProperty('id') ? 'green' : '#ccc',
            boxShadow: filterState?.hasOwnProperty('id')
                ? '0 0 0 1px green'
                : (state.isFocused ? '0 0 0 1px #2684FF' : 'none')
        }),
        // singleValue: (baseStyles) => ({
        //     ...baseStyles,
        //     color: 'green'
        // })
    })

    const customInputStyle = (filterState) => ({
        '& .MuiOutlinedInput-root': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: filterState && 'green',
                borderWidth: filterState && '2px'
            }
        }
    })

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton style={{ marginRight: '8px' }} color="primary" onClick={onClose}>
                    <KeyboardDoubleArrowRight />
                </IconButton>
                <Divider orientation='vertical' flexItem variant='middle' />
                <div style={{ flex: 1, marginLeft: '8px', textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Filters:</div>
                <Divider orientation='vertical' flexItem variant='middle' />
                <Button style={{ marginLeft: '8px' }} variant="text" onClick={handleResetAll}>
                    Clear All
                </Button>
            </div>

            <Divider style={{ marginBottom: '16px', marginLeft: '-16px' }} />

            <style>{`
                    .filterFullWidthDatePicker > .react-datepicker-wrapper{
                        width: 100%
                    }
                    .empFilterLabel {
                        font-size: 14px;
                    }
                `}</style>

            <div>

                {/* Sort By Section */}
                <div>
                    {FilterHeader('Sort by: ' + getReadableSortDirection(), 'sort', sortOrderFilter)}
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <Select
                                defaultValue={sortOrderFilter}
                                value={sortOrderFilter}
                                placeholder="Name, Date..."
                                options={constants.employerSortOrders}
                                onChange={(e) => handleFilterChange("sort", e)}
                                getOptionLabel={(option) => option?.name.toString() || ""}
                                getOptionValue={(option) => option?.id.toString() || ""}
                                menuPortalTarget={document.body}
                                styles={customSelectStyle(sortOrderFilter)}
                            />
                        </div>
                        {/* Sort By Direction Icon Button */}
                        <Tooltip title={sortDirection == 'asc' ? 'Descending' : 'Ascending'}>
                            <Button
                                variant='outlined'
                                size='small'
                                style={{ marginLeft: '8px', minWidth: '32px' }}
                                onClick={changeSortDirection}
                                disabled={sortOrderFilter == '' || sortOrderFilter == null}
                            >
                                {sortDirection == 'asc' ?
                                    <ArrowDownward />
                                    :
                                    <ArrowUpward />
                                }
                            </Button>
                        </Tooltip>
                    </div>
                </div>

                {/* Active Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Active:', 'active', activeFilter)}
                    <Select
                        defaultValue={activeFilter}
                        value={activeFilter}
                        placeholder="Yes, No..."
                        options={constants.boolValueTypes}
                        onChange={(e) => handleFilterChange("active", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(activeFilter)}
                    />
                </div>

                {/* Verified Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Verified:', 'verified', verifiedFilter)}
                    <Select
                        defaultValue={verifiedFilter}
                        value={verifiedFilter}
                        placeholder="Yes, No..."
                        options={constants.boolValueTypes}
                        onChange={(e) => handleFilterChange("verified", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(verifiedFilter)}
                    />
                </div>

                {/* Assisted Hire Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Assisted Hire:', 'assistedHire', assistedHireFilter)}
                    <Select
                        defaultValue={assistedHireFilter}
                        value={assistedHireFilter}
                        placeholder="Yes, No..."
                        options={constants.boolValueTypes}
                        onChange={(e) => handleFilterChange("assistedHire", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(assistedHireFilter)}
                    />
                </div>

                {/* Profile Incomplete Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Profile Incomplete:', 'profileIncomplete', profileIncompleteFilter)}
                    <Select
                        defaultValue={profileIncompleteFilter}
                        value={profileIncompleteFilter}
                        placeholder="Yes, No..."
                        options={constants.boolValueTypes}
                        onChange={(e) => handleFilterChange("profileIncomplete", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(profileIncompleteFilter)}
                    />
                </div>

                {/* Create Date Filter Section */}
                <div style={{ marginTop: '8px' }} className='filterFullWidthDatePicker'>
                    {FilterHeader('Create Date:', 'createDate', createDateFilter)}
                    <DatePicker
                        selected={createDateFilter}
                        onChange={(date) => handleFilterChange("createDate", date)}
                        dateFormat='dd/MM/yyyy'
                        placeholderText='click to select date'
                        autoComplete='off'
                        customInput={
                            <TextField
                                autoComplete='off'
                                fullWidth
                                style={{
                                    width: '100% !important',
                                }}
                                size='small'
                                sx={customInputStyle(createDateFilter)}
                            />
                        }
                    />
                </div>

                {/* Update Date Filter Section */}
                <div style={{ marginTop: '8px' }} className='filterFullWidthDatePicker'>
                    {FilterHeader('Modified Date:', 'updateDate', updateDateFilter)}
                    <DatePicker
                        selected={updateDateFilter}
                        onChange={(date) => handleFilterChange("updateDate", date)}
                        dateFormat='dd/MM/yyyy'
                        placeholderText='click to select date'
                        autoComplete='off'
                        customInput={
                            <TextField
                                autoComplete='off'
                                fullWidth
                                style={{
                                    width: '100% !important',
                                }}
                                size='small'
                                sx={customInputStyle(updateDateFilter)}
                            />
                        }
                    />
                </div>


            </div>

        </div>


    );
};

export default EmployerFilters;