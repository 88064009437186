// PrivateRoute.js
import React from 'react';
import {  Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.user.token);

  if (isLoggedIn) {
    return children;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
