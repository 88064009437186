import React from 'react';
import ReactModal from 'react-modal';
import { formatIndianCurrency } from '../helpers/formatIndianCurrency';
import { Button, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import clipboardService from '../utils/clipboardService';
import CloseIcon from '@mui/icons-material/Close';
import { formatDDMMYYYYDate } from '../helpers/formattedDateTime';
import openLink from '../redux/service/openLinkService';

const GigDetailsModal = ({ isOpen, onClose, gigDetails }) => {
  const dummyImageURL = 'https://via.placeholder.com/150';

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '800px',
      width: '90%',
      maxHeight: '90%',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      background: '#fff',
      padding: '20px',
      overflow: 'auto',
    },
  }
  if (!gigDetails) {
    return null; // Don't render anything if gigDetails is null or undefined
  }

  const handleCopyClick = (shareLink) => {
    clipboardService.copyToClipboard(shareLink);
  };

  const handleLinkClick = (link) => {
    openLink(link)
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Gig Details Modal"
      style={customStyles}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>Gig Details</h2>
        <IconButton onClick={onClose} style={{ marginTop: '1rem' }}>
          <CloseIcon />
        </IconButton>
      </div>


      {/* Information */}
      <div>
        <div style={{ marginBottom: '1rem', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img
            src={gigDetails?.employerCompanyLogo || dummyImageURL}
            alt="Profile"
            style={{ width: '80px', height: '80px', borderRadius: '50%', marginBottom: '1rem' }}
          />

          <Button color="primary" onClick={(e) => { e.preventDefault(); handleCopyClick(gigDetails.shareLink); }}>
            <ContentCopyIcon />
          </Button>
        </div>



        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
          <div>
            <p><strong>Employer Brand Name:</strong> {gigDetails.employerBrandName || '-'}</p>
            <p><strong>Employer Company Name:</strong> {gigDetails.employerCompanyName || '-'}</p>
            <p><strong>Employer Company Type:</strong> {gigDetails.employerCompanyType || '-'}</p>
            <p><strong>Job Title:</strong> {gigDetails.jobTitle || '-'}</p>
            <p><strong>Location:</strong> {gigDetails.jobLocation || '-'}</p>
            <p><strong>No of Positions:</strong> {gigDetails.noOfPosition || '-'}</p>
          </div>

          <div>
            <p><strong>Compensation:</strong> {gigDetails.compensation ? formatIndianCurrency(gigDetails.compensation) : '-'}</p>
            <p><strong>Time Range:</strong> {gigDetails.timeStart || '-'} to {gigDetails.timeEnd || '-'}</p>
            <p><strong>Calling Hours:</strong> {gigDetails.callingHoursStart || '-'} to {gigDetails.callingHoursEnd || '-'}</p>
            <p><strong>Mode of Payment:</strong> {gigDetails.modeOfPayment || '-'}</p>
            <p><strong>Additional Facilities: </strong> {gigDetails.additionalFacilities || '-'}</p>
            <p><strong>Active:</strong> {gigDetails.active ? 'Yes' : 'No'}</p>
            <p><strong>Job Expired: </strong> {gigDetails.jobExpired ? 'Yes' : 'No'}</p>
          </div>
        </div>

        <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
          <h3>Job Description</h3>
          <p>{gigDetails.jobDescription ? gigDetails.jobDescription : 'No Description Available'}</p>
        </div>

        <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
          <h3>Profile Creation and Updating:</h3>
          <p><strong>Create Date: {gigDetails.createDate ? formatDDMMYYYYDate(gigDetails.createDate) : "-"}</strong></p>
          <p><strong>Update Date: {gigDetails.updateDate ? formatDDMMYYYYDate(gigDetails.updateDate) : "-"}</strong></p>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <h3>Links</h3>
          <p><strong>Zomato Link:</strong> {gigDetails.zomatoLink ?
            <Button color="primary" onClick={() => handleLinkClick(gigDetails.zomatoLink)}>
              <ContentCopyIcon />
            </Button> : 'N/A'}
          </p>

          <p><strong>Other Link:</strong> {gigDetails.otherLink ?
            <Button color="primary" onClick={() => handleLinkClick(gigDetails.otherLink)}>
              <ContentCopyIcon />
            </Button> : 'N/A'}
          </p>
        </div>
      </div>
    </ReactModal>
  );
};

export default GigDetailsModal;
