import moment from 'moment';

const formatted12hTime = (timeValue) => {
    if (!(timeValue instanceof Date)) {
      return '';
    }
  
    // Format the time using toLocaleTimeString with 'en-US' locale
    return timeValue.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const convertTimeStringToDate = (timeString) => {
    let date = null;
    if(timeString) {
      const formattedTimeString = moment(timeString, 'hh:mm A');
      date = formattedTimeString.toDate();
    } 
    return date;
  };

  const formatDDMMYYYYDate = (dateString) => {
    const date = moment(dateString);
    return date.format('DD-MM-YYYY');
};

const formatYYYYMMDDDate = (dateString) => {
  const date = moment(dateString);
  return date.format('YYYY-MM-DD');
};

  
  export { formatted12hTime, convertTimeStringToDate, formatDDMMYYYYDate, formatYYYYMMDDDate};
  