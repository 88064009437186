import {
  GET_JOBS,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,
  GET_JOBS_AUDIT,
  GET_JOBS_AUDIT_SUCCESS,
  GET_JOBS_AUDIT_FAILURE,
  GET_SPECIFIC_JOBS_AUDIT,
  GET_SPECIFIC_JOBS_AUDIT_SUCCESS,
  GET_SPECIFIC_JOBS_AUDIT_FAILURE,
  RESET_SPECIFIC_JOBS_AUDIT,
  GET_JOB_APPLICANTS,
  GET_JOB_APPLICANTS_SUCCESS,
  GET_JOB_APPLICANTS_FAILURE,
  GET_SUGGESTED_CANDIDATES,
  GET_SUGGESTED_CANDIDATES_SUCCESS,
  GET_SUGGESTED_CANDIDATES_FAILURE,
  GET_SINGLE_JOB_BY_ID,
  GET_SINGLE_JOB_BY_ID_SUCCESS,
  GET_SINGLE_JOB_BY_ID_FAILURE,
  ADD_JOB,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILURE,
  JOB_ROLES,
  JOB_ROLES_SUCCESS,
  JOB_ROLES_FAILURE,
  RESET_ADDED_JOB_FLAG,
  EDIT_JOB,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_FAILURE,
  RESET_EDITED_JOB_FLAG,
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  SELECT_JOBS_FOR_JOB_SUGGESTION,
  REMOVE_JOBS_FOR_JOB_SUGGESTION,
  REMOVE_ALL_JOBS_FOR_JOB_SUGGESTION,
  EXPORT_JOB_POSTS_REQUEST,
  EXPORT_JOB_POSTS_FAILURE,
  ONLY_JOB_ROLES,
  ONLY_JOB_ROLES_SUCCESS,
  ONLY_JOB_ROLES_FAILURE,
  SET_JOB_APPLICANTS_PAGE_NUMBER,
  SET_JOBS_FILTERS
} from '../actions/jobActions';

const initialState = {
  jobs: null,
  jobsFilters: {
    sort: '',
    active: '',
    assistedHire: '',
    createDate: '',
    updateDate: ''
  },
  jobsAudit: null,
  specificJobsAudit: null,
  jobApplicants: null,
  suggestedCandidates: null,
  jobDetailsById: null,
  newJobCreated: null,
  jobRoles: null,
  editJobSuccess: null,
  deleteJobSuccess: null,
  selectedJobsForJobSuggestion: null,
  onlyJobRoles: null,
  jobApplicantsPageNumber: 0
};

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBS:
      return {
        ...state,
      };

    case GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
      };

    case GET_JOBS_FAILURE:
      return {
        ...state,
      };

    case SET_JOBS_FILTERS:
      return {
        ...state,
        jobsFilters: action.payload,
      };

    case GET_JOBS_AUDIT:
      return {
        ...state,
      };

    case GET_JOBS_AUDIT_SUCCESS:
      return {
        ...state,
        jobsAudit: action.payload,
      };

    case GET_JOBS_AUDIT_FAILURE:
      return {
        ...state,
      };

    case GET_SPECIFIC_JOBS_AUDIT:
      return {
        ...state,
      };

    case GET_SPECIFIC_JOBS_AUDIT_SUCCESS:
      return {
        ...state,
        specificJobsAudit: action.payload,
      };

    case GET_SPECIFIC_JOBS_AUDIT_FAILURE:
      return {
        ...state,
      };

    case RESET_SPECIFIC_JOBS_AUDIT:
      return {
        ...state,
        specificJobsAudit: null
      };

    case JOB_ROLES:
      return {
        ...state,
      };

    case JOB_ROLES_SUCCESS:
      return {
        ...state,
        jobRoles: action.payload,
      };

    case JOB_ROLES_FAILURE:
      return {
        ...state,
      };

    case GET_SINGLE_JOB_BY_ID:
      return {
        ...state,
      };

    case GET_SINGLE_JOB_BY_ID_SUCCESS:
      return {
        ...state,
        jobDetailsById: action.payload,
      };

    case GET_SINGLE_JOB_BY_ID_FAILURE:
      return {
        ...state,
      };

    case GET_JOB_APPLICANTS:
      return {
        ...state,
        jobApplicants: null
      };

    case GET_JOB_APPLICANTS_SUCCESS:
      return {
        ...state,
        jobApplicants: action.payload,
      };

    case GET_JOB_APPLICANTS_FAILURE:
      return {
        ...state
      };

    case GET_SUGGESTED_CANDIDATES:
      return {
        ...state,
        suggestedCandidates: null
      };

    case GET_SUGGESTED_CANDIDATES_SUCCESS:
      return {
        ...state,
        suggestedCandidates: action.payload,
      };

    case GET_SUGGESTED_CANDIDATES_FAILURE:
      return {
        ...state,
      };

    case ADD_JOB:
      return {
        ...state,
      };

    case ADD_JOB_SUCCESS:
      return {
        ...state,
        newJobCreated: true,
      };

    case ADD_JOB_FAILURE:
      return {
        ...state,
      };

    case DELETE_JOB:
      return {
        ...state,
      };

    case DELETE_JOB_SUCCESS:
      return {
        ...state,
        deleteJobSuccess: true,
      };

    case DELETE_JOB_FAILURE:
      return {
        ...state,
      };

    case RESET_ADDED_JOB_FLAG:
      return {
        ...state,
        newJobCreated: null
      };

    case RESET_EDITED_JOB_FLAG:
      return {
        ...state,
        editJobSuccess: null
      };

    case EDIT_JOB:
      return {
        ...state,
      };

    case EDIT_JOB_SUCCESS:
      return {
        ...state,
        editJobSuccess: true,
      };

    case EDIT_JOB_FAILURE:
      return {
        ...state,
      };

    case ONLY_JOB_ROLES:
      return {
        ...state,
      };

    case ONLY_JOB_ROLES_SUCCESS:
      return {
        ...state,
        onlyJobRoles: action.payload,
      };

    case ONLY_JOB_ROLES_FAILURE:
      return {
        ...state,
      };

    case SELECT_JOBS_FOR_JOB_SUGGESTION:
      return {
        ...state,
        selectedJobsForJobSuggestion: action.payload
      }

    case REMOVE_JOBS_FOR_JOB_SUGGESTION:
      return {
        ...state,
        selectedJobsForJobSuggestion: state.selectedJobsForJobSuggestion.filter(job => job.jobPostId !== action.payload),
      };

    case REMOVE_ALL_JOBS_FOR_JOB_SUGGESTION:
      return {
        ...state,
        selectedJobsForJobSuggestion: null,
      };

    case EXPORT_JOB_POSTS_REQUEST:
      return {
        ...state,
      };

    case EXPORT_JOB_POSTS_FAILURE:
      return {
        ...state,
      };

    case SET_JOB_APPLICANTS_PAGE_NUMBER:
      return {
        ...state,
        jobApplicantsPageNumber: action.payload
      };

    default:
      return state;
  }
};

export default jobReducer;
