import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import TextFieldComponent from '../components/TextFieldComponent';
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getJobApplicantsList, getJobsList, getSuggestedCandidatesList, getJobByIdDetails, deleteJobRecord, setSelectJobsForJobSuggestion, setJobApplicantsPageNumber } from '../redux/actions/jobActions';
import SuggestedCandidateModal from '../models/SuggestedCandidateModal';
import JobApplicantsModal from '../models/JobApplicantsModal';
import JobPostForm from '../pages/JobPostForm';
import { formatIndianCurrency } from '../helpers/formatIndianCurrency';
import moment from 'moment';
import JobPostRecordModal from '../models/JobPostRecord';
import { useLocation } from 'react-router-dom';
import ConfirmationDialog from '../components/ConfirmationDialog';
import Button from '@mui/material/Button';
import clipboardService from '../utils/clipboardService';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { isSuperUser } from '../redux/selectors/userSelector';
import CheckboxComponent from '../components/CheckBoxComponent';
import AddIcon from '@mui/icons-material/Add';
import SuggestedCandidatesAndJobsModal from '../models/SuggestedCandidatesAndJobsModal';
import constants from '../helpers/constants.json';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Add, ArrowBackIosNew, FilterAlt } from '@mui/icons-material';
import ExportComponent from '../components/ExportComponent';
import { IconButton, Tooltip } from '@mui/material';
import JobPostFilters from './JobPostFilters';

const ViewJobs = () => {
  const MENU_ID = 'JOB_ACTION'
  const [selectedRow, setSelectedRow] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const employerId = searchParams.get('employerId');

  const isMountingRef = useRef(false);

  const [queryParams, setQueryParams] = useState({
    pageNumber: 0,
    pageSize: 10,
    searchText: '',
    employerId: employerId ? employerId : '',
  });

  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [job, selectedJob] = useState(null);
  const [jobPostId, setJobPostId] = useState(null)
  const [isEditJobVisible, setEditJobVisible] = useState(false);
  const [isSuggestedCandidateModalOpen, setIsSuggestedCandidateModalOpen] = useState(false);
  const [isJobApplicantsModalOpen, setIsJobApplicantsModalOpen] = useState(false);
  const [isMoreDetailsVisible, setIsMoreDetailsVisible] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [selectedJobForSuggestedCandidate, setSelectedJobForSuggestedCandidate] = useState([]);
  const [isSuggestedCandidateAndJobsModalOpen, setSuggestedCandidateAndJobsModalOpen] = useState(false);
  const [isJobsFiltersOpen, setIsJobsFiltersOpen] = useState(false);


  const dispatch = useDispatch();
  const jobsResponse = useSelector((state) => state.job.jobs);
  const jobs = jobsResponse?.data;

  const jobsApplicantsResponse = useSelector((state) => state.job.jobApplicants);
  const jobApplicants = jobsApplicantsResponse;

  const jobMoreDetailsResponse = useSelector((state) => state.job.jobDetailsById);
  const jobMoreDetails = jobMoreDetailsResponse?.data;

  const jobsFilters = useSelector((state) => state.job.jobsFilters);

  const suggestedCandidatesResponse = useSelector((state) => state.job.suggestedCandidates);
  const suggestedCandidates = suggestedCandidatesResponse;

  const hasSuggestedCandidatesForJobSuggestion = useSelector((state) => state.candidate.selectedCandidatesForJobSuggestion?.length > 0);

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const handleContextMenu = (event, job, index) => {
    setSelectedRow(index);
    show({
      event,
      props: job,
    });
  };

  const superUser = useSelector(isSuperUser);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    let aParams = { ...queryParams, ...jobsFilters };
    dispatch(getJobsList(aParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (!isMountingRef.current) {
      setQueryParams((prevParams) => ({ ...prevParams, pageNumber: 0 }));
    } else {
      isMountingRef.current = false;
    }
  }, [jobsFilters]);

  const handleEditClick = (job) => {
    selectedJob(null);
    selectedJob(job);
    setEditJobVisible(true);
  };

  const handleSuggestedCandidateClick = (id) => {
    setIsSuggestedCandidateModalOpen(true);
    dispatch(getSuggestedCandidatesList(id));
  };

  const viewMoreDetails = (id) => {
    setIsMoreDetailsVisible(true);
    dispatch(getJobByIdDetails(id));
  };

  const handleJobApplicantsClick = (id) => {
    dispatch(setJobApplicantsPageNumber(0));
    dispatch(getJobApplicantsList(id, 0));
    setIsJobApplicantsModalOpen(true);
  };

  const handleCloseSuggestedCandidateModal = () => {
    setIsSuggestedCandidateModalOpen(false);
  };

  const handleCloseJobApplicantsModal = () => {
    setIsJobApplicantsModalOpen(false);
  };

  const handleCloseJobMoreDetailsModal = () => {
    setIsMoreDetailsVisible(false);
  };

  const handleDeleteClick = (job) => {
    selectedJob(job)
    setShowDeleteDialog(true);
  };

  const handlePageChange = (event, page) => {
    handleCloseJobModal();
    setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
  };

  const handleSearch = (text) => {
    handleCloseJobModal();
    setSearchTerm(text);
    clearTimeout(typingTimeout);
    const timeout = setTimeout(() => {
      setQueryParams((prevParams) => ({ ...prevParams, searchText: text, pageNumber: 0 }));
    }, 2000);
    setTypingTimeout(timeout);
  };

  const handleCloseJobModal = () => {
    setEditJobVisible(false);
    selectedJob(null);
  };


  const handleConfirmationDialogClose = () => {
    setShowDeleteDialog(false);
    selectedJob(null)
    setSelectedRow(null);
  };

  const handleConfirmationDialog = () => {
    dispatch(deleteJobRecord(job.jobPostId))
    setShowDeleteDialog(false);
    selectedJob(null);
    setSelectedRow(null);
  };

  const handleCopyClick = (shareLink) => {
    clipboardService.copyToClipboard(shareLink);
  };

  const handleFilterClick = () => {
    setIsJobsFiltersOpen(true);
  }

  const handleCloseJobsFilters = () => {
    setIsJobsFiltersOpen(false);
  }

  const handleItemClick = ({ event, id }) => {
    const job = event.props
    const { jobPostId } = event.props;
    setJobPostId(jobPostId);

    switch (id) {
      case "view_more":
        viewMoreDetails(jobPostId)
        break;
      case "suggested_candidate":
        handleSuggestedCandidateClick(jobPostId)
        break;
      case "job_applicants":
        handleJobApplicantsClick(jobPostId)
        break;
      case "edit":
        handleEditClick(job);
        break;
      case "delete":
        setEditJobVisible(false);
        handleDeleteClick(job)
        break;
      default:
        break
    }
  }

  const handleSuggestedJobChange = (job) => {
    setSelectedJobForSuggestedCandidate(prevSelectedJobPosts => {
      if (prevSelectedJobPosts.some(c => c.jobPostId === job.jobPostId)) {
        // If the job is already selected, remove it from the selected candidates list
        return prevSelectedJobPosts.filter(c => c.jobPostId !== job.jobPostId);
      } else {
        // If the job is not selected, add it to the selected candidates list
        return [...prevSelectedJobPosts, job];
      }
    });
  };

  const openSuggestedCandidateAndJobsModal = () => {
    dispatch(setSelectJobsForJobSuggestion(selectedJobForSuggestedCandidate))
    setSuggestedCandidateAndJobsModalOpen(true);
  }

  const handleCloseCandidateAndJobsModalOpen = () => {
    setSuggestedCandidateAndJobsModalOpen(false);
  }

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: isEditJobVisible ? 8 : (isJobsFiltersOpen ? 9 : 12) }} >

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {/* Search Text Field */}
            <div>
              <TextFieldComponent
                label="Search"
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: '350px' }}
              />
            </div>

            {superUser && (
              <ExportComponent
                screenName={constants.screenName.JOB_POST}
                style={{ marginLeft: '10px' }}
              />
            )}

          </div>

          <div>
            {/* Create Suggested Candidates Button */}
            {selectedJobForSuggestedCandidate.length > 0 && (
              <Button variant="contained" color="primary" onClick={() => openSuggestedCandidateAndJobsModal()} style={{ whiteSpace: 'nowrap' }}>
                <AddIcon style={{ marginRight: '8px' }} />
                Suggest Candidates
              </Button>
            )}

            {/* Filter Button */}
            <Tooltip title="Filter">
              <IconButton
                style={{ marginLeft: '8px' }}
                sx={{
                  backgroundColor: "#1976d2",
                  color: 'white',
                  ':hover': {
                    backgroundColor: 'primary.light'
                  },
                }}
                onClick={handleFilterClick}>
                <FilterAlt />
              </IconButton>
            </Tooltip>
          </div>

        </div>


        <div style={{ margin: '16px 0' }} />
        {jobs?.length ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {hasSuggestedCandidatesForJobSuggestion && <TableCell>Select</TableCell>}
                    <TableCell style={styles.textBold}>Job Title</TableCell>
                    <TableCell style={styles.textBold}>Employer Brand Name</TableCell>
                    <TableCell style={styles.textBold}>Job Location</TableCell>
                    <TableCell style={styles.textBold}>Salary Range</TableCell>
                    <TableCell style={styles.textBold}>Accommodation Provided</TableCell>
                    <TableCell style={styles.textBold}>Shift Timing</TableCell>
                    <TableCell style={styles.textBold}>Job Applicants</TableCell>
                    <TableCell style={styles.textBold}>Share Link</TableCell>
                    <TableCell style={styles.textBold}>Created At</TableCell>
                    <TableCell style={styles.textBold}>Updated At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs?.map((job, index) => (
                    <TableRow onContextMenu={(event) => handleContextMenu(event, job, index)} key={job.jobPostId}
                      onDoubleClick={() => viewMoreDetails(job.jobPostId)}
                      onClick={() => setSelectedRow(index)}
                      style={{
                        backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                      }}
                    >
                      {hasSuggestedCandidatesForJobSuggestion &&
                        <TableCell>
                          <CheckboxComponent
                            label=""
                            field={{
                              name: job.jobPostId,
                              value: selectedJobForSuggestedCandidate.some((c) => c.jobPostId === job.jobPostId),
                            }}
                            form={{
                              setFieldValue: () => handleSuggestedJobChange(job),
                            }}
                          />
                        </TableCell>
                      }
                      <TableCell>{job.jobTitle}</TableCell>
                      <TableCell style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>{job.employerBrandName}</TableCell>
                      <TableCell>{job.jobLocation}</TableCell>
                      <TableCell>{`${formatIndianCurrency(job.salaryLowerRange)} - ${formatIndianCurrency(
                        job.salaryUpperRange
                      )}`}</TableCell>
                      <TableCell>{job.provideAccomodation ? 'Yes' : 'No'}</TableCell>
                      <TableCell>{`${job.shiftStartTime} - ${job.shiftEndTime}`}</TableCell>
                      <TableCell>{job.noOfJobApplications}</TableCell>
                      <TableCell>
                        <Button color="primary" onClick={(e) => { e.preventDefault(); handleCopyClick(job.shareLink); }}>
                          <ContentCopyIcon />
                        </Button>
                      </TableCell>
                      <TableCell>{job.createDate && moment(job.createDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{job.updateDate && moment(job.updateDate).format('DD/MM/YYYY')}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Menu id={MENU_ID}>
              <Item id="view_more" onClick={(event, props) => handleItemClick({ event, props, id: 'view_more' })}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <VisibilityIcon />
                  <span style={{ marginLeft: '5px' }}>View More</span>
                </div>
              </Item>
              <Item id="suggested_candidate" onClick={(event) => handleItemClick({ event, id: 'suggested_candidate' })}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <LightbulbIcon />
                  <span style={{ marginLeft: '5px' }}>Suggested Candidate</span>
                </div>
              </Item>

              <Item id="job_applicants" onClick={(event) => handleItemClick({ event, id: 'job_applicants' })}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <PeopleIcon />
                  <span style={{ marginLeft: '5px' }}>Job Applicants</span>
                </div>
              </Item>
              <Separator />
              <Item id="edit" onClick={(event) => handleItemClick({ event, id: 'edit' })}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <EditIcon />
                  <span style={{ marginLeft: '5px' }}>Edit</span>
                </div>
              </Item>
              {superUser && <Item id="delete" onClick={(event) => handleItemClick({ event, id: 'delete' })}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
                  <DeleteIcon />
                  <span style={{ marginLeft: '5px' }}>Delete</span>
                </div>
              </Item>}

            </Menu>

            <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
              <Pagination
                page={queryParams.pageNumber + 1}
                onChange={handlePageChange}
                color="primary"
                count={jobsResponse?.metadata?.totalPages}
                size="small"
              />
            </Grid>
          </>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1>No Jobs</h1>
          </div>
        )}

        {isSuggestedCandidateModalOpen && suggestedCandidates !== null && (
          <SuggestedCandidateModal
            isOpen={isSuggestedCandidateModalOpen}
            onClose={handleCloseSuggestedCandidateModal}
            suggestedCandidates={suggestedCandidates}
          />
        )}

        {isJobApplicantsModalOpen && jobApplicants !== null && (
          <JobApplicantsModal
            jobPostId={jobPostId}
            isOpen={isJobApplicantsModalOpen}
            onClose={handleCloseJobApplicantsModal}
            jobApplicants={jobApplicants}
          />
        )}

        {isMoreDetailsVisible && jobMoreDetails !== null && (
          <JobPostRecordModal
            isOpen={isMoreDetailsVisible}
            onClose={handleCloseJobMoreDetailsModal}
            jobPostData={jobMoreDetails}
          />
        )}

        {isSuggestedCandidateAndJobsModalOpen === true && (
          <SuggestedCandidatesAndJobsModal
            isOpen={isSuggestedCandidateAndJobsModalOpen}
            onClose={handleCloseCandidateAndJobsModalOpen}

          />
        )}
      </Grid>

      {isEditJobVisible && (
        <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
          <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleCloseJobModal} style={{ marginBottom: '16px' }}>
            Back
          </Button>
          <JobPostForm
            jobPostQueryParams={queryParams}
            employerId={job.employerId}
            jobDetail={job}
            onClose={handleCloseJobModal}
            isOpen={isEditJobVisible}
          />
        </Grid>
      )}

      {isJobsFiltersOpen &&
        <Grid size={{ xs: 12, md: 3 }} style={{ paddingTop: '8px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
          <JobPostFilters
            onClose={handleCloseJobsFilters}
            filtersData={jobsFilters}
          />
        </Grid>
      }

      {showDeleteDialog === true && (
        <ConfirmationDialog
          open={true}
          onClose={handleConfirmationDialogClose}
          onConfirm={handleConfirmationDialog}
          message="Do you want to Delete?"
        />
      )}
    </Grid>
  );
};

export default ViewJobs;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}