import React, { useState, useEffect } from 'react';
import TextFieldComponent from '../components/TextFieldComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ButtonComponent from '../components/ButtonComponent';
import AsyncSelect from 'react-select/async';
import { getAppUsers, sendNotificationToUsers } from '../redux/service/userService';
import showToast from '../utils/toast';
import { FormHelperText } from '@mui/material';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  body: Yup.string().required('Body is required'),
  userIds: Yup.array().min(1, "Select at least one user").required()
});

const ViewNotifications = () => {

  const dispatch = useDispatch();
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [initAppUsers, setInitAppUsers] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      body: '',
      userIds: []
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {

      const tempUserIds = values.userIds.map(item => item.id);
      let notData = {
        title: values.title,
        body: values.body,
        users: tempUserIds
      }
      try {
        await sendNotificationToUsers(notData);
        showToast("Notification sent successfully.", "success");
        formik.resetForm();
      } catch (err) {
        showToast("Something went wrong.", "error");
      }
    }
  });

  useEffect(() => {
    async function fetchData() {
      let queryParams = {
        "pageNumber": 0
      };
      let userListResponse = await getAppUsers(queryParams);
      setInitAppUsers(userListResponse.data);
    }
    fetchData();
  }, []);

  const filterUsers = async (inputValue) => {
    let queryParams = {
      "pageNumber": 0,
      "searchText": inputValue
    };
    let userListResponse = await getAppUsers(queryParams);
    return userListResponse.data;
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(typingTimeout); // Clear the previous timeout
      const timeout = setTimeout(() => {
        resolve(filterUsers(inputValue));
      }, 700);
      setTypingTimeout(timeout);
    });

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <form onSubmit={formik.handleSubmit}>
        <TextFieldComponent
          label="Title *"
          type="text"
          field={{
            ...formik.getFieldProps('title'),
            onChange: formik.handleChange('title'),
          }}
          form={formik}
          fullWidth
          style={{ marginBottom: '16px' }}
        />

        <TextFieldComponent
          label="Body *"
          type="text"
          multiline={true}
          rows={5}
          field={{
            ...formik.getFieldProps('body'),
            onChange: formik.handleChange('body'),
          }}
          form={formik}
          fullWidth
          style={{ marginBottom: '16px' }}
        />

        <div style={{ marginBottom: 16 }}>
          <AsyncSelect
            name="userIds"
            onChange={(e) => {
              formik.setFieldValue('userIds', e);
            }}
            form={formik}
            fullWidth
            isMulti
            placeholder="Select Users"
            defaultOptions={initAppUsers}
            loadOptions={promiseOptions}
            getOptionLabel={(option) => option?.name?.toString() + ` [${option?.mobileNumber?.toString()}]` || ""}
            getOptionValue={(option) => option?.id?.toString() || ""}
            value={formik.values.userIds}
          />
          {formik && formik?.touched["userIds"] && formik?.errors["userIds"] &&
            <FormHelperText>{formik?.errors["userIds"]}</FormHelperText>
          }
        </div>

        <ButtonComponent
          type="submit"
          label={formik.isSubmitting ? 'Submitting...' : 'Submit'}
          disabled={formik.isSubmitting}
          fullWidth
        />
      </form>

    </div>
  );
};

export default ViewNotifications;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}