import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextFieldComponent from '../components/TextFieldComponent';
import DropdownComponent from '../components/DropDownComponent';
import ButtonComponent from '../components/ButtonComponent';
import CheckboxComponent from '../components/CheckBoxComponent'
import constants from '../helpers/constants.json';
import moment from 'moment';

import {
  addEmployerData,
  clearAddedEmployerFlag,
  clearEditedEmployerFlag,
  editEmployerData,
  getEmployeesList,
} from '../redux/actions/employerActions';
import { formatYYYYMMDDDate } from '../helpers/formattedDateTime';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits')
    .required('Mobile Number is required'),
  dob: Yup.date().required('Date of Birth is required').test(
    "DOB",
    "Employer must be 18 years old",
    value => {
      return moment().diff(moment(value), 'years') >= 18;
    }
  ),
  addressLine3: Yup.string().required('Address Line 3 is required'),
  outletType: Yup.object().required('Outlet type is required'),
  companyName: Yup.string().required('Company name is required'),
  brandName: Yup.string().required('Brand name is required'),
});

const EmployerForm = ({ employerDetail, employerQueryParams,  onClose }) => {
  const dispatch = useDispatch();
  const isNewEmployerCreated = useSelector((state) => state.employer.newEmployerCreated);
  const isEmployerEditedSuccess = useSelector((state) => state.employer.editEmployerSuccess);

  const { gender, outletTypeList } = constants;

  const genderObject = gender.find(gender => gender?.id === employerDetail?.gender);
  const outletObject = outletTypeList.find(outletType => outletType?.id === employerDetail?.outletType)


  const updatedEmployerDetails = {
      ...employerDetail,
      gender: genderObject || null,
      outletType: outletObject || null
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: employerDetail ? employerDetail.name : '',
      mobileNumber: employerDetail ? employerDetail.mobileNumber : '',
      gender: updatedEmployerDetails ? updatedEmployerDetails.gender : null,
      dob: employerDetail ? new Date(employerDetail.dob) : null,
      description: employerDetail ? employerDetail.description : '',
      outletType: updatedEmployerDetails ? updatedEmployerDetails.outletType : '',
      companyName: employerDetail ? employerDetail.companyName : '',
      brandName: employerDetail ? employerDetail.brandName : '',
      addressLine1: employerDetail ? employerDetail.addressLine1 : '',
      addressLine2: employerDetail ? employerDetail.addressLine2 : '',
      addressLine3: employerDetail ? employerDetail.addressLine3 : '',
      zomatoLink: employerDetail ? employerDetail.zomatoLink : '',
      otherLink: employerDetail ? employerDetail.otherLink : '',
      companyEmail: employerDetail ? employerDetail.companyEmail : '',
      isVerified: employerDetail ? employerDetail.verified : false,
      isAssistedHire: employerDetail ? employerDetail.assistedHire : false,
      isGigApproved: employerDetail ? employerDetail.gigApproved : false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        outletType: values.outletType?.id,
        gender: values.gender?.id,
        dob: formatYYYYMMDDDate(values.dob),
      };

      if(employerDetail?.employerId){
        await dispatch(editEmployerData(employerDetail.employerId,formattedValues));
      }else{
        await dispatch(addEmployerData(formattedValues));
      }
      onClose();
    },
  });

  useEffect(() => {
    if (isNewEmployerCreated) {
      formik.resetForm()
      dispatch(clearAddedEmployerFlag());
      dispatch(getEmployeesList(employerQueryParams))
    }
  }, [dispatch, formik, isNewEmployerCreated, employerQueryParams]);

  useEffect(() => {
    if (isEmployerEditedSuccess) {
      formik.resetForm()
      dispatch(clearEditedEmployerFlag());
      onClose();
    }
  }, [dispatch, formik, isEmployerEditedSuccess, onClose]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldComponent
        label="Name *"
        type="text"
        field={{
          ...formik.getFieldProps('name'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
        error={formik.touched.name && formik.errors.name}
      />

      <div style={{ marginBottom: '16px', marginTop: '16px' }}>
        <TextFieldComponent
          label="Date of Birth *"
          type="date"
          field={{
            ...formik.getFieldProps('dob'),
            onChange: formik.handleChange('dob'),
          }}
          form={formik}
          fullWidth
          style={{ marginBottom: '16px' }}
          error={formik.touched.dob && formik.errors.dob}
        />
      </div>

      <TextFieldComponent
        label="Mobile Number *"
        type="tel"
        inputmode="numeric"
        pattern="[0-9\s]{13,19}"
        disabled={employerDetail ? true : false}
        maxLength={10}
        minLength={10}
        field={{
          ...formik.getFieldProps('mobileNumber'),
          onChange: formik.handleChange('mobileNumber'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
        error={formik.touched.mobileNumber && formik.errors.mobileNumber}
      />

      <DropdownComponent
        label="Gender"
        field={{
          ...formik.getFieldProps('gender'),
          onChange: formik.handleChange('gender'),
        }}
        form={formik}
        options={gender}
        fullWidth
        style={{ marginBottom: '16px', }}
      />


      <TextFieldComponent
        label="Description"
        type="text"
        multiline={true}
        rows={4}
        field={{
          ...formik.getFieldProps('description'),
          onChange: formik.handleChange('description'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px', }}
      />

      <DropdownComponent
        label="Outlet Type *"
        field={{
          ...formik.getFieldProps('outletType'),
          onChange: formik.handleChange('outletType'),
        }}
        form={formik}
        options={outletTypeList}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <TextFieldComponent
        label="Company Name *"
        type="text"
        field={{
          ...formik.getFieldProps('companyName'),
          onChange: formik.handleChange('companyName'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <TextFieldComponent
        label="Brand Name *"
        type="text"
        field={{
          ...formik.getFieldProps('brandName'),
          onChange: formik.handleChange('brandName'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <TextFieldComponent
        label="Address Line 1"
        type="text"
        field={{
          ...formik.getFieldProps('addressLine1'),
          onChange: formik.handleChange('addressLine1'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <TextFieldComponent
        label="Address Line 2"
        type="text"
        field={{
          ...formik.getFieldProps('addressLine2'),
          onChange: formik.handleChange('addressLine2'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <TextFieldComponent
        label="Address Line 3 *"
        type="text"
        field={{
          ...formik.getFieldProps('addressLine3'),
          onChange: formik.handleChange('addressLine3'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <TextFieldComponent
        label="Zomato Link"
        type="text"
        field={{
          ...formik.getFieldProps('zomatoLink'),
          onChange: formik.handleChange('zomatoLink'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <TextFieldComponent
        label="Other Link"
        type="text"
        field={{
          ...formik.getFieldProps('otherLink'),
          onChange: formik.handleChange('otherLink'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <TextFieldComponent
        label="Company Email"
        type="email"
        field={{
          ...formik.getFieldProps('companyEmail'),
          onChange: formik.handleChange('companyEmail'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <CheckboxComponent
        label="Verified? *"
        field={{
          ...formik.getFieldProps('isVerified'),
          onChange: () => formik.handleChange('isVerified'),
        }}
        form={formik}
        style={{ marginBottom: '16px' }}
      />

      <CheckboxComponent
        label="Assisted Hire? *"
        field={{
          ...formik.getFieldProps('isAssistedHire'),
          onChange: () => formik.handleChange('isAssistedHire'),
        }}
        form={formik}
        style={{ marginBottom: '16px' }}
      />

      <CheckboxComponent
        label="Gig Approved? *"
        field={{
          ...formik.getFieldProps('isGigApproved'),
          onChange: () => formik.handleChange('isGigApproved'),
        }}
        form={formik}
        style={{ marginBottom: '16px' }}
      />

      <ButtonComponent
        type="submit"
        label={formik.isSubmitting ? 'Submitting...' : 'Submit'}
        fullWidth
        disabled={formik.isSubmitting}
      />
    </form>
  );
};

export default EmployerForm;
