import React, { useState } from 'react';
import ReactModal from 'react-modal';
import Pagination from '@mui/material/Pagination';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import moment from 'moment'
import { formatIndianCurrency } from '../helpers/formatIndianCurrency';
import CloseIcon from '@mui/icons-material/Close';
import JobPostRecordModal from './JobPostRecord';
import { useDispatch, useSelector } from 'react-redux';
import { getJobByIdDetails } from '../redux/actions/jobActions';
import { getCandidateJobApplicationsData } from '../redux/actions/candidateActions';

const CandidateJobApplicationModal = ({ isOpen, onClose, jobApplications, candidateId }) => {

  const [queryParams, setQueryParams] = useState({
    pageNumber: 0,
});

  const dispatch = useDispatch();
  const [isMoreDetailsVisible, setIsMoreDetailsVisible] = useState(false);

  const customModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(4px)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      left: '5%',
      top: '10%',
      zIndex: 1001,
      width: '94%',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      overflowY: 'auto',
    }
  }

  const currentApplications = jobApplications?.data || []

  const jobMoreDetailsResponse = useSelector((state) => state.job.jobDetailsById);
  const jobMoreDetails = jobMoreDetailsResponse?.data;

  const handleCloseJobMoreDetailsModal = () => {
    setIsMoreDetailsVisible(false);
  };

  const viewMoreDetails = (id) => {
    setIsMoreDetailsVisible(true);
    dispatch(getJobByIdDetails(id));
  };

  const handlePageChange = (event, page) => {
    setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
    dispatch(getCandidateJobApplicationsData(candidateId, page - 1));
};

  if (!jobApplications) {
    return null; // Don't render anything if jobApplications is null or undefined
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Candidate Job Applications Modal"
      style={customModalStyles}>
      <div style={{ width: '100%' }}>

        {/* Application Information */}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Candidate Job Applications</h2>
          <IconButton onClick={onClose} style={{ marginTop: '1rem' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold}>Job Title</TableCell>
                <TableCell style={styles.textBold}>Job Location</TableCell>
                <TableCell style={styles.textBold}>Salary Range</TableCell>
                <TableCell style={styles.textBold}>Provide Accommodation</TableCell>
                <TableCell style={styles.textBold}> Required English Proficiency</TableCell>
                <TableCell style={styles.textBold}>Employer Brand Name</TableCell>
                <TableCell style={styles.textBold}>Active</TableCell>
                <TableCell style={styles.textBold}>Created Date</TableCell>
                <TableCell style={styles.textBold}>Job Expired</TableCell>
                <TableCell style={styles.textBold}>Applied On Date</TableCell>
                <TableCell style={styles.textBold}>Application Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {currentApplications?.map((application, index) => (
                <TableRow
                  style={{ cursor: 'pointer' }}
                  onDoubleClick={() => viewMoreDetails(application.jobPostId)}
                  key={application.jobApplicationId}
                >
                  <TableCell>{application.jobTitle}</TableCell>
                  <TableCell>{application.jobLocation}</TableCell>
                  <TableCell>
                    {`${formatIndianCurrency(application.salaryLowerRange)} - ${formatIndianCurrency(application.salaryUpperRange)}`}
                  </TableCell>
                  <TableCell>{application.provideAccomodation ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{application.requiredEnglishProficiency}</TableCell>
                  <TableCell>{application.employerBrandName || 'Not specified'}</TableCell>
                  <TableCell>{application.active ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{moment(application.createDate).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{application.jobExpired ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{moment(application.appliedOnDate).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{application.applicationStatus || 'Not specified'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
          <Pagination
            page={queryParams.pageNumber + 1}
            onChange={handlePageChange}
            color="primary"
            count={jobApplications?.metadata?.totalPages}
            size="small"
          />
        </div>
      </div>

      {isMoreDetailsVisible && jobMoreDetails !== null && (
        <JobPostRecordModal
          isOpen={isMoreDetailsVisible}
          onClose={handleCloseJobMoreDetailsModal}
          jobPostData={jobMoreDetails}
        />
      )}

    </ReactModal>
  );
};

export default CandidateJobApplicationModal;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}