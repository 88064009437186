import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextFieldComponent from '../components/TextFieldComponent';
import ButtonComponent from '../components/ButtonComponent';
import DropdownComponent from '../components/DropDownComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addJobRole, editJobRoleById, onlyJobRolesList } from '../redux/actions/jobActions';

const validationSchema = Yup.object().shape({
    position: Yup.string().required('Position is required'),
    jobDescription: Yup.string().required('Job Description is required'),
});

const AddJobRole = ({ onClose, jobRoleDetail }) => {
    const dispatch = useDispatch();
    const onlyJobRoles = useSelector((state) => state.job.onlyJobRoles);

    useEffect(() => {
        dispatch(onlyJobRolesList());
    }, [dispatch]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            position: jobRoleDetail?.name || '',
            jobDescription: jobRoleDetail?.description || '',
            department: jobRoleDetail?.department || '',
            similarRole1: jobRoleDetail?.similarRole1 || null,
            similarRole2: jobRoleDetail?.similarRole2 || null,
            similarRole3: jobRoleDetail?.similarRole3 || null,
        },
        validationSchema: validationSchema,

        onSubmit: async (values) => {
            const { similarRole1, similarRole2, similarRole3, ...restValues } = values;
            const payload = {
                ...restValues,
                similarRole1: similarRole1 ? similarRole1.id : null,
                similarRole2: similarRole2 ? similarRole2.id : null,
                similarRole3: similarRole3 ? similarRole3.id : null,
            };

            if (jobRoleDetail) {
                await dispatch(editJobRoleById(jobRoleDetail.id, payload));
                onClose();
            } else {
                await dispatch(addJobRole(payload));
                onClose();
            }
            formik.resetForm();
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <TextFieldComponent
                label="Position *"
                type="text"
                minLength={1}
                field={{
                    ...formik.getFieldProps('position'),
                    onChange: formik.handleChange('position'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <TextFieldComponent
                label="Job Description *"
                type="text"
                multiline={true}
                rows={5}
                field={{
                    ...formik.getFieldProps('jobDescription'),
                    onChange: formik.handleChange('jobDescription'),
                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <TextFieldComponent
                label="Department"
                type="text"
                field={{
                    ...formik.getFieldProps('department'),
                    onChange: formik.handleChange('department'),

                }}
                form={formik}
                fullWidth
                style={{ marginBottom: '16px' }}
            />

            <div style={{ marginBottom: 16 }}>
                <DropdownComponent
                    label="Similar Role"
                    field={{
                        ...formik.getFieldProps('similarRole1'),
                        onChange: formik.handleChange('similarRole1'),
                    }}
                    form={formik}
                    fullWidth
                    options={onlyJobRoles}
                    value={formik.values.similarRole1}
                />
            </div>

            <div style={{ marginBottom: 16 }}>
                <DropdownComponent
                    label="Similar Role 2"
                    field={{
                        ...formik.getFieldProps('similarRole2'),
                        onChange: formik.handleChange('similarRole2'),
                    }}
                    form={formik}
                    fullWidth
                    options={onlyJobRoles}
                    value={formik.values.similarRole2}
                />
            </div>

            <div style={{ marginBottom: 16 }}>
                <DropdownComponent
                    label="Similar Role 3"
                    field={{
                        ...formik.getFieldProps('similarRole3'),
                        onChange: formik.handleChange('similarRole3'),
                    }}
                    form={formik}
                    fullWidth
                    options={onlyJobRoles}
                    value={formik.values.similarRole3}
                />
            </div>

            <ButtonComponent
                type="submit"
                label={formik.isSubmitting ? 'Submitting...' : 'Submit'}
                disabled={formik.isSubmitting}
                fullWidth
            />
        </form>
    );
};

export default AddJobRole;
