import { formatDDMMYYYYDate } from '../../helpers/formattedDateTime';
import apiService from './apiService';

export const getGigsService = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `admin/gig-posts?${queryString}`;

    const response = await apiService.get(url);
    return response.data;
};

export const getGigDetailService = async (gigPostId) => {
    const url = `/admin/gig-posts/${gigPostId}`;

    const response = await apiService.get(url);
    return response.data;
};

export const getGigPostApplicantsService = async (gigPostId, pageNumber = 0) => {
    const url = `/admin/gig-posts/${gigPostId}/applicants?pageNumber=${pageNumber}`;

    const response = await apiService.get(url);
    return response.data;
};

export const createGigPostService = async (gigPostDetails) => {
    const url = `/admin/gig-posts`;

    const response = await apiService.post(url, gigPostDetails);
    return response.data;
};

export const editGigPostService = async (gigPostId, gigPostDetails) => {
    const url = `/admin/gig-posts/${gigPostId}`;

    const response = await apiService.patch(url, gigPostDetails);
    return response.data;
};

export const getPendingGigsService = async (pageNumber = 0) => {
    const url = `/admin/gig-posts/applicants?pageNumber=${pageNumber}`;

    const response = await apiService.get(url);
    return response.data;
};

export const updateGigApplicationService = async (gigApplicationId, updateData) => {
    const url = `/admin/gig-application/${gigApplicationId}`;

    const response = await apiService.patch(url, updateData);
    return response.data;
};


export const deleteGigService = async (gigPostId) => {
    const url = `/admin/gig-posts/${gigPostId}`;

    const response = await apiService.delete(url);
    return response.data;
};


export const exportGigPostsDataService = async (startDate, endDate) => {
    const url = `/admin/gig-posts/export?startDate=${startDate}&endDate=${endDate}`;
    try {
      const response = await apiService.get(url, { responseType: 'blob' });
      // Create a blob object from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // Create a URL for the blob object
      const downloadUrl = window.URL.createObjectURL(blob);
     
      const link = document.createElement('a');
      link.href = downloadUrl;

      const formattedStartDate = formatDDMMYYYYDate(startDate);
      const formattedEndDate = formatDDMMYYYYDate(endDate);

      const fileName = `gig_post_data_${formattedStartDate}_${formattedEndDate}.xlsx`;

      link.download = fileName;
      // Trigger the download
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      return { success: true };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };
  