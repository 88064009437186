import React from "react";
import { FormControl, FormHelperText } from "@mui/material";
import Select, { components } from "react-select";

const DropdownComponent = ({
  label,
  options,
  field,
  form,
  placeholder,
  isMulti,
  isDisabled,
  fullWidth,
  required,
  style,
  selectStyle,
  onChange,
}) => {
  const handleChange = (selectedOption) => {
    // Update Formik's values directly
    if (form) {
      form.setFieldValue(field.name, selectedOption);
    } else if (onChange) {
      onChange(selectedOption);
    }
  };

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      disabled={isDisabled}
      error={form?.touched[field.name] && Boolean(form?.errors[field.name])}
      style={style}
    >
      {/* <label>{label}</label> */}
      <Select
        components={{
          ValueContainer: CustomValueContainer
        }}
        options={options}
        value={field?.value}
        onChange={handleChange}
        placeholder={label}
        isMulti={isMulti}
        isDisabled={isDisabled}
        menuPosition={'fixed'}
        menuPortalTarget={document.body}
        minMenuHeight={24}
        getOptionLabel={(option) => option?.name.toString() || ""}
        getOptionValue={(option) => option?.id.toString() || ""}
        menuPlacement={'bottom'}
        menuShouldScrollIntoView={false}
        styles={{
          ...selectStyle,
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible"
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: (state.hasValue || state.selectProps.inputValue) ? -12 : null,
            backgroundColor:
              state.hasValue || state.selectProps.inputValue
                ? "white"
                : "transparent",
            padding: "0 3px",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 12
          })
        }}
      />
      {form?.touched[field.name] && form?.errors[field.name] && (
        <FormHelperText>{form?.errors[field.name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default DropdownComponent;
