import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import TextFieldComponent from '../components/TextFieldComponent';
import Button from '@mui/material/Button';
import AddUser from '../models/AddUser';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUsersList } from '../redux/actions/userActions';
import { isSuperUser } from '../redux/selectors/userSelector';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { ArrowBackIosNew } from '@mui/icons-material';


const ViewAdmins = () => {

  const dispatch = useDispatch();
  const admins = useSelector((state) => Array.isArray(state.user.usersList) ? state.user.usersList : []);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddUserVisible, setAddUserVisible] = useState(false);

  const [userDetail, setUserDetail] = useState(null);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);


  const handleConfirmationDialogClose = () => {
    setIsUserDeleteModalOpen(false);
  };

  const handleConfirmationDialog = () => {
    setIsUserDeleteModalOpen(false);
    dispatch(deleteUser(selectedUserId));
    setIsUserDeleteModalOpen(false);
    setSelectedUserId(null);
  };


  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);


  const handleAddAdminClick = () => {
    setUserDetail(null);
    setAddUserVisible(!isAddUserVisible);
  };

  const handleEditClick = (admin) => {
    setAddUserVisible(false);
    setUserDetail(admin);
    setAddUserVisible(true);
  };

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsUserDeleteModalOpen(true);
  };

  const filteredAdmins = admins?.filter(
    admin =>
      admin?.name &&
      admin?.mobileNumber &&
      (admin?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        admin?.mobileNumber?.includes(searchTerm))
  );
  const superUser = useSelector(isSuperUser);

  return (
    <Grid container spacing={3}>
      {/* Left side: Admins Table */}
      <Grid size={{ xs: 12, md: isAddUserVisible ? 8 : 12 }} >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Search Input on the Left Side */}
          <TextFieldComponent
            label="Search"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: '350px' }}
          />

          {/* Add User Button on the Right Side */}
          {superUser && <Tooltip title="Add User">
            <Button variant="contained" color="primary" onClick={handleAddAdminClick}>
              {/* <AddIcon style={{ marginRight: '8px' }} /> */}
              Add User
            </Button>
          </Tooltip>}
        </div>

        <div style={{ margin: '16px 0' }} />

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold}>Name</TableCell>
                <TableCell style={styles.textBold}>Mobile Number</TableCell>
                <TableCell style={styles.textBold}>Role</TableCell>
                {superUser && <TableCell style={styles.textBold}>Edit</TableCell>}
                {superUser && <TableCell style={styles.textBold}>Delete</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAdmins.map((admin) => (
                <TableRow key={admin?.id}>
                  <TableCell>{admin?.name}</TableCell>
                  <TableCell>{admin?.mobileNumber}</TableCell>
                  <TableCell>{admin.roles[0]?.name}</TableCell>
                  {superUser && <TableCell>
                    <IconButton onClick={() => handleEditClick(admin)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>}
                  {superUser && <TableCell>
                    <IconButton onClick={() => handleDeleteClick(admin.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Right side: Add User Button */}
      {isAddUserVisible && (
        <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
          <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleAddAdminClick} style={{ marginBottom: '16px' }}>
            Back
          </Button>
          <AddUser onClose={() => setAddUserVisible(false)} userDetail={userDetail} />
        </Grid>
      )}

      {isUserDeleteModalOpen === true && (
        <ConfirmationDialog
          open={true}
          onClose={handleConfirmationDialogClose}
          onConfirm={handleConfirmationDialog}
          message="Do you want to Delete?"
        />
      )}
    </Grid>
  );
};

export default ViewAdmins;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}