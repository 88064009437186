import {
  ADD_EMPLOYER, ADD_EMPLOYER_FAILURE, ADD_EMPLOYER_SUCCESS, DELETE_EMPLOYER,
  DELETE_EMPLOYER_FAILURE, DELETE_EMPLOYER_SUCCESS, EDIT_EMPLOYER, EDIT_EMPLOYER_FAILURE,
  EDIT_EMPLOYER_SUCCESS, GET_EMPLOYERS, GET_EMPLOYERS_FAILURE, GET_EMPLOYERS_SUCCESS,
  GET_EMPLOYERS_AUDIT, GET_EMPLOYERS_AUDIT_FAILURE, GET_EMPLOYERS_AUDIT_SUCCESS,
  GET_SPECIFIC_EMPLOYERS_AUDIT, GET_SPECIFIC_EMPLOYERS_AUDIT_FAILURE, GET_SPECIFIC_EMPLOYERS_AUDIT_SUCCESS, RESET_SPECIFIC_EMPLOYERS_AUDIT,
  GET_SINGLE_EMPLOYER, GET_SINGLE_EMPLOYER_FAILURE, GET_SINGLE_EMPLOYER_SUCCESS,
  RESET_ADDED_EMPLOYER_FLAG, RESET_EDITED_EMPLOYER_FLAG, EXPORT_EMPLOYER_DATA, EXPORT_EMPLOYER_DATA_FAILURE,
  SET_EMPLOYER_FILTERS
} from "../actions/employerActions";
import { FILE_UPLOAD_FAILURE, FILE_UPLOAD_REQUEST, FILE_UPLOAD_SUCCESS } from "../actions/userActions";


const initialState = {
  employer: null,
  employerFilters: {
    sort: '',
    active: '',
    verified: '',
    assitedHire: '',
    profileIncomplete: '',
    createDate: '',
    updateDate: ''
  },
  employersAudit: null,
  specificEmployersAudit: null,
  employerById: null,
  newEmployerCreated: null,
  editEmployerSuccess: null,
  deleteEmployerSuccess: null,
  isDocumentFileUploaded: null
};

const employerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYERS:
      return {
        ...state,
      };

    case GET_EMPLOYERS_SUCCESS:
      return {
        ...state,
        employer: action.payload
      };

    case GET_EMPLOYERS_FAILURE:
      return {
        ...state,
      };

    case SET_EMPLOYER_FILTERS:
      return {
        ...state,
        employerFilters: action.payload,
      };

    case GET_EMPLOYERS_AUDIT:
      return {
        ...state,
      };

    case GET_EMPLOYERS_AUDIT_SUCCESS:
      return {
        ...state,
        employersAudit: action.payload
      };

    case GET_EMPLOYERS_AUDIT_FAILURE:
      return {
        ...state,
      };

    case GET_SPECIFIC_EMPLOYERS_AUDIT:
      return {
        ...state,
      };

    case GET_SPECIFIC_EMPLOYERS_AUDIT_SUCCESS:
      return {
        ...state,
        specificEmployersAudit: action.payload
      };

    case GET_SPECIFIC_EMPLOYERS_AUDIT_FAILURE:
      return {
        ...state,
      };

    case RESET_SPECIFIC_EMPLOYERS_AUDIT:
      return {
        ...state,
        specificEmployersAudit: null
      };

    case ADD_EMPLOYER:
      return {
        ...state,
      };

    case ADD_EMPLOYER_SUCCESS:
      return {
        ...state,
        newEmployerCreated: true
      };

    case ADD_EMPLOYER_FAILURE:
      return {
        ...state,
      };

    case DELETE_EMPLOYER:
      return {
        ...state,
      };

    case DELETE_EMPLOYER_SUCCESS:
      return {
        ...state,
        deleteEmployerSuccess: true
      };

    case DELETE_EMPLOYER_FAILURE:
      return {
        ...state,
      };

    case EDIT_EMPLOYER:
      return {
        ...state,
      };

    case EDIT_EMPLOYER_SUCCESS:
      return {
        ...state,
        editEmployerSuccess: true
      };

    case EDIT_EMPLOYER_FAILURE:
      return {
        ...state,
      };

    case GET_SINGLE_EMPLOYER:
      return {
        ...state,
      };

    case GET_SINGLE_EMPLOYER_SUCCESS:
      return {
        ...state,
        employerById: action.payload
      };

    case GET_SINGLE_EMPLOYER_FAILURE:
      return {
        ...state,
      };

    case FILE_UPLOAD_REQUEST:
      return {
        ...state,
      };

    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        isDocumentFileUploaded: action.payload
      };

    case FILE_UPLOAD_FAILURE:
      return {
        ...state,
        isDocumentFileUploaded: false
      };

    case RESET_ADDED_EMPLOYER_FLAG:
      return {
        ...state,
        newEmployerCreated: null
      };

    case RESET_EDITED_EMPLOYER_FLAG:
      return {
        ...state,
        editEmployerSuccess: null
      };

    case EXPORT_EMPLOYER_DATA:
      return {
        ...state,
      };

    case EXPORT_EMPLOYER_DATA_FAILURE:
      return {
        ...state,
      };


    default:
      return state;
  }
};

export default employerReducer;