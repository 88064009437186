import React from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TextFieldComponent = ({
  label,
  type,
  field,
  form,
  fullWidth,
  customValidation,
  minLength,
  maxLength,
  disabled,
  multiline,
  rows,
  inputmode,
  pattern,
  error,
  helperText,
  ...props
}) => {

  const handleChange = (e) => {
    let value = e.target.value;

    if (type === 'number' && customValidation) {
      value = value.replace(/\D/g, '');
    }

    if (field) {
      field.onChange(e);
      form.setFieldValue(field.name, value);
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;

    if (maxLength && value.length > maxLength) {
      e.target.value = value.slice(0, maxLength); // Truncate the value to maxLength
      e.preventDefault(); // Prevent further input
    }

    // Proceed with other handling if needed
    handleChange(e);
  };

  const renderInput = () => {
    if (type === 'date') {
      return (
        <div style={{ position: 'relative', zIndex: 1000 }}>
          {/* Other content or components that should not be overlapped */}
          <DatePicker
            selected={field.value}
            onChange={(date) => form.setFieldValue(field.name, date)}
            dateFormat="dd/MM/yyyy"
            placeholderText={label}
            {...props}
            customInput={
              <TextField
                inputProps={{ autoComplete: 'off' }}
                label={label}
                fullWidth
                style={{
                  width: '100% !important',
                }}
                error={error ? error : (field && form.touched[field.name] && Boolean(form.errors[field.name]))}
                helperText={helperText ? helperText : (field && form.touched[field.name] && form.errors[field.name])}
                size='small'
              />
            }
          />
        </div>
      );
    }

    return (
      <TextField
        autoComplete='off'
        inputMode={inputmode}
        pattern={pattern}
        multiline={multiline}
        rows={rows}
        label={label}
        type={type}
        fullWidth={fullWidth}
        onChange={handleChange}
        onInput={handleInput}
        value={field && field.value !== null ? field.value : ''}
        onBlur={field ? field.onBlur : undefined}
        error={error ? error : (field && form.touched[field.name] && Boolean(form.errors[field.name]))}
        helperText={helperText ? helperText : (field && form.touched[field.name] && form.errors[field.name])}
        inputProps={{
          minLength: minLength,
          maxLength: maxLength,
        }}
        disabled={disabled}
        size='small'
        {...props}
      />
    );
  };

  return renderInput();
};

export default TextFieldComponent;
