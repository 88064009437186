// userService.js
import apiService from './apiService';

export const generateOTPService = async (payload) => {
  const response = await apiService.post('/admin/auth/generate-otp', payload);
  return response.data;
};

export const verifyOTPService = async (payload) => {
  const response = await apiService.post('/admin/auth/login', payload);
  return response.data;
};

export const getAdminRolesService = async () => {
  const response = await apiService.get('/admin/auth/get-roles');
  return response.data;
};

export const getUsersListService = async () => {
  const response = await apiService.get('/admin/users');
  return response.data;
};

export const createNewUserService = async (payload) => {
  const response = await apiService.post('/admin/auth/create-user', payload);
  return response.data;
};

export const getReferralsListService = async () => {
  const response = await apiService.get('admin/referrals');
  return response.data;
};

export const getAppUserFromReferralCode = async (referralCode) => {
  const response = await apiService.get(`admin/app-users/${referralCode}`);
  return response.data;
};

export const deleteDocumentService = async (documentId) => {
  const response = await apiService.delete(`admin/documents/${documentId}`);
  return response.data;
}

export const updateUserService = async (user) => {
  const url = `/admin/users/${user.id}`;
  const response = await apiService.patch(url, user);
  return response.data;
}

export const deleteUserService = async (userId) => {
  const response = await apiService.delete(`admin/users/${userId}`);
  return response.data;
}

export const uploadFileService = async (files, userId, docType) => {
  const url = '/admin/documents';

  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }
  formData.append('userId', userId);
  formData.append('docType', docType);

  const response = await apiService.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const uploadResumeFile = async (file, onUploadProgress) => {
  return new Promise(async (resolve) => {
    const url = '/admin/resume-parser';

    const formData = new FormData();
    formData.append('file', file);

    const response = await apiService.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onUploadProgress
    });

    resolve(response.data);
  })
};

export const assignDocToNewUser = async (payload) => {
  return new Promise(async (resolve) => {
    const response = await apiService.post('/admin/doc', payload);
    resolve(response.data);
  });
};

export const isAppUserExist = async (mobileNumber) => {
  return new Promise(async (resolve) => {
    const url = `/admin/app-user-exist?mobileNumber=${mobileNumber}`;
    const response = await apiService.get(url);
    resolve(response.data);
  });
};

export const getAppUsers = async (params) => {
  return new Promise(async (resolve) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `/admin/app-users?${queryString}`;
    const response = await apiService.get(url);
    resolve(response.data);
  });
};

export const sendNotificationToUsers = async (payload) => {
  return new Promise(async (resolve) => {
    const response = await apiService.post('/notification/send', payload);
    resolve(response.data);
  });
};
