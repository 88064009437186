import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextFieldComponent from '../components/TextFieldComponent';
import ButtonComponent from '../components/ButtonComponent';
import DropdownComponent from '../components/DropDownComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getAdminRoles, createUser, setNewUserCreated, updateUser } from '../redux/actions/userActions';

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits')
    .required('Mobile Number is required'),
  name: Yup.string().required('Name is required'),
  roleId: Yup.object().required('Role is required'),
});

const AddAdminRoleModel = ({ onClose, userDetail }) => {
 
  const dispatch = useDispatch();
  const adminRoles = useSelector((state) => state.user.adminRoles);
  const isNewUserCreated = useSelector((state) => state.user.createNewUser);

  // State to track whether admin roles have been fetched
  const [adminRolesFetched, setAdminRolesFetched] = useState(false);

  useEffect(() => {
    // Dispatch the action to set isNewUserCreated to false when the component is loaded
    dispatch(setNewUserCreated(null));

    const fetchAdminRoles = async () => {
      await dispatch(getAdminRoles());
      setAdminRolesFetched(true);
    };

    // Fetch admin roles only if it hasn't been fetched before
    if (!adminRolesFetched) {
      fetchAdminRoles();
    }
  }, [dispatch, adminRolesFetched, isNewUserCreated]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobileNumber: userDetail?.mobileNumber,
      name: userDetail?.name,
      roleId: userDetail?.roles[0]
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        mobileNumber: values.mobileNumber,
        name: values.name,
        roleId: values.roleId?.id
      };
  
      // Conditionally include id if userDetail.id is defined
      if (userDetail?.id) {
        payload.id = userDetail.id;
      }
  
      if (userDetail) {
        await dispatch(updateUser(payload));
      } else {
        await dispatch(createUser(payload));
      }
     
      onClose();
      formik.resetForm();
    }
  });
  

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldComponent
        label="Mobile Number *"
        type="tel"
        inputmode="numeric"
        pattern="[0-9\s]{13,19}"
        disabled={userDetail?.mobileNumber ? true : false}
        maxLength={10}
        minLength={10}
        field={{
          ...formik.getFieldProps('mobileNumber'),
          onChange: formik.handleChange('mobileNumber'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <TextFieldComponent
        label="Name *"
        type="text"
        field={{
          ...formik.getFieldProps('name'),
          onChange: formik.handleChange('name'),
        }}
        form={formik}
        fullWidth
        style={{ marginBottom: '16px' }}
      />

      <DropdownComponent
        label="Admin Role *"
        field={{
          ...formik.getFieldProps('roleId'),
          onChange: formik.handleChange('roleId'),
        }}
        form={formik}
        options={adminRoles}
        fullWidth
        style={{ marginBottom: '16px' }}
        value={formik.values.roleId}
      />

      <ButtonComponent
        type="submit"
        label={formik.isSubmitting ? 'Submitting...' : 'Submit'}
        fullWidth
      />
    </form>
  );
};

export default AddAdminRoleModel;
