import React, { useState } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ReactModal from 'react-modal';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import { uploadFile } from '../redux/actions/userActions'


const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(4px)',
        zIndex: 1000,
        marginTop: '2em'
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '30%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        height: '60%',
    },
};

const DocumentUploadModal = ({ documentTypes, userId, open, onClose }) => {
    const dispatch = useDispatch();
    const [documentFiles, setDocumentFiles] = useState([]);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [selectedDocType, setSelectedDocType] = useState(null);

    const handleFileInputChange = (event, index, docName) => {
        const selectedFiles = event.target.files;
        const allowedExtensions = docName.canUpload; // Get allowed extensions from docName

        // Validate each selected file
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const fileExtension = file.name.split('.').pop().toLowerCase(); // Get file extension

            // Check if the file extension is allowed
            if (!allowedExtensions.includes(fileExtension)) {
                alert(`Cannot upload file "${file.name}". Only ${allowedExtensions.join(', ')} files are allowed.`);
                return; // Abort upload
            }
        }

        // If all files have valid extensions, update state and proceed
        setDocumentFiles(prevState => {
            const updatedFiles = [...prevState];
            updatedFiles[index] = { name: '', files: [] };
            for (let i = 0; i < selectedFiles.length; i++) {
                updatedFiles[index].files.push(selectedFiles[i]);
            }
            return updatedFiles;
        });


        setSelectedDocType(docName.id);
        setConfirmationModalOpen(true); // Show confirmation modal
    };

    const handleConfirmation = async () => {
        if (!documentFiles || documentFiles.length === 0) {
            return;
        } else {
            const payload = {
                files: documentFiles?.flatMap(doc => doc.files || []), // Ensure files array exists
                userId,
                docType: selectedDocType
            };

            await dispatch(uploadFile(payload.files, userId, payload.docType));
            setConfirmationModalOpen(false);
        }

    };

    const handleCancel = () => {
        setSelectedDocType(null)
        setDocumentFiles([]);
        setConfirmationModalOpen(false);
    };

    return (
        <>
            <ReactModal
                isOpen={open}
                onRequestClose={onClose}
                contentLabel="Upload Document Modal"
                style={customModalStyles}
            >
                <div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2> Upload Documents / Images (Upload PDF, Image or Document Format Only)</h2>
                        <IconButton onClick={onClose} style={{ marginTop: '1rem' }}>
                            <CloseIcon />
                        </IconButton>
                    </div>


                    <div style={{ marginTop: '20px' }}>
                        {documentTypes.map((docType, index) => (
                            <div key={docType.id} style={{ marginBottom: '16px' }}>
                                <Typography variant="subtitle1">{docType.name} ({docType.note})</Typography>
                                <input
                                    accept="image/*, application/pdf, .doc, .docx"
                                    style={{ display: 'none' }}
                                    id={`file-input-${index}`}
                                    type="file"
                                    multiple={docType.multiple}
                                    onChange={(event) => handleFileInputChange(event, index, docType)}
                                />
                                <label htmlFor={`file-input-${index}`}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        component="span"
                                        startIcon={<CloudUploadOutlined />}
                                    >
                                        Select File
                                    </Button>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </ReactModal>

            <ConfirmationDialog
                open={confirmationModalOpen}
                onClose={handleCancel}
                onConfirm={handleConfirmation}
                onCancel={handleCancel}
                message="Are you sure you want to upload these documents?"
            />
        </>
    );
};

export default DocumentUploadModal;
