// gigsActions.js
import showToast from '../../utils/toast';
import {
  getGigDetailService,
  getGigPostApplicantsService,
  createGigPostService,
  editGigPostService,
  getPendingGigsService,
  updateGigApplicationService,
  getGigsService,
  deleteGigService,
  exportGigPostsDataService,
} from '../service/gigsService';
import { startLoader, stopLoader } from './loaderActions';

// Action types
export const GET_GIGS = 'GET_GIG';
export const GET_GIGS_SUCCESS = 'GET_GIG_SUCCESS';
export const GET_GIGS_FAILURE = 'GET_GIG_FAILURE';

export const GET_GIG_DETAILS = 'GET_GIG_DETAILS';
export const GET_GIG_DETAILS_SUCCESS = 'GET_GIG_DETAILS_SUCCESS';
export const GET_GIG_DETAILS_FAILURE = 'GET_GIG_DETAILS_FAILURE';

export const GET_GIG_APPLICANTS = 'GET_GIG_APPLICANTS';
export const GET_GIG_APPLICANTS_SUCCESS = 'GET_GIG_APPLICANTS_SUCCESS';
export const GET_GIG_APPLICANTS_FAILURE = 'GET_GIG_APPLICANTS_FAILURE';

export const CREATE_GIG = 'CREATE_GIG';
export const CREATE_GIG_SUCCESS = 'CREATE_GIG_SUCCESS';
export const CREATE_GIG_FAILURE = 'CREATE_GIG_FAILURE';

export const EDIT_GIG = 'EDIT_GIG';
export const EDIT_GIG_SUCCESS = 'EDIT_GIG_SUCCESS';
export const EDIT_GIG_FAILURE = 'EDIT_GIG_FAILURE';

export const DELETE_GIG = 'DELETE_GIG';
export const DELETE_GIG_SUCCESS = 'DELETE_GIG_SUCCESS';
export const DELETE_GIG_FAILURE = 'DELETE_GIG_FAILURE';

export const GET_PENDING_GIGS = 'GET_PENDING_GIGS';
export const GET_PENDING_GIGS_SUCCESS = 'GET_PENDING_GIGS_SUCCESS';
export const GET_PENDING_GIGS_FAILURE = 'GET_PENDING_GIGS_FAILURE';

export const UPDATE_GIG_APPLICATION = 'UPDATE_GIG_APPLICATION';
export const UPDATE_GIG_APPLICATION_SUCCESS = 'UPDATE_GIG_APPLICATION_SUCCESS';
export const UPDATE_GIG_APPLICATION_FAILURE = 'UPDATE_GIG_APPLICATION_FAILURE';

export const RESET_ADDED_GIG_FLAG = 'RESET_ADDED_GIG_FLAG';

export const RESET_EDITED_GIG_FLAG = 'RESET_EDITED_GIG_FLAG';

export const EXPORT_GIG_POSTS_REQUEST = 'EXPORT_GIG_POSTS_REQUEST';
export const EXPORT_GIG_POSTS_SUCCESS = 'EXPORT_GIG_POSTS_SUCCESS';
export const EXPORT_GIG_POSTS_FAILURE = 'EXPORT_GIG_POSTS_FAILURE';

// Action creators
export const getGigs = () => ({
  type: GET_GIGS,
});

export const getGigsSuccess = (response) => ({
  type: GET_GIGS_SUCCESS,
  payload: response,
});

export const getGigsFailure = (error) => ({
  type: GET_GIGS_FAILURE,
  payload: error,
});


export const getGigDetails = () => ({
    type: GET_GIG_DETAILS,
  });
  
  export const getGigDetailsSuccess = (response) => ({
    type: GET_GIG_DETAILS_SUCCESS,
    payload: response,
  });
  
  export const getGigDetailsFailure = (error) => ({
    type: GET_GIG_DETAILS_FAILURE,
    payload: error,
  });


export const getGigApplicants = () => ({
  type: GET_GIG_APPLICANTS,
});

export const getGigApplicantsSuccess = (response) => ({
  type: GET_GIG_APPLICANTS_SUCCESS,
  payload: response,
});

export const getGigApplicantsFailure = (error) => ({
  type: GET_GIG_APPLICANTS_FAILURE,
  payload: error,
});

export const createGig = () => ({
  type: CREATE_GIG,
});

export const createGigSuccess = (response) => ({
  type: CREATE_GIG_SUCCESS,
  payload: response,
});

export const createGigFailure = (error) => ({
  type: CREATE_GIG_FAILURE,
  payload: error,
});

export const editGig = () => ({
  type: EDIT_GIG,
});

export const editGigSuccess = (response) => ({
  type: EDIT_GIG_SUCCESS,
  payload: response,
});

export const editGigFailure = (error) => ({
  type: EDIT_GIG_FAILURE,
  payload: error,
});

export const deleteGig = () => ({
  type: DELETE_GIG,
});

export const deleteGigSuccess = (response) => ({
  type: DELETE_GIG_SUCCESS,
  payload: response,
});

export const deleteGigFailure = (error) => ({
  type: DELETE_GIG_FAILURE,
  payload: error,
});

export const getPendingGigs = () => ({
  type: GET_PENDING_GIGS,
});

export const getPendingGigsSuccess = (response) => ({
  type: GET_PENDING_GIGS_SUCCESS,
  payload: response,
});

export const getPendingGigsFailure = (error) => ({
  type: GET_PENDING_GIGS_FAILURE,
  payload: error,
});

export const updateGigApplication = () => ({
  type: UPDATE_GIG_APPLICATION,
});

export const updateGigApplicationSuccess = (response) => ({
  type: UPDATE_GIG_APPLICATION_SUCCESS,
  payload: response,
});

export const updateGigApplicationFailure = (error) => ({
  type: UPDATE_GIG_APPLICATION_FAILURE,
  payload: error,
});

export const resetGigAdded = () => ({
  type: RESET_ADDED_GIG_FLAG,
})

export const resetGigEdited = () => ({
  type: RESET_EDITED_GIG_FLAG,
})

const exportGigPostsRequest = () => ({
  type: EXPORT_GIG_POSTS_REQUEST,
});

const exportGigPostsSuccess = () => ({
  type: EXPORT_GIG_POSTS_SUCCESS,
});

const exportGigPostsFailure = (error) => ({
  type: EXPORT_GIG_POSTS_FAILURE,
  error,
});



// Async actions

export const getGigsList = (payload) => async (dispatch) => {
    dispatch(getGigs());
    dispatch(startLoader());
  
    try {
      const response = await getGigsService(payload);
  
      if (response.success) {
        dispatch(getGigsSuccess(response));
      } else {
        dispatch(getGigsFailure(response.message));
        showToast(response.message, 'error');
      }
    } catch (error) {
      dispatch(getGigsFailure('Error getting gigs list'));
      showToast('Error getting gigs list', 'error');
    } finally {
      dispatch(stopLoader());
    }
  };
  

export const getGigDetail = (gigPostId) => async (dispatch) => {
  dispatch(getGigDetails());
  dispatch(startLoader());

  try {
    const response = await getGigDetailService(gigPostId);

    if (response.success) {
      dispatch(getGigDetailsSuccess(response));
    } else {
      dispatch(getGigDetailsFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getGigsFailure('Error getting gig details'));
    showToast('Error getting gig details', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getGigApplicantsList = (gigPostId, pageNumber) => async (dispatch) => {
  dispatch(getGigApplicants());
  dispatch(startLoader());

  try {
    const response = await getGigPostApplicantsService(gigPostId, pageNumber);

    if (response.success && response.data.length) {
      dispatch(getGigApplicantsSuccess(response));
    } else {
      dispatch(getGigApplicantsFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getGigApplicantsFailure('Error getting gig applicants'));
    showToast('Error getting gig applicants', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const createGigPost = (gigPostDetails) => async (dispatch) => {
  dispatch(createGig());
  dispatch(startLoader());

  try {
    const response = await createGigPostService(gigPostDetails);

    if (response.success) {
      dispatch(createGigSuccess(response));
      showToast('Gig created successfully', 'success');
    } else {
      dispatch(createGigFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(createGigFailure('Error creating gig'));
    showToast('Error creating gig', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const editGigPost = (gigPostId, gigPostDetails) => async (dispatch, getState) => {
  dispatch(editGig());
  dispatch(startLoader());

  try {
    const response = await editGigPostService(gigPostId, gigPostDetails);

    if (response.success) {
      dispatch(editGigSuccess(response));

      const updatedGigData = response.data;

      const gigState = getState().gig || {};
     
      const updatedGigList = gigState.gigs.data.map((existingGig) => {
        if (existingGig.gigPostId === updatedGigData.gigPostId) {
          return {
            ...existingGig,
            ...updatedGigData,
          };
        }
        return existingGig;
      });

     
      const updatedPayload = {
        success: true,
        message: gigState.message,
        msgId: "SUCCESS",
        data: updatedGigList,
        metadata: gigState.metadata,
      };

      dispatch({
        type: GET_GIGS_SUCCESS,
        payload: updatedPayload,
      });


      showToast('Gig updated successfully', 'success');
    } else {
      dispatch(editGigFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(editGigFailure('Error updating gig'));
    showToast('Error updating gig', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const deleteGigPost = (gigPostId) => async (dispatch, getState) => {
  dispatch(editGig());
  dispatch(startLoader());

  try {
    const response = await deleteGigService(gigPostId);

    if (response.success) {
      dispatch(deleteGigSuccess(response));

      const gigState = getState().gig || {};
      const updatedGigList = gigState?.gigs?.data?.filter((existingGig) => {
        return existingGig.gigPostId !== gigPostId;
      });
     
      const updatedPayload = {
        success: true,
        message: gigState.message,
        msgId: "SUCCESS",
        data: updatedGigList,
        metadata: gigState.metadata,
      };

      dispatch({
        type: GET_GIGS_SUCCESS,
        payload: updatedPayload,
      });


      showToast('Gig updated successfully', 'success');
    } else {
      dispatch(deleteGigFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(deleteGigFailure('Error deleting gig'));
    showToast('Error deleting gig', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getPendingGigsList = (pageNumber) => async (dispatch) => {
  dispatch(getPendingGigs());
  dispatch(startLoader());

  try {
    const response = await getPendingGigsService(pageNumber);

    if (response.success) {
      dispatch(getPendingGigsSuccess(response));
      
    } else {
      dispatch(getPendingGigsFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getPendingGigsFailure('Error getting pending gigs'));
    showToast('Error getting pending gigs', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const updateGigApplicationStatus = (gigApplicationId, updateData) => async (dispatch, getState) => {
  dispatch(updateGigApplication());
  dispatch(startLoader());

  try {
    const response = await updateGigApplicationService(gigApplicationId, updateData);

    if (response.success) {

      const updatedPendingGigData = response.data;

      dispatch(updateGigApplicationSuccess(response));  

      const gigState = getState().gig || {};
     
      const updatedPendingGigList = gigState.pendingGigs.data.map((existingPendingGig) => {
        if (existingPendingGig.gigApplicationId !== updatedPendingGigData.gigApplicationId) {
          return existingPendingGig;
        }
        return null;
      }).filter(Boolean);
  
      const updatedPayload = {
        success: true,
        message: gigState.message,
        msgId: "SUCCESS",
        data: updatedPendingGigList,
        metadata: gigState.metadata,
      };

      dispatch({
        type: GET_PENDING_GIGS_SUCCESS,
        payload: updatedPayload,
      });

  
      showToast('Gig application updated successfully', 'success');
    } else {
      dispatch(updateGigApplicationFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(updateGigApplicationFailure('Error updating gig application'));
    showToast('Error updating gig application', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const clearAddedGigFlag = () => async (dispatch) => {
  dispatch(resetGigAdded(null));
}

export const clearEditedGigFlag = () => async (dispatch) => {
  dispatch(resetGigEdited(null));
}

export const exportGigPosts = (startDate, endDate) => async (dispatch) => {
  dispatch(exportGigPostsRequest());
  dispatch(startLoader()); 
  try {
    const response = await exportGigPostsDataService(startDate, endDate);

    if (response.success) {
      dispatch(exportGigPostsSuccess());
      showToast(response.message, 'success');
    } else {
      dispatch(exportGigPostsFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(exportGigPostsFailure('Error exporting gig post data'));
    showToast('Error exporting gig post', 'error');
  } finally {
    dispatch(stopLoader()); 
  }
}