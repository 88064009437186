// SuggestedCandidatesModal.js
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CandidateProfile from '../models/CandidateProfile';
import { useDispatch, useSelector, } from 'react-redux';
import { deleteSuggestedCandidateProfile, getSingleCandidateData } from '../redux/actions/candidateActions';
import moment from 'moment'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { convertToShortYearAndMonth } from '../helpers/utility';

const SuggestedCandidatesModal = ({ isOpen, onClose, suggestedCandidates }) => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(4px)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      left: '5%',
      top: '10%',
      zIndex: 1001,
      width: '94%',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      overflowY: 'auto',
    },
  }
  const dispatch = useDispatch();

  const currentCandidates = suggestedCandidates?.data || [];
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null)
  const [isCandidateDeleteModalOpen, setIsCandidateDeleteModalOpen] = useState(false);

  const candidateById = useSelector((state) => state.candidate.candidateById);
  const candidateDataById = candidateById?.data;

  if (!suggestedCandidates) {
    return null; // Don't render anything if suggestedCandidates is null or undefined
  }

  const handleCloseCandidateModal = () => {
    setIsCandidateModalOpen(false);
  };

  const openCandidateDetailsModal = (candidate) => {
    dispatch(getSingleCandidateData(candidate.candidateId));
    setIsCandidateModalOpen(true)
  }

  const handleDeleteProfileSuggestion = (candidate) => {
    setSelectedCandidate(candidate);
    setIsCandidateDeleteModalOpen(true);
  }

  const handleConfirmationDialogClose = () => {
    setIsCandidateDeleteModalOpen(false);
    setSelectedCandidate(null)
  };

  const handleConfirmationDialog = () => {
    setIsCandidateDeleteModalOpen(false);
    dispatch(deleteSuggestedCandidateProfile(selectedCandidate.profileSuggestionId))
    setSelectedCandidate(null);
    setIsCandidateDeleteModalOpen(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Suggested Candidates Modal"
      style={customStyles}
    >
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Suggested Candidates</h2>
          <IconButton onClick={onClose} style={{ marginTop: '1rem' }}>
            <CloseIcon />
          </IconButton>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold}>Candidate Name</TableCell>
                <TableCell style={styles.textBold}>Location</TableCell>
                <TableCell style={styles.textBold}>Job Location</TableCell>
                <TableCell style={styles.textBold}>Experience</TableCell>
                <TableCell style={styles.textBold}>Highest Education</TableCell>
                <TableCell style={styles.textBold}>Last Course Name</TableCell>
                <TableCell style={styles.textBold}>Recruiter Suggested</TableCell>
                <TableCell style={styles.textBold}>Applied On Date</TableCell>
                <TableCell style={styles.textBold}>Application Status</TableCell>
                <TableCell style={styles.textBold}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentCandidates.map((candidate) => (
                <TableRow key={candidate.candidateId} style={{ cursor: 'pointer' }}>

                  <TableCell onClick={() => openCandidateDetailsModal(candidate)}>{candidate.candidateName}</TableCell>
                  <TableCell>{candidate.location}</TableCell>
                  <TableCell>{candidate.jobLocation}</TableCell>
                  <TableCell>{convertToShortYearAndMonth(candidate.monthsOfExperience) || 'No experience'}</TableCell>
                  <TableCell>{candidate.highestEducation}</TableCell>
                  <TableCell>{candidate.lastCourseName || 'Not specified'}</TableCell>
                  <TableCell>{candidate.recruiterSuggested ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{moment(candidate.appliedOnDate).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{candidate.applicationStatus || 'Not specified'}</TableCell>
                  {candidate.recruiterSuggested && <TableCell>
                    <IconButton aria-label="delete" onClick={() => handleDeleteProfileSuggestion(candidate)}>
                      <DeleteIcon />
                    </IconButton>
                    {/* Content of the table cell goes here */}
                  </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


      </div>

      {isCandidateModalOpen && candidateDataById !== null && (
        <CandidateProfile
          isOpen={isCandidateModalOpen}
          onClose={handleCloseCandidateModal}
          candidateData={candidateDataById}
        />
      )}

      {isCandidateDeleteModalOpen === true && (
        <ConfirmationDialog
          open={true}
          onClose={handleConfirmationDialogClose}
          onConfirm={handleConfirmationDialog}
          message="Do you want to Delete?"
        />
      )}


    </ReactModal>


  );
};

export default SuggestedCandidatesModal;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}