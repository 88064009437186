import {
  GET_CANDIDATES,
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATES_FAILURE,
  GET_CANDIDATES_AUDIT,
  GET_CANDIDATES_AUDIT_SUCCESS,
  GET_CANDIDATES_AUDIT_FAILURE,
  GET_SPECIFIC_CANDIDATES_AUDIT,
  GET_SPECIFIC_CANDIDATES_AUDIT_SUCCESS,
  GET_SPECIFIC_CANDIDATES_AUDIT_FAILURE,
  RESET_SPECIFIC_CANDIDATES_AUDIT,
  GET_SINGLE_CANDIDATE,
  GET_SINGLE_CANDIDATE_SUCCESS,
  GET_SINGLE_CANDIDATE_FAILURE,
  GET_CANDIDATE_JOB_APPLICATIONS,
  GET_CANDIDATE_JOB_APPLICATIONS_SUCCESS,
  GET_CANDIDATE_JOB_APPLICATIONS_FAILURE,
  ADD_CANDIDATE,
  ADD_CANDIDATE_SUCCESS,
  ADD_CANDIDATE_FAILURE,
  RESET_ADDED_CANDIDATE_FLAG,
  EDIT_CANDIDATE,
  EDIT_CANDIDATE_SUCCESS,
  EDIT_CANDIDATE_FAILURE,
  RESET_EDITED_CANDIDATE_FLAG,
  DELETE_CANDIDATE,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_FAILURE,
  DELETE_SUGGESTED_CANDIDATE_PROFILE_REQUEST,
  DELETE_SUGGESTED_CANDIDATE_PROFILE_FAILURE,
  CREATE_PROFILE_SUGGESTION_REQUEST,
  CREATE_PROFILE_SUGGESTION_FAILURE,
  SELECT_CANDIDATES_FOR_JOB_SUGGESTION,
  REMOVE_CANDIDATES_FOR_JOB_SUGGESTION,
  DOWNLOAD_CANDIDATE_DATA_REQUEST,
  DOWNLOAD_CANDIDATE_DATA_FAILURE,
  SET_CANDIDATE_FILTERS
} from '../actions/candidateActions';

const initialState = {
  candidates: null,
  candidateFilters: {
    sort: '',
    gender: '',
    highestEducation: '',
    experienceLevel: '',
    workExperience: '',
    preferredRole: '',
    jobLocation: '',
    userType: '',
    active: '',
    lookingForJob: '',
    profileIncomplete: '',
    createDate: '',
    updateDate: ''
  },
  candidatesAudit: null,
  specificCandidatesAudit: null,
  candidateById: null,
  candidateJobApplications: null,
  newCandidateCreated: null,
  newCandidateCreatedId: null,
  editCandidateSuccess: null,
  deleteCandidateSuccess: null,
  selectedCandidatesForJobSuggestion: null
};

const candidateReducer = (state = initialState, action) => {
  switch (action.type) {

    case ADD_CANDIDATE:
      return {
        ...state,
      };

    case ADD_CANDIDATE_SUCCESS:
      return {
        ...state,
        newCandidateCreated: true,
        newCandidateCreatedId: action.payload?.id
      };

    case ADD_CANDIDATE_FAILURE:
      return {
        ...state,
      };

    case EDIT_CANDIDATE:
      return {
        ...state,
      };

    case EDIT_CANDIDATE_SUCCESS:
      return {
        ...state,
        editCandidateSuccess: true,
      };

    case EDIT_CANDIDATE_FAILURE:
      return {
        ...state,
      };

    case DELETE_CANDIDATE:
      return {
        ...state,
      };

    case DELETE_CANDIDATE_SUCCESS:
      return {
        ...state,
        deleteCandidateSuccess: true,
      };

    case DELETE_CANDIDATE_FAILURE:
      return {
        ...state,
      };

    case GET_CANDIDATES:
      return {
        ...state,
      };

    case GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        candidates: action.payload,
      };

    case GET_CANDIDATES_FAILURE:
      return {
        ...state,
      };


    case SET_CANDIDATE_FILTERS:
      return {
        ...state,
        candidateFilters: action.payload,
      };


    case GET_CANDIDATES_AUDIT:
      return {
        ...state,
      };

    case GET_CANDIDATES_AUDIT_SUCCESS:
      return {
        ...state,
        candidatesAudit: action.payload,
      };

    case GET_CANDIDATES_AUDIT_FAILURE:
      return {
        ...state,
      };

    case GET_SPECIFIC_CANDIDATES_AUDIT:
      return {
        ...state,
      };

    case GET_SPECIFIC_CANDIDATES_AUDIT_SUCCESS:
      return {
        ...state,
        specificCandidatesAudit: action.payload,
      };

    case GET_SPECIFIC_CANDIDATES_AUDIT_FAILURE:
      return {
        ...state,
      };

    case RESET_SPECIFIC_CANDIDATES_AUDIT:
      return {
        ...state,
        specificCandidatesAudit: null
      };

    case GET_SINGLE_CANDIDATE:
      return {
        ...state,
      };

    case GET_SINGLE_CANDIDATE_SUCCESS:
      return {
        ...state,
        candidateById: action.payload,
      };

    case GET_SINGLE_CANDIDATE_FAILURE:
      return {
        ...state,
      };

    case GET_CANDIDATE_JOB_APPLICATIONS:
      return {
        ...state,
        candidateJobApplications: null
      };

    case GET_CANDIDATE_JOB_APPLICATIONS_SUCCESS:
      return {
        ...state,
        candidateJobApplications: action.payload,
      };

    case GET_CANDIDATE_JOB_APPLICATIONS_FAILURE:
      return {
        ...state
      };

    case RESET_ADDED_CANDIDATE_FLAG:
      return {
        ...state,
        newCandidateCreated: null
      };

    case RESET_EDITED_CANDIDATE_FLAG:
      return {
        ...state,
        editCandidateSuccess: null
      };

    case DELETE_SUGGESTED_CANDIDATE_PROFILE_REQUEST:
      return {
        ...state,
      };

    case DELETE_SUGGESTED_CANDIDATE_PROFILE_FAILURE:
      return {
        ...state
      };

    case CREATE_PROFILE_SUGGESTION_REQUEST:
      return {
        ...state,
      };

    case CREATE_PROFILE_SUGGESTION_FAILURE:
      return {
        ...state
      };

    case SELECT_CANDIDATES_FOR_JOB_SUGGESTION:
      return {
        ...state,
        selectedCandidatesForJobSuggestion: action.payload,
      };

    case REMOVE_CANDIDATES_FOR_JOB_SUGGESTION:
      return {
        ...state,
        selectedCandidatesForJobSuggestion: state.selectedCandidatesForJobSuggestion.filter(job => job.candidateId !== action.payload),
      };

    case DOWNLOAD_CANDIDATE_DATA_REQUEST:
      return {
        ...state,
      };

    case DOWNLOAD_CANDIDATE_DATA_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default candidateReducer;
